import _ from 'lodash'

import type { SearchParamState } from 'store/searchParamStore'

export const getLocalSearchParam = (
  searchParamState: SearchParamState[],
  tenantId: string | null | undefined,
  companyId: number | undefined
) => {
  const findSearchParam = _.find(searchParamState, {
    tenantId,
    companyId,
  }) as SearchParamState

  if (findSearchParam === undefined) return undefined

  return findSearchParam.searchParam
}
