/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 商品マスタ
 * @export
 * @interface MstItem
 */
export interface MstItem {
    /**
     * 
     * @type {number}
     * @memberof MstItem
     */
    companyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    itemCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    itemName: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstItem
     */
    salesStartdate: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof MstItem
     */
    salesEnddate: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MstItem
     */
    longside: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstItem
     */
    shortside: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstItem
     */
    height: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstItem
     */
    weight: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    spec: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstItem
     */
    maxPalletQuantity: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstItem
     */
    palletQuantityFace: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstItem
     */
    palletQuantityTier: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstItem
     */
    minOrderQuantity: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    orderUnitType: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstItem
     */
    caseLot: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    orderUnitTypeName: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    replaceItemCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    oldItemCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    makerName: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    jan: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    janCase: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    gtin13: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    gtin14: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    itf: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    sdp: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    orgItemCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    makerGln: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    orgSupplierId: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstItem
     */
    supplierCompanyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstItem
     */
    supplierName: string | null;
}

/**
 * Check if a given object implements the MstItem interface.
 */
export function instanceOfMstItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "itemCode" in value;
    isInstance = isInstance && "itemName" in value;
    isInstance = isInstance && "salesStartdate" in value;
    isInstance = isInstance && "salesEnddate" in value;
    isInstance = isInstance && "longside" in value;
    isInstance = isInstance && "shortside" in value;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "spec" in value;
    isInstance = isInstance && "maxPalletQuantity" in value;
    isInstance = isInstance && "palletQuantityFace" in value;
    isInstance = isInstance && "palletQuantityTier" in value;
    isInstance = isInstance && "minOrderQuantity" in value;
    isInstance = isInstance && "orderUnitType" in value;
    isInstance = isInstance && "caseLot" in value;
    isInstance = isInstance && "orderUnitTypeName" in value;
    isInstance = isInstance && "replaceItemCode" in value;
    isInstance = isInstance && "oldItemCode" in value;
    isInstance = isInstance && "makerName" in value;
    isInstance = isInstance && "jan" in value;
    isInstance = isInstance && "janCase" in value;
    isInstance = isInstance && "gtin13" in value;
    isInstance = isInstance && "gtin14" in value;
    isInstance = isInstance && "itf" in value;
    isInstance = isInstance && "sdp" in value;
    isInstance = isInstance && "orgItemCode" in value;
    isInstance = isInstance && "makerGln" in value;
    isInstance = isInstance && "orgSupplierId" in value;
    isInstance = isInstance && "supplierCompanyId" in value;
    isInstance = isInstance && "supplierName" in value;

    return isInstance;
}

export function MstItemFromJSON(json: any): MstItem {
    return MstItemFromJSONTyped(json, false);
}

export function MstItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'itemCode': json['item_code'],
        'itemName': json['item_name'],
        'salesStartdate': (json['sales_startdate'] === null ? null : new Date(json['sales_startdate'])),
        'salesEnddate': (json['sales_enddate'] === null ? null : new Date(json['sales_enddate'])),
        'longside': json['longside'],
        'shortside': json['shortside'],
        'height': json['height'],
        'weight': json['weight'],
        'spec': json['spec'],
        'maxPalletQuantity': json['max_pallet_quantity'],
        'palletQuantityFace': json['pallet_quantity_face'],
        'palletQuantityTier': json['pallet_quantity_tier'],
        'minOrderQuantity': json['min_order_quantity'],
        'orderUnitType': json['order_unit_type'],
        'caseLot': json['case_lot'],
        'orderUnitTypeName': json['order_unit_type_name'],
        'replaceItemCode': json['replace_item_code'],
        'oldItemCode': json['old_item_code'],
        'makerName': json['maker_name'],
        'jan': json['jan'],
        'janCase': json['jan_case'],
        'gtin13': json['gtin_13'],
        'gtin14': json['gtin_14'],
        'itf': json['itf'],
        'sdp': json['sdp'],
        'orgItemCode': json['org_item_code'],
        'makerGln': json['maker_gln'],
        'orgSupplierId': json['org_supplier_id'],
        'supplierCompanyId': json['supplier_company_id'],
        'supplierName': json['supplier_name'],
    };
}

export function MstItemToJSON(value?: MstItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'item_code': value.itemCode,
        'item_name': value.itemName,
        'sales_startdate': (value.salesStartdate === null ? null : value.salesStartdate.toISOString()),
        'sales_enddate': (value.salesEnddate === null ? null : value.salesEnddate.toISOString()),
        'longside': value.longside,
        'shortside': value.shortside,
        'height': value.height,
        'weight': value.weight,
        'spec': value.spec,
        'max_pallet_quantity': value.maxPalletQuantity,
        'pallet_quantity_face': value.palletQuantityFace,
        'pallet_quantity_tier': value.palletQuantityTier,
        'min_order_quantity': value.minOrderQuantity,
        'order_unit_type': value.orderUnitType,
        'case_lot': value.caseLot,
        'order_unit_type_name': value.orderUnitTypeName,
        'replace_item_code': value.replaceItemCode,
        'old_item_code': value.oldItemCode,
        'maker_name': value.makerName,
        'jan': value.jan,
        'jan_case': value.janCase,
        'gtin_13': value.gtin13,
        'gtin_14': value.gtin14,
        'itf': value.itf,
        'sdp': value.sdp,
        'org_item_code': value.orgItemCode,
        'maker_gln': value.makerGln,
        'org_supplier_id': value.orgSupplierId,
        'supplier_company_id': value.supplierCompanyId,
        'supplier_name': value.supplierName,
    };
}

