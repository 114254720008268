import type { Dispatch, FormEvent, SetStateAction } from 'react'
import React, { useState } from 'react'

import { Box } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { SearchCustomer, SearchFrom, SelectCenter, SelectTypeCode } from 'components/commonparts'
import { ItemSearch } from 'components/dialog'

import type { GetShipExpectedShipExpectedGetRequest, MstItem, ResponseResult } from 'api-client'

interface SearchConditionProps {
  searchParam: GetShipExpectedShipExpectedGetRequest
  onSearch: (requestParam: GetShipExpectedShipExpectedGetRequest, e: FormEvent<HTMLFormElement>) => void
  setItemInfo: Dispatch<SetStateAction<MstItem | undefined>>
  onCsvDownLoad: () => void
  onExcelDownLoad: () => void
  isCsvLoading: boolean
  isXlsxLoading: boolean
  result?: ResponseResult | undefined
}

function SearchCondition({
  searchParam,
  onSearch,
  setItemInfo,
  onCsvDownLoad,
  onExcelDownLoad,
  isCsvLoading,
  isXlsxLoading,
  result,
}: SearchConditionProps) {
  const { loginUserInfo } = useAuthContext()

  const [itemIsValid, setItemIsValid] = useState(true)
  const [centerId, setCenterId] = useState<number | null | undefined>(searchParam.centerId)
  const [viweDateTypeCode, setViewDateTypeCode] = useState(searchParam.viewDateType)
  const [customerCompanyId, setCustomerCompanyId] = useState<number | undefined>(
    // companyIdがログインユーザーの企業IDの場合はundefinedにする
    searchParam.companyId === loginUserInfo!.companyId ? undefined : searchParam.companyId
  )
  const [itemCode, setItemCode] = useState<string | null | undefined>(searchParam.itemCode)
  const [viweUnitTypeCode, setViewUnitTypeCode] = useState(searchParam.viewUnitType)

  const onClear = () => {
    setCustomerCompanyId(undefined)
    setItemCode('')
    setItemInfo(undefined)
    setCenterId(undefined)
  }

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault()

        // 必須入力チェック
        if (!itemIsValid) {
          onSearch(
            {
              companyId: customerCompanyId ?? loginUserInfo!.companyId,
              centerId,
              viewDateType: viweDateTypeCode,
              viewUnitType: viweUnitTypeCode,
              itemCode,
            },
            e
          )
        }
      }}
    >
      <SearchFrom
        onClear={onClear}
        onCsvDownLoad={() => {
          if (!itemIsValid) {
            onCsvDownLoad()
          }
        }}
        onExcelDownLoad={() => {
          if (!itemIsValid) {
            onExcelDownLoad()
          }
        }}
        isDownload
        isCsvLoading={isCsvLoading}
        isXlsxLoading={isXlsxLoading}
        result={result}
      >
        <SelectTypeCode
          typeGroup="ViewDateType"
          typeCode={viweDateTypeCode}
          setTypeCode={setViewDateTypeCode}
          setTypeCodeInfo={() => {}}
        />
        <SearchCustomer
          title="会社"
          trCompanyId={loginUserInfo!.companyId}
          customerCompanyId={customerCompanyId}
          setCustomerCompanyId={setCustomerCompanyId}
          setCustomerCompanyInfo={() => {}}
          isRequred={false}
          setIsValid={() => {}}
        />
        <SelectCenter
          trCompanyId={customerCompanyId}
          centerId={centerId}
          setCenterId={setCenterId}
          setCenterInfo={() => {}}
          isRequired={false}
          setIsValid={() => {}}
        />
        <ItemSearch
          trCompanyId={customerCompanyId}
          itemCode={itemCode}
          setItemCode={setItemCode}
          setItemInfo={setItemInfo}
          isRequred
          setIsValid={setItemIsValid}
        />
        <SelectTypeCode
          typeGroup="ViewUnitType"
          typeCode={viweUnitTypeCode}
          setTypeCode={setViewUnitTypeCode}
          setTypeCodeInfo={() => {}}
        />
      </SearchFrom>
    </Box>
  )
}

export default SearchCondition
