export type RowType = {
  name: string
  code: string
  order: number
}

export const ROW_TYPE_ARRIVAL: RowType = {
  name: '入荷',
  code: 'arrival',
  order: 1,
}

export const ROW_TYPE_SHIP: RowType = {
  name: '出荷',
  code: 'ship',
  order: 2,
}

export const ROW_TYPE_SALES_ORDER: RowType = {
  name: '受注実績',
  code: 'salesOrder',
  order: 3,
}

export const ROW_TYPE_STOCK: RowType = {
  name: '在庫',
  code: 'stock',
  order: 4,
}

export const ROW_TYPE_STOCK_DAYS: RowType = {
  name: '在庫日数',
  code: 'stockDays',
  order: 5,
}

export const ROW_TYPE_REASON: RowType = {
  name: '特売等情報',
  code: 'reason',
  order: 6,
}

export type StockCell = {
  recDate: Date
  orderDate: Date | null
  value: number | null
  oldValue: number | null
  enableUpdate: boolean
  // 在庫日数計算のため、shipのみ参照する属性
  enableData: boolean
  isUpdate: boolean
  isCurrentUpdate: boolean
  recType: string | null
  fixFlg?: boolean
}

export type StockCellWithPk = StockCell & {
  companyId: number
  centerId: number
  itemCode: string
  rowType: RowType
}

export type StockRow = {
  companyId: number
  centerId: number
  orgItemCode: string
  itemCode: string
  itemName: string | null
  janCase: string | null
  expectedRank: number | null
  orderUnitTypeName: string | null
  caseLot: number | null
  minOrderQuantity: number | null
  rowType: RowType
  stockDaysSetting: number | null
  safetyStockSetting: number | null
  minStockQuantity: number | null
  startDate: Date | null
  stopShip: boolean | null
  stopArrival: boolean | null
  isTotal: boolean
  [key: `col${number}`]: StockCell | null
}
