/* eslint-disable react/destructuring-assignment */
import React from 'react'
import { Link } from 'react-router-dom'

import { protectedRoutes } from 'constants/routes'

import { useAuthContext } from 'AuthProvider'
import { useSearchParamStore } from 'store/searchParamStore'
import { getLocalSearchParam } from 'utils/getLocalSearchParam'

import type { CustomCellRendererProps } from 'ag-grid-react'

function ItemNameRenderer({ node, value }: CustomCellRendererProps) {
  const { loginUserInfo } = useAuthContext()
  const searchParamState = useSearchParamStore((state) => state.searchParamState)
  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)

  function buildURL() {
    const { aggData } = node
    if (!aggData) {
      return ''
    }
    // 商品詳細ではなく、ダッシュボードに飛ばすように変更(24/06)
    return `/${protectedRoutes.Dashboard.path}?company_id=${aggData.companyId}&center_id=${
      aggData.centerId
    }&item_code=${aggData.itemCode}&date_range=year`
    // return `/${protectedRoutes.StockAdjust.path}?company_id=${aggData.companyId}&center_id=${
    //   aggData.centerId
    // }&item_code=${aggData.itemCode}&optimize_range=${
    //   // ローカルストレージから取得する
    //   localSearchParam?.stockCenter.optimizeRange || ''
    // }`
  }

  if (node.group) {
    return (
      <div>
        {node.aggData?.isTotal ? (
          <div>{value}</div>
        ) : (
          <Link className="text-blue-400 underline" style={{ textDecoration: 'none' }} to={buildURL()} target="_blank">
            {value}
          </Link>
        )}
      </div>
    )
  }

  return <div />
}

export default ItemNameRenderer
