/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * センター・商品毎出荷予測
 * @export
 * @interface ShipExpected
 */
export interface ShipExpected {
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    companyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShipExpected
     */
    orgItemCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShipExpected
     */
    itemCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShipExpected
     */
    itemName: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    centerId: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShipExpected
     */
    centerName: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    expectedRank: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col1: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col2: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col3: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col4: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col5: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col6: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col7: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col8: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col9: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col10: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col11: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col12: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col13: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col14: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col15: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col16: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col17: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col18: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col19: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col20: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col21: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col22: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col23: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpected
     */
    col24: number | null;
}

/**
 * Check if a given object implements the ShipExpected interface.
 */
export function instanceOfShipExpected(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "orgItemCode" in value;
    isInstance = isInstance && "itemCode" in value;
    isInstance = isInstance && "itemName" in value;
    isInstance = isInstance && "centerId" in value;
    isInstance = isInstance && "centerName" in value;
    isInstance = isInstance && "expectedRank" in value;
    isInstance = isInstance && "col1" in value;
    isInstance = isInstance && "col2" in value;
    isInstance = isInstance && "col3" in value;
    isInstance = isInstance && "col4" in value;
    isInstance = isInstance && "col5" in value;
    isInstance = isInstance && "col6" in value;
    isInstance = isInstance && "col7" in value;
    isInstance = isInstance && "col8" in value;
    isInstance = isInstance && "col9" in value;
    isInstance = isInstance && "col10" in value;
    isInstance = isInstance && "col11" in value;
    isInstance = isInstance && "col12" in value;
    isInstance = isInstance && "col13" in value;
    isInstance = isInstance && "col14" in value;
    isInstance = isInstance && "col15" in value;
    isInstance = isInstance && "col16" in value;
    isInstance = isInstance && "col17" in value;
    isInstance = isInstance && "col18" in value;
    isInstance = isInstance && "col19" in value;
    isInstance = isInstance && "col20" in value;
    isInstance = isInstance && "col21" in value;
    isInstance = isInstance && "col22" in value;
    isInstance = isInstance && "col23" in value;
    isInstance = isInstance && "col24" in value;

    return isInstance;
}

export function ShipExpectedFromJSON(json: any): ShipExpected {
    return ShipExpectedFromJSONTyped(json, false);
}

export function ShipExpectedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipExpected {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'orgItemCode': json['org_item_code'],
        'itemCode': json['item_code'],
        'itemName': json['item_name'],
        'centerId': json['center_id'],
        'centerName': json['center_name'],
        'expectedRank': json['expected_rank'],
        'col1': json['col1'],
        'col2': json['col2'],
        'col3': json['col3'],
        'col4': json['col4'],
        'col5': json['col5'],
        'col6': json['col6'],
        'col7': json['col7'],
        'col8': json['col8'],
        'col9': json['col9'],
        'col10': json['col10'],
        'col11': json['col11'],
        'col12': json['col12'],
        'col13': json['col13'],
        'col14': json['col14'],
        'col15': json['col15'],
        'col16': json['col16'],
        'col17': json['col17'],
        'col18': json['col18'],
        'col19': json['col19'],
        'col20': json['col20'],
        'col21': json['col21'],
        'col22': json['col22'],
        'col23': json['col23'],
        'col24': json['col24'],
    };
}

export function ShipExpectedToJSON(value?: ShipExpected | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'org_item_code': value.orgItemCode,
        'item_code': value.itemCode,
        'item_name': value.itemName,
        'center_id': value.centerId,
        'center_name': value.centerName,
        'expected_rank': value.expectedRank,
        'col1': value.col1,
        'col2': value.col2,
        'col3': value.col3,
        'col4': value.col4,
        'col5': value.col5,
        'col6': value.col6,
        'col7': value.col7,
        'col8': value.col8,
        'col9': value.col9,
        'col10': value.col10,
        'col11': value.col11,
        'col12': value.col12,
        'col13': value.col13,
        'col14': value.col14,
        'col15': value.col15,
        'col16': value.col16,
        'col17': value.col17,
        'col18': value.col18,
        'col19': value.col19,
        'col20': value.col20,
        'col21': value.col21,
        'col22': value.col22,
        'col23': value.col23,
        'col24': value.col24,
    };
}

