import { useState } from 'react'

import { initRequestParam } from 'constants/constants'
import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type { GetMstItemsItemGetRequest, Item, ResponseResult } from 'api-client'
import type { ErrorResponse } from 'types/types'

export const useItem = () => {
  const [items, setItems] = useState<Item>(initRequestParam)
  const [findItem, setFindItem] = useState<Item>(initRequestParam)
  const [isLoading, setIsLoading] = useState(false)
  const [isFindItemLoading, setIsFindItemLoading] = useState(false)
  const [result, setResult] = useState<ResponseResult>()
  const [findItemresult, setFindItemResult] = useState<ResponseResult>()
  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchItems = (searchParam: GetMstItemsItemGetRequest) => {
    setItems(initRequestParam)
    setResult(undefined)
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getMstItemsItemGet({
          ...searchParam,
          itemName: searchParam.itemName || undefined,
          orgItemCode: searchParam.orgItemCode || undefined,
          orgSupplierId: searchParam.orgSupplierId || undefined,
          itemCode: searchParam.itemCode || undefined,
          barcode: searchParam.barcode || undefined,
        })
      })
      .then((response) => {
        setItems(response.data)
        setResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  // 一つの商品を取得したい場合に使用
  const fetchFindItem = (searchParam: GetMstItemsItemGetRequest) => {
    setFindItem(initRequestParam)
    setFindItemResult(undefined)
    setIsFindItemLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getMstItemsItemGet({
          companyId: searchParam.companyId,
          itemCode: searchParam.itemCode,
        })
      })
      .then((response) => {
        setFindItem(response.data)
        setFindItemResult(response.result)
      })
      .catch(({ response }: ErrorResponse) => {
        setFindItemResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsFindItemLoading(false)
      })
  }

  return {
    result,
    items,
    fetchItems,
    isLoading,

    findItem,
    fetchFindItem,
    findItemresult,
    isFindItemLoading,
  }
}
