import { useState } from 'react'

import { initRequestParam } from 'constants/constants'
import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type { GetOrderAlertOrderAlertGetRequest, OrderAlertList, ResponseResult } from 'api-client'
import type { ErrorResponse } from 'types/types'

export const useOrderAlert = () => {
  const [orderAlert, setOrderAlert] = useState<OrderAlertList>(initRequestParam)
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState<ResponseResult>()
  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchOrderAlert = (searchParam: GetOrderAlertOrderAlertGetRequest) => {
    setOrderAlert(initRequestParam)
    setResult(undefined)
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getOrderAlertOrderAlertGet({
          ...searchParam,
          centerId: searchParam.centerId,
          orgSupplierId: searchParam.orgSupplierId || undefined,
          sort: searchParam.sort,
        })
      })
      .then((response) => {
        setOrderAlert(response.data)
        setResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    orderAlert,
    fetchOrderAlert,
    result,
    isLoading,
  }
}
