import React, { useRef, useState } from 'react'

import UploadIcon from '@mui/icons-material/Upload'
import { Box, Button, CircularProgress, TextField } from '@mui/material'
import { VisuallyHiddenInput, fileInputWidth } from 'style'

import MessageInfo from '../MessageInfo/MessageInfo'

import type { ResponseResult } from 'api-client'

interface UploadButtonProps {
  onClick: (file: File) => Promise<void>
  isLoading?: boolean
  inputWidth?: string
  placeholder?: string
  result?: ResponseResult | undefined
}

function UploadButton({
  onClick,
  isLoading,
  inputWidth = fileInputWidth,
  placeholder = '',
  result,
}: UploadButtonProps) {
  const [file, setFile] = useState<File | null>(null)
  const [fileName, setFileName] = useState<string>('')
  const fileInputRef = useRef<HTMLInputElement | null>(null)

  return (
    <Box display="flex" alignItems="center">
      <TextField
        id="outlined-basic"
        placeholder={placeholder}
        variant="outlined"
        value={fileName}
        inputProps={{
          readOnly: true,
        }}
        sx={{ width: inputWidth }}
      />
      <Button component="label" variant="contained">
        ファイル選択
        <VisuallyHiddenInput
          ref={fileInputRef}
          type="file"
          // csvとエクセルを受け取るようにする
          accept=".csv,.xlsx"
          onChange={(e) => {
            const TrFiles = e.target.files

            if (TrFiles && TrFiles[0]) {
              setFile(TrFiles[0])
              setFileName(TrFiles[0].name)
            }
          }}
        />
      </Button>
      <Button
        variant="contained"
        startIcon={isLoading ? <CircularProgress size={24} color="primary" /> : <UploadIcon />}
        onClick={async () => {
          if (!file) return

          await onClick(file)
          setFile(null)
          setFileName('')
          // これをしないと、同じファイルを選択してもイベントが発火しない
          if (fileInputRef.current) fileInputRef.current.value = ''
        }}
        disabled={!file}
      >
        アップロード
      </Button>
      <MessageInfo result={result} />
    </Box>
  )
}

export default UploadButton
