import type { FormEvent, MouseEvent } from 'react'
import React, { useState } from 'react'

import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material'
import { boxMarginTop, mediumFontSize } from 'style'

interface LoginFormProps {
  login: (email: string, password: string, event: FormEvent<HTMLFormElement>) => Promise<void>
  isLoading: boolean
  isDisable: boolean
}

function LoginForm({ login, isLoading, isDisable }: LoginFormProps) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
  }

  return (
    <Box component="form" onSubmit={(event: FormEvent<HTMLFormElement>) => login(email, password, event)} noValidate>
      <Box>
        <FormControl>
          <InputLabel sx={{ fontSize: mediumFontSize }}>ユーザーID (メールアドレス)</InputLabel>
          <TextField
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={isDisable}
            sx={{ width: '15.7rem' }}
          />
        </FormControl>
      </Box>
      <Box>
        <FormControl>
          <InputLabel htmlFor="password" sx={{ fontSize: mediumFontSize }}>
            Password
          </InputLabel>
          <OutlinedInput
            id="password"
            type={showPassword ? 'text' : 'password'}
            disabled={isDisable}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ width: '15.7rem' }}
          />
        </FormControl>
      </Box>
      {!isDisable && (
        <Box display="flex" justifyContent="center" sx={{ ...boxMarginTop }}>
          <Button type="submit" variant="contained">
            ログイン
            {isLoading && <CircularProgress size={24} color="secondary" />}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default LoginForm
