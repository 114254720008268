/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OrderAlert
 */
export interface OrderAlert {
    /**
     * 
     * @type {number}
     * @memberof OrderAlert
     */
    companyId: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderAlert
     */
    centerId: number | null;
    /**
     * 
     * @type {Date}
     * @memberof OrderAlert
     */
    orderDate: Date | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAlert
     */
    orgSupplierId: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAlert
     */
    supplierName: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderAlert
     */
    orderLimitTime: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderAlert
     */
    todayOrderCount: number | null;
    /**
     * 
     * @type {number}
     * @memberof OrderAlert
     */
    stockAlertCount: number | null;
}

/**
 * Check if a given object implements the OrderAlert interface.
 */
export function instanceOfOrderAlert(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "centerId" in value;
    isInstance = isInstance && "orderDate" in value;
    isInstance = isInstance && "orgSupplierId" in value;
    isInstance = isInstance && "supplierName" in value;
    isInstance = isInstance && "orderLimitTime" in value;
    isInstance = isInstance && "todayOrderCount" in value;
    isInstance = isInstance && "stockAlertCount" in value;

    return isInstance;
}

export function OrderAlertFromJSON(json: any): OrderAlert {
    return OrderAlertFromJSONTyped(json, false);
}

export function OrderAlertFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderAlert {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'centerId': json['center_id'],
        'orderDate': (json['order_date'] === null ? null : new Date(json['order_date'])),
        'orgSupplierId': json['org_supplier_id'],
        'supplierName': json['supplier_name'],
        'orderLimitTime': json['order_limit_time'],
        'todayOrderCount': json['today_order_count'],
        'stockAlertCount': json['stock_alert_count'],
    };
}

export function OrderAlertToJSON(value?: OrderAlert | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'center_id': value.centerId,
        'order_date': (value.orderDate === null ? null : value.orderDate.toISOString()),
        'org_supplier_id': value.orgSupplierId,
        'supplier_name': value.supplierName,
        'order_limit_time': value.orderLimitTime,
        'today_order_count': value.todayOrderCount,
        'stock_alert_count': value.stockAlertCount,
    };
}

