import { useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'

import type { StockUpdateUploadStockUploadPostRequest } from 'api-client'

export const useStockUpload = () => {
  const [isLoading, setIsLoading] = useState(false)

  const fetchStockUpload = (searchParam: StockUpdateUploadStockUploadPostRequest) => {
    setIsLoading(true)

    return auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.stockUpdateUploadStockUploadPost(searchParam)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    fetchStockUpload,
    isLoading,
  }
}
