import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { auth } from 'constants/fireabaseConfig'
import { protectedRoutes } from 'constants/routes'

import { Alert, Card, Link, Typography } from '@mui/material'
import { PasswordResetForm } from 'components/parts'
import { sendPasswordResetEmail } from 'firebase/auth'
import { useURLQuery } from 'hooks/useURLQuery'
import { mediumFontSize } from 'style'
import { BeforeLogin } from 'templates'

function PasswordRest() {
  const navigate = useNavigate()
  const query = useURLQuery()

  const [errorMessage, setErrorMessage] = useState<string | null | undefined>('')

  const passwordReset = async (email: string) => {
    try {
      auth.tenantId = query.get('tenantId')
      auth.languageCode = 'ja'

      await sendPasswordResetEmail(auth, email)
      navigate(`/${protectedRoutes.Login.path}?tenantId=${query.get('tenantId')}`)
    } catch (e) {
      setErrorMessage('エラーが発生しました。もう一度お試しください。')
    }
  }

  return (
    <BeforeLogin>
      <Typography display="flex" justifyContent="center" variant="largeBold" textAlign="center">
        パスワード再設定
      </Typography>
      <Card variant="outlined" sx={{ padding: '1.5rem' }}>
        <PasswordResetForm handleSubmit={passwordReset} />
        {errorMessage && (
          <Alert severity="error" sx={{ marginTop: '1.5rem', width: '15.7rem' }}>
            {errorMessage}
          </Alert>
        )}
      </Card>
      <Link
        href={`${protectedRoutes.Login.path}?${query.toString()}`}
        color="inherit"
        underline="none"
        fontSize={mediumFontSize}
      >
        戻る
      </Link>
    </BeforeLogin>
  )
}

export default PasswordRest
