import type { FormEvent } from 'react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { auth } from 'constants/fireabaseConfig'

import { Box, Typography } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { Configuration, DefaultApi } from 'api-client'
import { ItemDetail } from 'components/commonparts'
import { SearchCondition } from 'components/parts/StockAdjust'
import { useStock } from 'hooks/useStock'
import { useStockUpload } from 'hooks/useStockUpload'
import { useSearchParamStore } from 'store/searchParamStore'
import { getInitCompanyId } from 'utils/getInitParam'
import { getLocalSearchParam } from 'utils/getLocalSearchParam'

import type { GetStockItemTreeStockItemTreeGetRequest, MstItem, ResponseResult } from 'api-client'
import type { DataListStockUpdate } from 'types/types'

function StockAdjust() {
  const { loginUserInfo } = useAuthContext()

  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)
  const searchParamState = useSearchParamStore((state) => state.searchParamState)

  const { result, stock, fetchStock, isLoading } = useStock()
  const { fetchStockUpload, isLoading: stockUploadIsLoading } = useStockUpload()

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  // クエリパラメーターを取得
  const trQueryParam = {
    companyId: Number(query.get('company_id')),
    centerId: Number(query.get('center_id')),
    orgSupplierId: query.get('org_supplier_id'),
    itemCode: query.get('item_code'),
    optimizeRange: query.get('optimize_range'),
  }

  // 自社の検索条件をローカルストレージから取得する
  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)

  const localCompanyId = localSearchParam?.stockAdjust.companyId || undefined
  const localCenterId = localSearchParam?.stockAdjust.centerId || undefined
  const localOrgSupplierId = localSearchParam?.stockAdjust.orgSupplierId || ''
  const localItemCode = localSearchParam?.stockAdjust.itemCode || ''
  const localOptimizeRange = localSearchParam?.stockAdjust.optimizeRange || ''

  const initSearchParam: GetStockItemTreeStockItemTreeGetRequest = {
    // ユーザーの企業ID
    companyId: getInitCompanyId(trQueryParam.companyId, localCompanyId, loginUserInfo!.companyId),
    centerId: trQueryParam.centerId ? trQueryParam.centerId : localCenterId,
    orgSupplierId: trQueryParam.orgSupplierId ? trQueryParam.orgSupplierId : localOrgSupplierId,
    itemCode: trQueryParam.itemCode ? trQueryParam.itemCode : localItemCode,
    optimizeRange: trQueryParam.optimizeRange ? trQueryParam.optimizeRange : localOptimizeRange,
  }

  const [searchParam, setSearchParam] = useState<GetStockItemTreeStockItemTreeGetRequest>(initSearchParam)
  const [itemInfo, setItemInfo] = useState<MstItem | undefined>(undefined)
  const [stockUpdate, setStockUpdate] = useState<DataListStockUpdate[]>([])
  const [trCompanyId, setTrCompanyId] = useState<number>(
    getInitCompanyId(trQueryParam.companyId, localCompanyId, loginUserInfo!.companyId)
  )
  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)
  const [uploadResultMessage, setUploadResultMessage] = useState<ResponseResult | undefined>(undefined)

  const navigate = useNavigate()
  const onSearch = (requestParam: GetStockItemTreeStockItemTreeGetRequest, e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // ガード句
    if (!(e.nativeEvent instanceof SubmitEvent)) return
    const { submitter } = e.nativeEvent

    // idがpageの場合のみ検索実行
    if (submitter?.id === 'page') {
      // 検索API実行
      fetchStock({
        ...requestParam,
      })
      setSearchParam(requestParam)

      // companyIdが自社のcompanyIdのみ検索条件をローカルストレージに保存する
      if (requestParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
        setSearchParamState<GetStockItemTreeStockItemTreeGetRequest>(
          loginUserInfo?.tenantId,
          loginUserInfo?.companyId,
          'StockAdjust',
          {
            ...requestParam,
          }
        )
      }

      // アップデート配列をリセット
      setStockUpdate([])

      setTrCompanyId(requestParam.companyId)

      // 検索実行時に検索条件をクエリパラメータとして付与する
      navigate(
        `?company_id=${requestParam.companyId}&center_id=${requestParam.centerId}&item_code=${requestParam.itemCode}&optimize_range=${requestParam.optimizeRange}`
      )
    }
  }

  // データ保存処理
  const onListUpdate = () => {
    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.stockUpdateStockUpdatePost({
          stockUpdate: {
            body: stockUpdate,
          },
        })
      })
      .then(() => {
        // 検索API実行
        fetchStock({
          ...searchParam,
        })
        setStockUpdate([])
      })
      .catch(() => {
        setResultMessage({
          status: 'error',
          message: '入荷数修正でエラーが発生しました。',
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
  }

  // DataListの初期化処理
  const onInitList = () => {
    fetchStock({
      ...searchParam,
    })
    setStockUpdate([])
  }

  const onStockFileUpload = async (file: File) => {
    try {
      const response = await fetchStockUpload({ postedFile: file })
      if (response?.result.status === 'success') {
        // 検索API実行
        fetchStock({
          ...searchParam,
        })
      }
      setUploadResultMessage(response?.result)
    } catch (e) {
      setUploadResultMessage({
        status: 'error',
        message: 'ファイルアップロードでエラーが発生しました。',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })
    }
  }

  useEffect(() => {
    // クエリパラメータがある場合は初回レンダリング時に検索を実行する
    if ((trQueryParam.centerId && trQueryParam.itemCode) || (localCenterId && localItemCode)) {
      fetchStock(searchParam)

      // companyIdが自社のcompanyIdのみ検索条件をローカルストレージに保存する
      if (searchParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
        setSearchParamState<GetStockItemTreeStockItemTreeGetRequest>(
          loginUserInfo?.tenantId,
          loginUserInfo?.companyId,
          'StockAdjust',
          {
            ...searchParam,
          }
        )
      }
    }
  }, [])

  // 検索メッセージ
  useEffect(() => {
    setResultMessage(result)
  }, [result])

  return (
    <Box>
      <Typography component="div" variant="largeBold">
        商品詳細
      </Typography>
      <SearchCondition
        searchParam={initSearchParam}
        onSearch={onSearch}
        setItemInfo={setItemInfo}
        result={resultMessage}
      />
      <ItemDetail item={itemInfo} defaultExpanded />
    </Box>
  )
}

export default StockAdjust
