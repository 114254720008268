import { useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type {
  CreateUserUserCreatePostRequest,
  ReadUsersUserSearchGetRequest,
  ResponseResult,
  ResponseUpdate,
  UpdateUserUserUpdatePostRequest,
  User,
} from 'api-client'
import type { ErrorResponse } from 'types/types'

export const useUser = () => {
  const [userSearch, setUserSearch] = useState<User>({
    datacount: 0,
    maxcount: 0,
    lastUpdateDatetime: null,
    list: [],
  })
  const [userSearchIsLoading, setUserSearchIsLoading] = useState(false)
  const [userCreateIsLoading, setUserCreateIsLoading] = useState(false)
  const [userUpdateIsLoading, setUserUpdateIsLoading] = useState(false)
  const [userSearchResult, setUserSearchResult] = useState<ResponseResult | undefined>(undefined)
  const [userCreateResult, setUserCreateResult] = useState<ResponseUpdate | undefined>(undefined)
  const [userUpdateResult, setUserUpdateResult] = useState<ResponseUpdate | undefined>(undefined)
  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchUserSearch = (searchParam: ReadUsersUserSearchGetRequest) => {
    setUserSearchIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.readUsersUserSearchGet(searchParam)
      })
      .then((response) => {
        setUserSearch(response.data)
        setUserSearchResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setUserSearchResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setUserSearchIsLoading(false)
      })
  }

  const fetchUserCreate = (searchParam: CreateUserUserCreatePostRequest) => {
    setUserCreateIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.createUserUserCreatePost(searchParam)
      })
      .then((response) => {
        setUserCreateResult(response)
      })
      .catch(({ response }: ErrorResponse) => {
        setUserCreateResult({
          errors: [],
          result: {
            status: 'error',
            message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
            systemDate: null,
            pageNo: 0,
            systemInfo: null,
          },
          updateCount: null,
        })
      })
      .finally(() => {
        setUserCreateIsLoading(false)
      })
  }

  const fetchUserUpdate = (searchParam: UpdateUserUserUpdatePostRequest) => {
    setUserUpdateIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.updateUserUserUpdatePost(searchParam)
      })
      .then((response) => {
        setUserUpdateResult(response)
      })
      .catch(({ response }: ErrorResponse) => {
        setUserUpdateResult({
          errors: [],
          result: {
            status: 'error',
            message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
            systemDate: null,
            pageNo: 0,
            systemInfo: null,
          },
          updateCount: null,
        })
      })
      .finally(() => {
        setUserUpdateIsLoading(false)
      })
  }

  return {
    userSearch,
    fetchUserSearch,
    userSearchResult,
    userSearchIsLoading,

    fetchUserCreate,
    userCreateResult,
    userCreateIsLoading,

    fetchUserUpdate,
    userUpdateResult,
    userUpdateIsLoading,
  }
}
