import type { FormEvent } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { auth } from 'constants/fireabaseConfig'
import { protectedRoutes } from 'constants/routes'

import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  InputLabel,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { LoginForm } from 'components/parts'
import {
  signInWithEmailAndPassword,
  RecaptchaVerifier,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
  PhoneAuthProvider,
} from 'firebase/auth'
import { fetchMfaInfo, useLoginUserInfo } from 'hooks/useLoginUserInfo'
import { useURLQuery } from 'hooks/useURLQuery'
import { boxMarginTop, mediumFontSize } from 'style'
import { BeforeLogin } from 'templates'

import type { FirebaseError } from 'firebase/app'
import type { MultiFactorError, MultiFactorResolver } from 'firebase/auth'

function Login() {
  const NEEDS_MFA_ERROR = 'auth/multi-factor-auth-required'
  const INVALID_CODE_ERROR = 'auth/invalid-verification-code'
  const { loginUserInfoResult, setLoginUserInfo, setLoginUserInfoResult, setIsLoginLoading } = useAuthContext()

  // const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | undefined>()
  // const [mfaResolver, setMfaResolver] = useState<MultiFactorResolver | undefined>()
  const [verificationId, setVerificationId] = useState<string>('')
  const [verificationCode, setVerificationCode] = useState<string>('')
  const [needsVerify, setNeedsVerify] = useState<boolean>(false)

  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null>(null)
  const mfaResolverRef = useRef<MultiFactorResolver | null>(null)
  const { fetchLoginUserInfo, isLoading } = useLoginUserInfo()

  const query = useURLQuery()
  const navigate = useNavigate()

  const [errorMessage, setErrorMessage] = useState<string | null | undefined>('')

  const setRecaptcha = async (): Promise<RecaptchaVerifier | null> => {
    if (recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current.clear()
    }

    try {
      recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
      })
      const id = await recaptchaVerifierRef.current.render()
    } catch (e) {
      // 開発環境ではuseEffectが2回実行されてしまい、renderでエラーが発生するため、
      // それを回避するためにtry catchを使用。
    }

    return null
  }

  const requestAuthCode = async (error: FirebaseError) => {
    if (!recaptchaVerifierRef.current) {
      return
    }

    mfaResolverRef.current = getMultiFactorResolver(auth, error as MultiFactorError)

    if (!mfaResolverRef.current) {
      return
    }

    const selectedIndex = 0
    const hint = mfaResolverRef.current.hints[selectedIndex]
    if (hint.factorId !== PhoneMultiFactorGenerator.FACTOR_ID) {
      return
    }

    const phoneInfoOptions = {
      multiFactorHint: hint,
      session: mfaResolverRef.current.session,
    }
    const phoneAuthProvider = new PhoneAuthProvider(auth)
    const resultId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifierRef.current)
    setVerificationId(resultId)
  }

  const login = async (email: string, password: string, event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    setErrorMessage(null)

    // テナントID
    auth.tenantId = query.get('tenantId')
    auth.languageCode = 'ja'

    try {
      await signInWithEmailAndPassword(auth, email, password)
      setIsLoginLoading(true)

      await fetchLoginUserInfo()?.then((response) => {
        if (response.data) {
          const loginUserWithMfa = {
            ...response.data,
            ...fetchMfaInfo(),
          }

          setLoginUserInfo(loginUserWithMfa)
        }

        setLoginUserInfoResult(response.result)
        navigate(protectedRoutes.Top.path)
      })
    } catch (e) {
      const fbe = e as FirebaseError
      if (fbe?.code === NEEDS_MFA_ERROR) {
        setNeedsVerify(true)
        await setRecaptcha()

        if (!recaptchaVerifierRef.current) {
          console.error(`verifierが不正な値: ${recaptchaVerifierRef.current}`)
          return
        }

        await requestAuthCode(fbe)
      } else {
        setErrorMessage('メールアドレスまたはパスワードが異なります。')
      }
    } finally {
      setIsLoginLoading(false)
    }
  }

  const loginWithAuthCode = async () => {
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

    if (!mfaResolverRef.current) {
      return
    }

    try {
      setIsLoginLoading(true)
      const res = await mfaResolverRef.current.resolveSignIn(multiFactorAssertion)
      await fetchLoginUserInfo()
      navigate(protectedRoutes.Top.path)
    } catch (e) {
      const fbe = e as FirebaseError
      if (fbe?.code === INVALID_CODE_ERROR) {
        setErrorMessage('認証コードが違います')
      } else {
        console.error(`不明なエラー: ${e}`)
      }
    } finally {
      setIsLoginLoading(false)
    }
  }

  useEffect(() => {
    // login-user-infoのメッセージを取得する
    if (loginUserInfoResult?.status !== 'success') {
      setErrorMessage(loginUserInfoResult?.message)
    }
  }, [loginUserInfoResult])

  return (
    <BeforeLogin>
      <Card variant="outlined" sx={{ padding: '1.5rem' }}>
        <LoginForm login={login} isLoading={isLoading} isDisable={needsVerify} />
        {errorMessage && (
          // firebaseまたはlogin-user-info APIのエラーメッセージがあった場合は表示する
          <Alert severity="error" sx={{ marginTop: '1.5rem', width: '15.7rem' }}>
            {errorMessage}
          </Alert>
        )}

        {needsVerify && (
          <div>
            <Alert severity="warning" sx={{ marginTop: '1.5rem', width: '15.7rem' }}>
              <Typography sx={{ fontWeight: 'bold' }}>2段階認証が必要です</Typography>
              <Typography>携帯電話に送信された6桁の認証コードを入力してください</Typography>
            </Alert>
            <FormControl>
              <InputLabel sx={{ fontSize: mediumFontSize }}>認証コード</InputLabel>
              <TextField
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                sx={{ width: '15.7rem' }}
              />
            </FormControl>
            <Box display="flex" justifyContent="center" sx={{ ...boxMarginTop }}>
              <Button variant="contained" onClick={loginWithAuthCode}>
                コード送信
                {isLoading && <CircularProgress size={24} color="secondary" />}
              </Button>
            </Box>
          </div>
        )}
      </Card>
      <Link
        href={`${protectedRoutes.PasswordRest.path}?${query.toString()}`}
        color="inherit"
        underline="none"
        fontSize={mediumFontSize}
      >
        ※パスワードを忘れた場合
      </Link>
      <div id="recaptcha-container" />
    </BeforeLogin>
  )
}

export default Login
