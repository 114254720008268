import { useState } from 'react'

import { initRequestParamWithDaylist } from 'constants/constants'
import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import _ from 'lodash'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type { GetStockStockGetRequest, ResponseResult, Stock, Stocks } from 'api-client'
import type { ErrorResponse } from 'types/types'

export interface StockAdjust extends Stock {
  id: string
  systemDate: Date | null
  arrivalQuantityPrev: number | null
  shipQuantityPrev: number | null
  stockQuantityPrev: number | null
  orderQuantityReasonPrev: string | null
}

export interface StocksAdjust extends Stocks {
  list: StockAdjust[]
}

export const useStock = () => {
  const [stock, setStock] = useState<StocksAdjust>(initRequestParamWithDaylist)
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState<ResponseResult | undefined>(undefined)
  const { setCurrentStatuses } = useCurrentStatusStore()

  const calcErraRate = (shipQuantityExpected: number | null, shipQuantityRec: number | null) => {
    if (shipQuantityRec == null) return null

    return (
      (shipQuantityExpected == null ? 0 : shipQuantityExpected - shipQuantityRec) /
      (shipQuantityRec === 0 ? 1 : shipQuantityRec)
    )
  }

  const fetchStock = (searchParam: GetStockStockGetRequest) => {
    setStock(initRequestParamWithDaylist)
    setResult(undefined)
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getStockStockGet({
          ...searchParam,
          companyId: searchParam.companyId,
          centerId: searchParam.centerId || undefined,
          orgSupplierId: searchParam.orgSupplierId || undefined,
          itemCode: searchParam.itemCode || undefined,
          optimizeRange: searchParam.optimizeRange,
          sort: searchParam.sort || undefined,
        })
      })
      .then((response) => {
        setStock({
          ...response.data,
          list: _.map(response.data.list, (value) => {
            const recDate = new Date(value.recDate as Date)
            return {
              ...value,
              // 用途:ダッシュボードページ
              // rechartsライブラリのdataKeyに合わせてnumberにする
              recDateToNumber: recDate.getTime(),
              errorRate: calcErraRate(value.shipQuantityExpected, value.shipQuantityRec),

              // 用途:入荷数修正ページ
              id: Math.random().toString(32).substring(2),
              systemDate: response.result.systemDate,
              arrivalQuantityPrev: value.arrivalQuantity,
              shipQuantityPrev: value.shipQuantity,
              stockQuantityPrev: value.stockQuantity,
              stockDaysPrev: value.stockDays,
              // DataGridのセレクターの型がstringのため、nullの場合は空文字を返す
              orderQuantityReasonPrev: value.orderQuantityReason ?? '',
              orderQuantityReason: value.orderQuantityReason ?? '',
            }
          }),
        })
        setResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    result,
    stock,
    fetchStock,
    isLoading,
  }
}
