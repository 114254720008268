import type { Dispatch, FormEvent, SetStateAction } from 'react'
import React, { useState } from 'react'

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { SearchCustomer, SearchFrom, SelectCenter, SelectSupplier, SelectTypeCode } from 'components/commonparts'

import type { GetStockItemTreeStockItemTreeGetRequest, MstSupplier, ResponseResult } from 'api-client'

interface SearchConditionProps {
  searchParam: GetStockItemTreeStockItemTreeGetRequest
  onSearch: (requestParam: GetStockItemTreeStockItemTreeGetRequest) => void
  setSupplierInfo: Dispatch<SetStateAction<MstSupplier | undefined>>
  onCsvDownLoad: () => void
  onExcelDownLoad: () => void
  isCsvLoading: boolean
  isXlsxLoading: boolean
  result?: ResponseResult | undefined
  hasGridEdited?: boolean
}

function SearchCondition({
  searchParam,
  onSearch,
  setSupplierInfo,
  onCsvDownLoad,
  onExcelDownLoad,
  isCsvLoading,
  isXlsxLoading,
  result,
  hasGridEdited,
}: SearchConditionProps) {
  const { loginUserInfo } = useAuthContext()

  const [centerIsValid, setCenterIsValid] = useState(true)
  const [supplierIsValid, setSupplierIsValid] = useState(true)
  const [centerId, setCenterId] = useState<number | null | undefined>(searchParam.centerId)
  const [customerCompanyId, setCustomerCompanyId] = useState<number | undefined>(
    // companyIdがログインユーザーの企業IDの場合はundefinedにする
    searchParam.companyId === loginUserInfo!.companyId ? undefined : searchParam.companyId
  )
  const [orgSupplierId, setOrgSupplierId] = useState(searchParam.orgSupplierId)
  const [viweUnitTypeCode, setViewUnitTypeCode] = useState(searchParam.viewUnitType)
  const [optimizeRangeCode, setOptimizeRangeCode] = useState(searchParam.optimizeRange)
  const [discardChangeDialogOpen, setDiscardChangeDialogOpen] = useState<boolean>(false)

  const onClear = () => {
    setCustomerCompanyId(undefined)
    setCenterId(undefined)
    setOrgSupplierId(undefined)
  }

  const executeSubmit = () => {
    // 必須入力チェック
    if (!centerIsValid && !supplierIsValid) {
      onSearch({
        companyId: customerCompanyId ?? loginUserInfo!.companyId,
        centerId,
        orgSupplierId,
        optimizeRange: optimizeRangeCode,
        viewUnitType: viweUnitTypeCode,
      })
    }
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!(e.nativeEvent instanceof SubmitEvent)) return
    const { submitter } = e.nativeEvent

    // idがpageの場合のみ検索実行
    if (submitter?.id !== 'page') return

    if (hasGridEdited) {
      setDiscardChangeDialogOpen(true)
      return
    }
    executeSubmit()
  }

  const handleDiscardChangeDialogClose = () => {
    setDiscardChangeDialogOpen(false)
  }

  const handleForceSearch = () => {
    executeSubmit()
    setDiscardChangeDialogOpen(false)
  }

  return (
    <Box component="form" onSubmit={onSubmit}>
      <SearchFrom
        onClear={onClear}
        onCsvDownLoad={() => {
          if (!centerIsValid && !supplierIsValid) {
            onCsvDownLoad()
          }
        }}
        onExcelDownLoad={() => {
          if (!centerIsValid && !supplierIsValid) {
            onExcelDownLoad()
          }
        }}
        isDownload
        isCsvLoading={isCsvLoading}
        isXlsxLoading={isXlsxLoading}
        result={result}
      >
        <SearchCustomer
          title="会社"
          trCompanyId={loginUserInfo!.companyId}
          customerCompanyId={customerCompanyId}
          setCustomerCompanyId={setCustomerCompanyId}
          setCustomerCompanyInfo={() => {}}
          isRequred={false}
          setIsValid={() => {}}
        />
        <SelectCenter
          trCompanyId={customerCompanyId}
          centerId={centerId}
          setCenterId={setCenterId}
          setCenterInfo={() => {}}
          isRequired
          setIsValid={setCenterIsValid}
        />
        <SelectSupplier
          trCompanyId={customerCompanyId}
          orgSupplierId={orgSupplierId}
          setOrgSupplierId={setOrgSupplierId}
          setSupplierInfo={setSupplierInfo}
          isRequred
          setIsValid={setSupplierIsValid}
        />
        <SelectTypeCode
          typeGroup="OptimizeRange"
          typeCode={optimizeRangeCode}
          setTypeCode={setOptimizeRangeCode}
          setTypeCodeInfo={() => {}}
        />
        <SelectTypeCode
          typeGroup="ViewUnitType"
          typeCode={viweUnitTypeCode}
          setTypeCode={setViewUnitTypeCode}
          setTypeCodeInfo={() => {}}
        />
      </SearchFrom>
      <Dialog
        open={discardChangeDialogOpen}
        onClose={handleDiscardChangeDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">変更破棄の確認</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            編集中の内容があります。変更を破棄して検索しますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDiscardChangeDialogClose}>キャンセル</Button>
          <Button variant="contained" onClick={handleForceSearch} autoFocus>
            破棄して検索
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default SearchCondition
