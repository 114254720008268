import React from 'react'

import { Box, CircularProgress, IconButton, styled } from '@mui/material'
import { useGridApiContext, useGridRootProps } from '@mui/x-data-grid-premium'
import { useTreeDataUtilityClasses } from 'style'

import type { IconButtonProps } from '@mui/material'
import type { GridGroupNode, GridRenderCellParams } from '@mui/x-data-grid-premium'

export type GroupingCellWithLazyLoadingProps = GridRenderCellParams<any, any, any, GridGroupNode>

function GroupingCellWithLazyLoading({ id, rowNode, row, formattedValue }: GroupingCellWithLazyLoadingProps) {
  const rootProps = useGridRootProps()
  const apiRef = useGridApiContext()
  const classes = useTreeDataUtilityClasses({ classes: rootProps.classes })

  const isLoading = rowNode.childrenExpanded ? !row.childrenFetched : false

  const Icon = rowNode.childrenExpanded ? rootProps.slots.treeDataCollapseIcon : rootProps.slots.treeDataExpandIcon

  const handleClick: IconButtonProps['onClick'] = () => {
    // 行の展開を手動で設定
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded)
  }

  const LoadingContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  })

  return (
    <Box className={classes.root} sx={{ ml: rowNode.depth * 2 }}>
      <div className={classes.toggle}>
        {/* 親要素のみ開閉アイコンを表示させる */}
        {rowNode.children &&
          (isLoading ? (
            <LoadingContainer>
              <CircularProgress size="1rem" color="inherit" />
            </LoadingContainer>
          ) : (
            <IconButton
              size="small"
              onClick={handleClick}
              tabIndex={-1}
              aria-label={
                rowNode.childrenExpanded
                  ? apiRef.current.getLocaleText('treeDataCollapse')
                  : apiRef.current.getLocaleText('treeDataExpand')
              }
            >
              <Icon fontSize="inherit" />
            </IconButton>
          ))}
      </div>
      <span>{formattedValue === undefined ? rowNode.groupingKey : formattedValue}</span>
    </Box>
  )
}

export default GroupingCellWithLazyLoading
