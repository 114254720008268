import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button } from '@mui/material'

interface BackButtonProps {
  caption?: string
  herf: string
}

function BackButton({ herf, caption = '戻る' }: BackButtonProps) {
  const navigate = useNavigate()

  return (
    <Button variant="contained" color="secondary" onClick={() => navigate(herf)}>
      {caption}
    </Button>
  )
}

export default BackButton
