import _ from 'lodash'
import { initDataListStatus } from 'types/types'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import type {
  GetMstItemsItemGetRequest,
  GetOrderAlertOrderAlertGetRequest,
  GetShipExpectedShipExpectedGetRequest,
  GetStockItemTreeStockItemTreeGetRequest,
  GetStockStockGetRequest,
} from 'api-client'

export interface SearchParamState {
  tenantId: string
  companyId: number
  searchParam: {
    top: GetOrderAlertOrderAlertGetRequest
    dashboard: GetStockStockGetRequest
    shipExpectedCenter: GetShipExpectedShipExpectedGetRequest
    shipExpectedItem: GetShipExpectedShipExpectedGetRequest
    stockAdjust: GetStockStockGetRequest
    stockCenter: GetStockItemTreeStockItemTreeGetRequest
    item: GetMstItemsItemGetRequest
  }
}

const SearchParamKey = {
  Top: 'top',
  Dashboard: 'dashboard',
  ShipExpectedCenter: 'shipExpectedCenter',
  ShipExpectedItem: 'shipExpectedItem',
  StockAdjust: 'stockAdjust',
  StockCenter: 'stockCenter',
  Item: 'item',
} as const

const initSearchParam = {
  top: {
    centerId: 0,
    orgSupplierId: undefined,
    sort: [],
    limit: initDataListStatus().paginationModel.pageSize,
    pageNo: initDataListStatus().paginationModel.page,
  },
  dashboard: {
    companyId: 0,
    centerId: undefined,
    itemCode: '',
    dateRange: 'year',
  },
  shipExpectedCenter: {
    companyId: 0,
    centerId: undefined,
    viewDateType: '',
    viewUnitTsype: '',
    itemCode: '',
  },
  shipExpectedItem: {
    companyId: 0,
    centerId: undefined,
    viewDateType: '',
    viewUnitType: '',
    itemCode: '',
  },
  stockAdjust: {
    companyId: 0,
    centerId: undefined,
    viewUnitType: '',
    orgSupplierId: '',
    itemCode: '',
    optimizeRange: '',
  },
  stockCenter: {
    companyId: 0,
    centerId: undefined,
    viewUnitType: '',
    orgSupplierId: '',
    itemCode: '',
    optimizeRange: '',
  },
  item: {
    companyId: 0,
    itemCode: '',
    itemName: '',
    orgItemCode: '',
    barcode: '',
    orgSupplierId: undefined,
    sort: [],
    limit: initDataListStatus().paginationModel.pageSize,
    pageNo: initDataListStatus().paginationModel.page,
  },
}

interface SearchParamStore {
  searchParamState: SearchParamState[]
  setSearchParamState: <T>(
    tenantId: string,
    companyId: number,
    searchParamKey: keyof typeof SearchParamKey,
    searchParam: T
  ) => void
}

const updateSearchParam = <T>(
  state: SearchParamState[],
  tenantId: string,
  companyId: number,
  searchParamKey: keyof typeof SearchParamKey,
  searchParam: T
) => {
  if (_.find(state, { tenantId, companyId }) === undefined) {
    return [
      ...state,
      {
        tenantId,
        companyId,
        searchParam: {
          ...initSearchParam,
          [`${SearchParamKey[searchParamKey]}`]: {
            ...searchParam,
          },
        },
      },
    ]
  }

  return _.map(state, (prevValue) => {
    if (prevValue.tenantId === tenantId && prevValue.companyId === companyId) {
      return {
        ...prevValue,
        searchParam: {
          ...prevValue.searchParam,
          [`${SearchParamKey[searchParamKey]}`]: {
            ...searchParam,
          },
        },
      }
    }

    return { ...prevValue }
  })
}

// NOTE: hooksのuserからcompanyIdを取得したいが、hooksはコンポーネントでしか呼び出すことができないため、初期値を0として
// 各page配下で初期値の設定を行う
// src/utils/getInitCompanyId.ts
export const useSearchParamStore = create<SearchParamStore>()(
  persist(
    (set) => ({
      searchParamState: [],
      setSearchParamState: <T>(
        tenantId: string,
        companyId: number,
        searchParamKey: keyof typeof SearchParamKey,
        searchParam: T
      ) => {
        set((state) => ({
          searchParamState: updateSearchParam<T>(
            state.searchParamState,
            tenantId,
            companyId,
            searchParamKey,
            searchParam
          ),
        }))
      },
    }),
    {
      // ローカルストレージの構造が変わる場合はversionとmigrateを更新することで新しいストアが更新される
      version: 2,
      name: 'search-param-storage',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      migrate: (persistedState: SearchParamStore, version) => {
        if (version === 1) {
          // eslint-disable-next-line no-param-reassign
          persistedState.searchParamState = []
        }

        return persistedState
      },
    }
  )
)
