/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstSupplier
 */
export interface MstSupplier {
    /**
     * 
     * @type {number}
     * @memberof MstSupplier
     */
    companyId: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstSupplier
     */
    supplierCompanyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    orgSupplierId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    supplierName: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    supplierHojinNo: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    supplierAddress: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    supplierGln: string | null;
    /**
     * 
     * @type {number}
     * @memberof MstSupplier
     */
    minOrderQuantityOnce: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstSupplier
     */
    orderLeadtime: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstSupplier
     */
    orderLimitTime: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstSupplier
     */
    updateDatetime: Date | null;
}

/**
 * Check if a given object implements the MstSupplier interface.
 */
export function instanceOfMstSupplier(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "supplierCompanyId" in value;
    isInstance = isInstance && "orgSupplierId" in value;
    isInstance = isInstance && "supplierName" in value;
    isInstance = isInstance && "supplierHojinNo" in value;
    isInstance = isInstance && "supplierAddress" in value;
    isInstance = isInstance && "supplierGln" in value;
    isInstance = isInstance && "minOrderQuantityOnce" in value;
    isInstance = isInstance && "orderLeadtime" in value;
    isInstance = isInstance && "orderLimitTime" in value;
    isInstance = isInstance && "updateDatetime" in value;

    return isInstance;
}

export function MstSupplierFromJSON(json: any): MstSupplier {
    return MstSupplierFromJSONTyped(json, false);
}

export function MstSupplierFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstSupplier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'supplierCompanyId': json['supplier_company_id'],
        'orgSupplierId': json['org_supplier_id'],
        'supplierName': json['supplier_name'],
        'supplierHojinNo': json['supplier_hojin_no'],
        'supplierAddress': json['supplier_address'],
        'supplierGln': json['supplier_gln'],
        'minOrderQuantityOnce': json['min_order_quantity_once'],
        'orderLeadtime': json['order_leadtime'],
        'orderLimitTime': json['order_limit_time'],
        'updateDatetime': (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
    };
}

export function MstSupplierToJSON(value?: MstSupplier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'supplier_company_id': value.supplierCompanyId,
        'org_supplier_id': value.orgSupplierId,
        'supplier_name': value.supplierName,
        'supplier_hojin_no': value.supplierHojinNo,
        'supplier_address': value.supplierAddress,
        'supplier_gln': value.supplierGln,
        'min_order_quantity_once': value.minOrderQuantityOnce,
        'order_leadtime': value.orderLeadtime,
        'order_limit_time': value.orderLimitTime,
        'update_datetime': (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
    };
}

