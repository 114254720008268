import type { SyntheticEvent } from 'react'
import React, { useState } from 'react'

import { Box, Tab, Tabs, Typography } from '@mui/material'
import { CustomTabPanel, FileUpload } from 'components/commonparts'
import OptimizeRequest from 'components/commonparts/OptimizeRequest/OptimizeRequest'
import { UploadRecordTab, useUploadRecordTabStore } from 'store/uploadRecordTabStore'

function UploadRecord() {
  const uploadRecordTabState = useUploadRecordTabStore((state) => state.uploadRecordTabState)
  const setUploadRecordTabsState = useUploadRecordTabStore((state) => state.setUploadRecordTabsState)

  const [value, setValue] = useState(uploadRecordTabState)

  const handleChange = (_event: SyntheticEvent, newValue: (typeof UploadRecordTab)[keyof typeof UploadRecordTab]) => {
    setValue(newValue)
    setUploadRecordTabsState(newValue)
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return (
    <Box>
      <Typography component="div" variant="largeBold">
        実績データ登録
      </Typography>
      <Box sx={{ marginBottom: 1, borderBottom: 1 }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Tab value={UploadRecordTab.Order} label="発注" {...a11yProps(0)} />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Tab value={UploadRecordTab.Ship} label="受注 (出荷予測のみ利用の場合）" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={UploadRecordTab.Order}>
        <FileUpload dataType="TOrderArrival" title="発注入荷実績" isDownload={false} />
        <FileUpload dataType="TStock" title="在庫実績" isDownload={false} />
        <FileUpload dataType="TShippingOrder" title="受注出荷実績" isDownload={false} />
        <OptimizeRequest />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={UploadRecordTab.Ship}>
        <FileUpload dataType="TShippingOrder" title="受注出荷実績" isDownload={false} />
      </CustomTabPanel>
    </Box>
  )
}

export default UploadRecord
