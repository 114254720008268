import type { Ref } from 'react'
import React from 'react'

import { Box } from '@mui/material'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'block',
  width: '95%',
  maxHeight: '97vh',
  overflow: 'scroll',
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 16,
  pt: 1,
  px: 2,
  pb: 2,
}

interface DialogWrapperProps {
  children: JSX.Element | JSX.Element[]
  forwardRef?: Ref<HTMLDivElement>
}

// MUIのModalコンポーネントの中を別コンポーネントとして切り出すとエラーが発生するため以下のように対処
function DialogLayout({ children, forwardRef }: DialogWrapperProps) {
  return (
    <Box sx={style} ref={forwardRef} tabIndex={0}>
      {children}
    </Box>
  )
}

const DialogWrapper = React.forwardRef<HTMLDivElement, DialogWrapperProps>(({ children, forwardRef }, ref) => (
  // eslint-disable-next-line react/no-children-prop
  <DialogLayout children={children} forwardRef={ref} />
))

export default DialogWrapper
