import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { protectedRoutes } from 'constants/routes'

import CheckIcon from '@mui/icons-material/Check'
import { type GridColDef } from '@mui/x-data-grid'
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'

import type { GridRenderCellParams } from '@mui/x-data-grid-premium'
import type { CompanyList } from 'api-client'
import type { DataListStatus } from 'types/types'

interface DataListProps {
  list: CompanyList
  isLoading: boolean
  listStatus: DataListStatus
  onListStatusChange: (status: DataListStatus) => void
}

function DataList({ list, isLoading, listStatus, onListStatusChange }: DataListProps) {
  const columns: GridColDef[] = [
    {
      field: 'companyId',
      headerName: 'プロダクト統一企業ID',
      type: 'string',
      renderCell: (params: GridRenderCellParams) => (
        <Link
          className="text-blue-400 underline"
          style={{ textDecoration: 'none' }}
          // ユーザー企業情報更新リンク
          to={`/${protectedRoutes.CompanyDetail.path}?company_id=${params.row.companyId}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      headerName: '企業名',
      field: 'companyName',
      type: 'string',
    },
    {
      headerName: '会社法人等番号（登記所）',
      field: 'companyHojinNo',
      type: 'string',
    },
    {
      headerName: '本社住所',
      field: 'companyAddress',
      type: 'string',
    },
    {
      headerName: 'GS1事業者コード',
      field: 'companyGln',
      type: 'string',
    },
    {
      headerName: '業態',
      field: 'businessCategoryName',
      type: 'string',
      minWidth: 140,
    },
    {
      headerName: 'テナントID',
      field: 'tenantId',
      type: 'string',
    },
    {
      headerName: '無効',
      field: 'deleteFlg',
      type: 'boolean',
      renderCell: (params) => {
        if (!params.value) return ''
        return <CheckIcon />
      },
      minWidth: 70,
    },
    {
      field: '',
      headerName: 'ユーザー一覧',
      type: 'string',
      minWidth: 120,
      valueGetter: (params) => params.row.companyId,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          className="text-blue-400 underline"
          style={{ textDecoration: 'none' }}
          // ユーザー企業情報更新リンク
          to={`/${protectedRoutes.UserList.path}?company_id=${params.row.companyId}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: 'ログインURL',
      headerName: 'ログインURL',
      type: 'string',
      minWidth: 500,
      valueGetter: (params) =>
        `${new URL(document.URL).origin}/login${params.row.tenantId ? `?tenantId=${params.row.tenantId}` : ''}`,
    },
  ]

  // セルの自動調整機能 premium版のみ利用可能
  const apiRef = useGridApiRef()
  const autosizeColumns = {
    columns: ['companyId', 'companyName', 'companyHojinNo', 'companyAddress', 'companyGln', 'tenantId', 'deleteFlg'],
    includeHeaders: true,
    includeOutliere: true,
    outliersFactor: 1.5,
  }

  // https://mui.com/x/react-data-grid/column-dimensions/#autosizing
  // 上記のセルの自動調整機能採用しているが、正式的な機能ではない
  useEffect(() => {
    if (list.list.length > 0 && apiRef.current.autosizeColumns) {
      // NOTE:データをdataGridにバインドしてからじゃないと自動調節が効かないため意図的にずらして実行
      setTimeout(() => apiRef.current.autosizeColumns(autosizeColumns), 1000)
    }
  }, [list.list])

  return (
    <DataGridPremium
      // 画面サイズによって1画面で収まるように高さを調節する
      sx={{ maxHeight: 'calc(100vh - 200px)' }}
      rows={list.list}
      rowCount={list.maxcount as number}
      columns={columns}
      initialState={{
        pagination: { paginationModel: listStatus.paginationModel },
      }}
      paginationMode="server"
      loading={isLoading}
      getRowId={(row) => row.companyId}
      pagination
      paginationModel={listStatus.paginationModel}
      onPaginationModelChange={(pgState) => {
        onListStatusChange({ sortModel: listStatus.sortModel, paginationModel: pgState })
      }}
      apiRef={apiRef}
      density="compact"
    />
  )
}

export default DataList
