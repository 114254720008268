import type { FormEvent } from 'react'
import React, { useState } from 'react'

import { Box } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { SearchFrom, SelectCenter, SelectSupplier } from 'components/commonparts'

import type { GetOrderAlertOrderAlertGetRequest, ResponseResult } from 'api-client'

interface SearchConditionProps {
  searchParam: GetOrderAlertOrderAlertGetRequest
  onSearch: (requestParam: GetOrderAlertOrderAlertGetRequest, e: FormEvent<HTMLFormElement>) => void
  result?: ResponseResult | undefined
}

function SearchCondition({ searchParam, onSearch, result }: SearchConditionProps) {
  const { loginUserInfo } = useAuthContext()

  const [centerIsValid, setCenterIsValid] = useState(true)
  const [centerId, setCenterId] = useState<number | null | undefined>(
    // NOTE:centerIdは必須項目になっていて0を初期値としているため、0の場合はundefinedにする
    searchParam.centerId === 0 ? undefined : searchParam.centerId
  )
  const [orgSupplierId, setOrgSupplierId] = useState(searchParam.orgSupplierId)

  const onClear = () => {
    setCenterId(undefined)
    setOrgSupplierId(undefined)
  }

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault()

        // 必須入力チェック
        if (!centerIsValid && centerId) {
          onSearch(
            {
              centerId,
              orgSupplierId,
            },
            e
          )
        }
      }}
    >
      <SearchFrom onClear={onClear} result={result}>
        <SelectCenter
          trCompanyId={loginUserInfo!.companyId}
          centerId={centerId}
          setCenterId={setCenterId}
          setCenterInfo={() => {}}
          isRequired
          setIsValid={setCenterIsValid}
        />
        <SelectSupplier
          trCompanyId={loginUserInfo!.companyId}
          orgSupplierId={orgSupplierId}
          setOrgSupplierId={setOrgSupplierId}
          setSupplierInfo={() => {}}
          isRequred={false}
          setIsValid={() => {}}
        />
      </SearchFrom>
    </Box>
  )
}

export default SearchCondition
