/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyOutput
 */
export interface CompanyOutput {
    /**
     * 
     * @type {number}
     * @memberof CompanyOutput
     */
    companyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyOutput
     */
    companyName: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyOutput
     */
    companyHojinNo: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyOutput
     */
    companyAddress: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyOutput
     */
    companyGln: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyOutput
     */
    businessCategory: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyOutput
     */
    businessCategoryName: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyOutput
     */
    tenantId: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyOutput
     */
    deleteFlg: boolean | null;
}

/**
 * Check if a given object implements the CompanyOutput interface.
 */
export function instanceOfCompanyOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "companyName" in value;
    isInstance = isInstance && "companyHojinNo" in value;
    isInstance = isInstance && "companyAddress" in value;
    isInstance = isInstance && "companyGln" in value;
    isInstance = isInstance && "businessCategory" in value;
    isInstance = isInstance && "businessCategoryName" in value;
    isInstance = isInstance && "tenantId" in value;
    isInstance = isInstance && "deleteFlg" in value;

    return isInstance;
}

export function CompanyOutputFromJSON(json: any): CompanyOutput {
    return CompanyOutputFromJSONTyped(json, false);
}

export function CompanyOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'companyName': json['company_name'],
        'companyHojinNo': json['company_hojin_no'],
        'companyAddress': json['company_address'],
        'companyGln': json['company_gln'],
        'businessCategory': json['business_category'],
        'businessCategoryName': json['business_category_name'],
        'tenantId': json['tenant_id'],
        'deleteFlg': json['delete_flg'],
    };
}

export function CompanyOutputToJSON(value?: CompanyOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'company_name': value.companyName,
        'company_hojin_no': value.companyHojinNo,
        'company_address': value.companyAddress,
        'company_gln': value.companyGln,
        'business_category': value.businessCategory,
        'business_category_name': value.businessCategoryName,
        'tenant_id': value.tenantId,
        'delete_flg': value.deleteFlg,
    };
}

