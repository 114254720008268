import type { Dispatch, SetStateAction } from 'react'
import React, { useEffect, useState } from 'react'

import { CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import _ from 'lodash'

import type { Groups } from 'api-client'

export const TypeGroup = {
  DateType: 'date_type',
  ViewDateType: 'view_date_type',
  ViewUnitType: 'view_unit_type',
  OrderUnitType: 'order_unit_type',
  OptimizeRange: 'optimize_range',
  OrderQuantityReason: 'order_quantity_reason',
  AuthType: 'auth_type',
  BusinessCategory: 'business_category',
} as const

interface SelectTypeCodeProps {
  typeGroup: keyof typeof TypeGroup
  title?: string
  typeCode?: string | null | undefined
  setTypeCode: Dispatch<SetStateAction<string | null | undefined>>
  setTypeCodeInfo: Dispatch<SetStateAction<Groups | undefined>>
}

function SelectTypeCode({ typeGroup, title = '', typeCode, setTypeCode, setTypeCodeInfo }: SelectTypeCodeProps) {
  const { typeCode: typeCodes } = useAuthContext()

  const [trTypecode, setTrTypecode] = useState<Groups | undefined>(undefined)
  const [trValue, setTrValue] = useState<string | undefined | null>('')

  useEffect(() => {
    if (typeCodes.length > 0) {
      const findTypeCode = _.find(typeCodes, { typeGroup: TypeGroup[typeGroup] })
      setTrTypecode(findTypeCode)
      setTypeCodeInfo(_.find(typeCodes, { typeGroup: TypeGroup[typeGroup] }))

      // デフォルト値設定
      const trType = _.find(findTypeCode?.codes)!.typeCode

      // typeCodeに値が入っていれば初期値として設定
      if (typeCode) {
        setTrValue(typeCode)
        setTypeCode(typeCode)
      } else {
        setTrValue(trType)
        setTypeCode(trType)
      }
    }
  }, [typeCodes])

  return (
    <>
      {/* mapセレクト要素を展開時より前にvalueを設定するとmui側でエラーが出るため、
      trTypecodeにデータが格納されるまでレンダリングしないようにする */}
      {trTypecode ? (
        <FormControl>
          <InputLabel id="select-type-code">{title || trTypecode.typeGroupName}</InputLabel>
          <Select
            labelId="select-type-code"
            id="demo-simple-select"
            defaultValue={trValue}
            onChange={(e) => {
              setTypeCode(e.target.value)
            }}
          >
            {trTypecode.codes.map((value) => (
              <MenuItem key={value.sortOrder} value={value.typeCode}>
                {value.typeName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <FormControl>
          <InputLabel id="select-type-code" />
          <CircularProgress color="inherit" size={30} />
        </FormControl>
      )}
    </>
  )
}

export default SelectTypeCode
