import React from 'react'

import { HelpOutline } from '@mui/icons-material'
import { IconButton, Tooltip, styled, tooltipClasses } from '@mui/material'

import type { TooltipProps } from '@mui/material'

const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 'none',
  },
})

function ExpectedRankTooltip() {
  return (
    <NoMaxWidthTooltip
      sx={{ maxWidth: 'none' }}
      arrow
      title={
        <div style={{ fontSize: '.9rem', whiteSpace: 'normal' }}>
          予測日の前30日間を予測精度の検証期間とし、
          <br />
          その検証期間内の日毎の予測誤差率の中央値によってランクを設定しています。
          <br />
          <br />
          過去30日間の日毎の予測誤差率(%)の中央値
          <br />
          0-50%未満→予測精度★3
          <br />
          50-100%未満→予測精度★2
          <br />
          100%以上→予測精度★1
        </div>
      }
    >
      <IconButton>
        <HelpOutline />
      </IconButton>
    </NoMaxWidthTooltip>
  )
}

export default ExpectedRankTooltip
