import { useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type { ResponseResult } from 'api-client'
import type { LoginUserWithMfa, MfaUser } from 'types/types'

export const fetchMfaInfo = () => {
  const mfaUser = auth.currentUser as MfaUser
  const mfaInfo = mfaUser?.reloadUserInfo?.mfaInfo

  if (mfaInfo && mfaInfo[0]) {
    return {
      isMfaEnabled: true,
      mfaTel: mfaInfo[0].phoneInfo,
    }
  }

  return {
    isMfaEnabled: false,
    mfaTel: null,
  }
}

export const useLoginUserInfo = () => {
  const [loginUserInfo, setLoginUserInfo] = useState<LoginUserWithMfa | undefined>(undefined)
  const [result, setResult] = useState<ResponseResult>()

  const [isLoading, setIsLoading] = useState(false)

  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchLoginUserInfo = () => {
    setIsLoading(true)

    return auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.readUsersMeLoginUserInfoGet()
      })
      .then((response) => {
        if (response.data) {
          const loginUserWithMfa = {
            ...response.data,
            ...fetchMfaInfo(),
          }

          setLoginUserInfo(loginUserWithMfa)
          setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
        }
        setResult(response.result)
        return response
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    loginUserInfo,
    result,
    setLoginUserInfo,
    setResult,
    fetchLoginUserInfo,
    isLoading,
  }
}
