import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { protectedRoutes } from 'constants/routes'

import { LicenseInfo } from '@mui/x-data-grid-premium'
import { useAuthContext } from 'AuthProvider'
import { LicenseManager } from 'ag-grid-enterprise'
import { AuthAction, Login, PasswordRest } from 'pages'
import { AfterLogin } from 'templates'

function App() {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSEKEY as string)
  LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENSEKEY as string)

  const { user, loginUserInfoResult } = useAuthContext()

  return (
    <BrowserRouter>
      <Routes>
        <Route path={protectedRoutes.Login.path} element={<Login />} />
        <Route path={protectedRoutes.PasswordRest.path} element={<PasswordRest />} />
        <Route path={protectedRoutes.AuthAction.path} element={<AuthAction />} />
        <Route
          path={`${protectedRoutes.Top.path}*`}
          element={
            loginUserInfoResult?.status === 'success' ? (
              <AfterLogin />
            ) : (
              <Navigate
                to={`/${protectedRoutes.Login.path}${user?.tenantId ? `?tenantId=${user?.tenantId}` : ''}`}
                replace
              />
            )
          }
        />
      </Routes>
    </BrowserRouter>
  )
}

export default App
