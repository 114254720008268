import type { Dispatch, PropsWithChildren, SetStateAction } from 'react'
import React, { createContext, useContext, useEffect, useState } from 'react'

import { auth } from 'constants/fireabaseConfig'
import { protectedRoutes } from 'constants/routes'

import { type Groups, type ResponseResult } from 'api-client'
import { useLoginUserInfo } from 'hooks/useLoginUserInfo'
import { useTypecode } from 'hooks/useTypecode'

import type { User } from 'firebase/auth'
import type { ErrorResponse, LoginUserWithMfa } from 'types/types'

interface AuthContextProps {
  user: User | null
  typeCode: Groups[]

  loginUserInfo: LoginUserWithMfa | undefined
  setLoginUserInfo: Dispatch<SetStateAction<LoginUserWithMfa | undefined>>
  loginUserInfoResult: ResponseResult | undefined
  setLoginUserInfoResult: Dispatch<SetStateAction<ResponseResult | undefined>>

  isLoginLoading: boolean
  setIsLoginLoading: Dispatch<SetStateAction<boolean>>
}

const AuthContext = createContext<AuthContextProps>({
  user: null,
  typeCode: [],

  loginUserInfo: undefined,
  setLoginUserInfo: () => {},
  loginUserInfoResult: undefined,
  setLoginUserInfoResult: () => {},

  isLoginLoading: false,
  setIsLoginLoading: () => {},
})

export function useAuthContext() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }: PropsWithChildren) {
  const {
    loginUserInfo,
    setLoginUserInfo,
    fetchLoginUserInfo,
    result: loginUserInfoResult,
    setResult: setLoginUserInfoResult,
    isLoading: isLoginUserInfoLoading,
  } = useLoginUserInfo()
  const { typeCode, fetchTypeCode, isLoading: isTypeCodeLoading } = useTypecode()

  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(true)

  const [isLoginLoading, setIsLoginLoading] = useState(false)

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    user,
    typeCode,

    loginUserInfo,
    setLoginUserInfo,
    loginUserInfoResult,
    setLoginUserInfoResult,

    isLoginLoading,
    setIsLoginLoading,
  }

  useEffect(() => {
    // サイン状態を監視
    const unsubscribed = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        fetchLoginUserInfo()?.catch(({ response }: ErrorResponse) => {
          setLoginUserInfoResult({
            status: 'error',
            message:
              response && response.status === 401
                ? 'メールアドレスまたはパスワードが異なります。'
                : `エラーが発生しました。`,
            systemDate: null,
            pageNo: 0,
            systemInfo: null,
          })
        })
        fetchTypeCode()
        setUser(authUser)
        setIsLoading(false)
      } else {
        setIsLoading(false)
        // サインインしていない場合は、ログイン画面に遷移する
        if (!isLoading) {
          window.location.href = `/${protectedRoutes.Login.path}?tenantId=${user?.tenantId}`
        }
      }
    })
    return () => {
      unsubscribed()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return isLoading || isTypeCodeLoading || isLoginUserInfoLoading || isLoginLoading ? (
    <div />
  ) : (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  )
}
