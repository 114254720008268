import type { FormEvent } from 'react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { auth } from 'constants/fireabaseConfig'

import { Box, Typography } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { Configuration, DefaultApi, type GetShipExpectedShipExpectedGetRequest, type ResponseResult } from 'api-client'
import { ShipExpectedDataList } from 'components/commonparts'
import { SearchCondition } from 'components/parts/ShipExpectedCenter'
import { useShipExpected } from 'hooks/useShipExpected'
import { useShipExpectedFileDownload } from 'hooks/useShipExpectedFileDownload'
import _ from 'lodash'
import { useSearchParamStore } from 'store/searchParamStore'
import { getInitCompanyId } from 'utils/getInitParam'
import { getLocalSearchParam } from 'utils/getLocalSearchParam'

import type { AddFieldTreePass } from 'components/commonparts/ShipExpectedDataList/ShipExpectedDataList'

function ShipExpectedCenter() {
  const { loginUserInfo } = useAuthContext()

  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)
  const searchParamState = useSearchParamStore((state) => state.searchParamState)

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  // クエリパラメーターを取得
  const trQueryParam = {
    companyId: Number(query.get('company_id')),
    centerId: Number(query.get('center_id')),
    viewDateType: query.get('view_date_type'),
    viewUnitType: query.get('view_unit_type'),
    itemCode: query.get('item_code'),
  }

  // 自社の検索条件をローカルストレージから取得する
  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)

  const localCompanyId = localSearchParam?.shipExpectedCenter.companyId || undefined
  const localCenterId = localSearchParam?.shipExpectedCenter.centerId || undefined
  const localViewDateType = localSearchParam?.shipExpectedCenter.viewDateType || ''
  const localViewUnitType = localSearchParam?.shipExpectedCenter.viewUnitType || ''
  const localItemCode = localSearchParam?.shipExpectedCenter.itemCode || ''

  // クエリパラメータかローカルストレージに保存している検索条件を初期値に指定
  const initSearchParam: GetShipExpectedShipExpectedGetRequest = {
    // ユーザーの企業ID
    companyId: getInitCompanyId(trQueryParam.companyId, localCompanyId, loginUserInfo!.companyId),
    //  ストアのセンター情報
    centerId: trQueryParam.centerId ? trQueryParam.centerId : localCenterId,
    viewDateType: trQueryParam.viewDateType ? trQueryParam.viewDateType : localViewDateType,
    viewUnitType: trQueryParam.viewUnitType ? trQueryParam.viewUnitType : localViewUnitType,
    itemCode: trQueryParam.itemCode ? trQueryParam.itemCode : localItemCode,
  }

  const { result, shipExpected, fetchShipExpected, isLoading } = useShipExpected()
  const { fetchShipExpectedCsvDownload, isCsvLoading, fetchShipExpectedXlsxDownload, isXlsxLoading } =
    useShipExpectedFileDownload()

  const [searchParam, setSearchParam] = useState<GetShipExpectedShipExpectedGetRequest>(initSearchParam)
  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)

  const navigate = useNavigate()
  const onSearch = (requestParam: GetShipExpectedShipExpectedGetRequest, e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // ガード句
    if (!(e.nativeEvent instanceof SubmitEvent)) return
    const { submitter } = e.nativeEvent

    // idがpageの場合のみ検索実行
    if (submitter?.id === 'page') {
      // 検索API実行
      fetchShipExpected({
        ...requestParam,
      })
      setSearchParam(requestParam)

      // companyIdが自社のcompanyIdのみ検索条件をローカルストレージに保存する
      if (requestParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
        setSearchParamState<GetShipExpectedShipExpectedGetRequest>(
          loginUserInfo?.tenantId,
          loginUserInfo?.companyId,
          'ShipExpectedCenter',
          {
            ...requestParam,
          }
        )
      }

      // 検索実行時に検索条件をクエリパラメータとして付与する
      navigate(
        `?view_date_type=${requestParam.viewDateType}&view_unit_type=${requestParam.viewUnitType}&company_id=${requestParam.companyId}&center_id=${requestParam.centerId}&item_code=${requestParam.itemCode}`
      )
    }
  }

  const onCsvDownLoad = () => {
    setResultMessage(undefined)

    fetchShipExpectedCsvDownload('倉庫別出荷予測', searchParam)?.catch(() => {
      setResultMessage({
        status: 'error',
        message: 'CSVダウンロードでエラーが発生しました。',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })
    })
  }

  const onExcelDownLoad = () => {
    setResultMessage(undefined)

    fetchShipExpectedXlsxDownload('倉庫別出荷予測', searchParam)?.catch(() => {
      setResultMessage({
        status: 'error',
        message: 'EXCELダウンロードでエラーが発生しました。',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })
    })
  }

  const getChildRows = async (
    requestParam: GetShipExpectedShipExpectedGetRequest,
    rootTreePath: string
  ): Promise<void | AddFieldTreePass[] | undefined> =>
    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getShipExpectedCustomerShipExpectedCustomerGet({
          ...searchParam,
          itemCode: requestParam.itemCode,
        })
      })
      .then((response) => {
        // MUI DataGridのTreeData形式に対応するため、listに子のtreepassItemフィールドを追加する
        const addFieldTreePass = _.map(response.data.list, (value) => ({
          ...value,
          id: `${value.itemCode}/${value.customerCompanyId}`,
          // TreeData形式を有効にするデータパス
          treepassItem: [
            `${rootTreePath}`,
            // customerCompanyIdが0の場合は(ID:~)を表示させない
            `${value.customerCompanyName} ${value.customerCompanyId ? `(ID：${value.customerCompanyId})` : ''}`,
          ],
        }))

        return addFieldTreePass
      })
      .catch(() => {
        setResultMessage({
          status: 'error',
          message: 'エラーが発生しました。',
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })

  useEffect(() => {
    // クエリパラメータまたはローカルストレージに保存している検索条件がある場合は初回レンダリング時に検索を実行する
    if ((trQueryParam.companyId && trQueryParam.centerId) || localCenterId) {
      fetchShipExpected(searchParam)

      // companyIdが自社のcompanyIdのみ検索条件をローカルストレージに保存する
      if (searchParam.companyId === loginUserInfo!.companyId && loginUserInfo?.tenantId && loginUserInfo?.companyId) {
        setSearchParamState<GetShipExpectedShipExpectedGetRequest>(
          loginUserInfo?.tenantId,
          loginUserInfo?.companyId,
          'ShipExpectedCenter',
          {
            ...searchParam,
          }
        )
      }
    }
  }, [])

  // 検索メッセージ
  useEffect(() => {
    setResultMessage(result)
  }, [result])

  return (
    <Box>
      <Typography component="div" variant="largeBold">
        倉庫別出荷予測
      </Typography>
      <SearchCondition
        searchParam={initSearchParam}
        onSearch={onSearch}
        onCsvDownLoad={onCsvDownLoad}
        onExcelDownLoad={onExcelDownLoad}
        isCsvLoading={isCsvLoading}
        isXlsxLoading={isXlsxLoading}
        result={resultMessage}
      />
      <ShipExpectedDataList
        list={shipExpected}
        isLoading={isLoading}
        getChildRows={getChildRows}
        treepassKey="treepassItem"
      />
    </Box>
  )
}

export default ShipExpectedCenter
