import { useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type {
  CompanyList,
  CreateCompanyCompanyCreatePostRequest,
  ReadCompanyCompanySearchGetRequest,
  ResponseResult,
  ResponseUpdate,
  UpdateCompanyCompanyUpdatePostRequest,
} from 'api-client'
import type { ErrorResponse } from 'types/types'

export const useCompany = () => {
  const [companySearch, setConpanySearch] = useState<CompanyList>({
    datacount: 0,
    maxcount: 0,
    lastUpdateDatetime: null,
    list: [],
  })
  const [compnaySearchIsLoading, setCompanySearchIsLoading] = useState(false)
  const [companyCreateIsLoading, setCompanyCreateIsLoading] = useState(false)
  const [companyUpdateIsLoading, setCompanyUpdateIsLoading] = useState(false)
  const [companySearchResult, setCompanySearchResult] = useState<ResponseResult | undefined>(undefined)
  const [companyCreateResult, setCompanyCreateResult] = useState<ResponseUpdate | undefined>(undefined)
  const [companyUpdateResult, setCompanyUpdateResult] = useState<ResponseUpdate | undefined>(undefined)
  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchCompanySearch = (searchParam: ReadCompanyCompanySearchGetRequest) => {
    setCompanySearchIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.readCompanyCompanySearchGet(searchParam)
      })
      .then((response) => {
        setConpanySearch(response.data)
        setCompanySearchResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setCompanySearchResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setCompanySearchIsLoading(false)
      })
  }

  const fetchCompanyCreate = (searchParam: CreateCompanyCompanyCreatePostRequest) => {
    setCompanyCreateIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.createCompanyCompanyCreatePost(searchParam)
      })
      .then((response) => {
        setCompanyCreateResult(response)
      })
      .catch(({ response }: ErrorResponse) => {
        setCompanyCreateResult({
          errors: [],
          result: {
            status: 'error',
            message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
            systemDate: null,
            pageNo: 0,
            systemInfo: null,
          },
          updateCount: null,
        })
      })
      .finally(() => {
        setCompanyCreateIsLoading(false)
      })
  }

  const fetchCompanyUpdate = (searchParam: UpdateCompanyCompanyUpdatePostRequest) => {
    setCompanyUpdateIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.updateCompanyCompanyUpdatePost(searchParam)
      })
      .then((response) => {
        setCompanyUpdateResult(response)
      })
      .catch(({ response }: ErrorResponse) => {
        setCompanyUpdateResult({
          errors: [],
          result: {
            status: 'error',
            message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
            systemDate: null,
            pageNo: 0,
            systemInfo: null,
          },
          updateCount: null,
        })
      })
      .finally(() => {
        setCompanyUpdateIsLoading(false)
      })
  }

  return {
    companySearch,
    fetchCompanySearch,
    companySearchResult,
    compnaySearchIsLoading,

    fetchCompanyCreate,
    companyCreateResult,
    companyCreateIsLoading,

    fetchCompanyUpdate,
    companyUpdateResult,
    companyUpdateIsLoading,
  }
}
