import { useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'

import type { ResponseResult, SavePostUploadDataTypePostRequest } from 'api-client'

export const useUpload = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState<ResponseResult | undefined>(undefined)

  const fetchUpload = (searchParam: SavePostUploadDataTypePostRequest) => {
    setIsLoading(true)

    return auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.savePostUploadDataTypePost(searchParam)
      })
      .catch(() => {
        setResult({
          status: 'error',
          message: `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    result,
    fetchUpload,
    isLoading,
  }
}
