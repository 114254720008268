/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SystemInfo } from './SystemInfo';
import {
    SystemInfoFromJSON,
    SystemInfoFromJSONTyped,
    SystemInfoToJSON,
} from './SystemInfo';

/**
 * APIのステータスの定義
 * @export
 * @interface ResponseResult
 */
export interface ResponseResult {
    /**
     * 
     * @type {string}
     * @memberof ResponseResult
     */
    status: string | null;
    /**
     * 
     * @type {string}
     * @memberof ResponseResult
     */
    message: string | null;
    /**
     * ページ番号
     * @type {number}
     * @memberof ResponseResult
     */
    pageNo: number;
    /**
     * 
     * @type {Date}
     * @memberof ResponseResult
     */
    systemDate: Date | null;
    /**
     * 
     * @type {SystemInfo}
     * @memberof ResponseResult
     */
    systemInfo: SystemInfo | null;
}

/**
 * Check if a given object implements the ResponseResult interface.
 */
export function instanceOfResponseResult(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "pageNo" in value;
    isInstance = isInstance && "systemDate" in value;
    isInstance = isInstance && "systemInfo" in value;

    return isInstance;
}

export function ResponseResultFromJSON(json: any): ResponseResult {
    return ResponseResultFromJSONTyped(json, false);
}

export function ResponseResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'message': json['message'],
        'pageNo': json['page_no'],
        'systemDate': (json['system_date'] === null ? null : new Date(json['system_date'])),
        'systemInfo': SystemInfoFromJSON(json['system_info']),
    };
}

export function ResponseResultToJSON(value?: ResponseResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'message': value.message,
        'page_no': value.pageNo,
        'system_date': (value.systemDate === null ? null : value.systemDate.toISOString().substring(0,10)),
        'system_info': SystemInfoToJSON(value.systemInfo),
    };
}

