import React, { useEffect, useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Alert, Card, Typography } from '@mui/material'
import ActionFinishedPanel from 'components/parts/AuthAction/ActionFinishedPanel/ActionFinishedPanel'
import PasswordResetExecForm from 'components/parts/AuthAction/PasswordResetExecForm/PasswordResetExecForm'
import RevertMFAPanel from 'components/parts/AuthAction/RevertMFAPanel/RevertMFAPanel'
import { applyActionCode, confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth'
import { useURLQuery } from 'hooks/useURLQuery'
import { BeforeLogin } from 'templates'

function AuthAction() {
  const query = useURLQuery()
  const [mode, setMode] = useState<string | null>(null)
  const [actionCode, setActionCode] = useState<string | null>(null)
  const [tenantId, setTenantId] = useState<string | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>('')
  const [successMessage, setSuccessMessage] = useState<string | null>('')

  useEffect(() => {
    setMode(query.get('mode'))
    setActionCode(query.get('oobCode'))
    setTenantId(query.get('tenantId'))
  }, [])

  const resetPassword = async (password: string, passwordConfirmation: string) => {
    setErrorMessage('')
    if (!password) {
      setErrorMessage('パスワードを入力してください')
      return
    }

    if (password !== passwordConfirmation) {
      setErrorMessage('パスワードが一致しません')
      return
    }

    if (!actionCode) {
      setErrorMessage('不正なアクセスです')
      return
    }

    auth.tenantId = tenantId

    try {
      const email = await verifyPasswordResetCode(auth, actionCode)
      confirmPasswordReset(auth, actionCode, password)

      setSuccessMessage('パスワードを再設定しました。')
      setMode('finished')
    } catch (e) {
      setErrorMessage('エラーが発生しました。もう一度お試しください。')
    }
  }

  const revertSecondFactorAddition = async () => {
    setErrorMessage('')
    if (!actionCode) {
      setErrorMessage('不正なアクセスです')
      return
    }

    auth.tenantId = tenantId

    try {
      await applyActionCode(auth, actionCode)
      setSuccessMessage('二段階認証を解除しました')
      setMode('finished')
    } catch (e) {
      setErrorMessage('エラーが発生しました。もう一度お試しください。')
    }
  }

  const titleLabel = () => {
    switch (mode) {
      case 'resetPassword':
        return 'パスワード再設定'
      case 'revertSecondFactorAddition':
        return '二段階認証の解除'
      case 'finished':
        return '設定完了'
      default:
        return 'テスト'
    }
  }

  return (
    <BeforeLogin>
      <Typography display="flex" justifyContent="center" variant="largeBold" textAlign="center">
        {titleLabel()}
      </Typography>
      <Card variant="outlined" sx={{ padding: '1.5rem' }}>
        {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
        {mode === 'resetPassword' && <PasswordResetExecForm handleSubmit={resetPassword} />}
        {mode === 'revertSecondFactorAddition' && <RevertMFAPanel handleSubmit={revertSecondFactorAddition} />}
        {mode === 'finished' && <ActionFinishedPanel tenantId={tenantId} message={successMessage} />}
      </Card>
    </BeforeLogin>
  )
}

export default AuthAction
