import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const UploadMasterTab = {
  Common: 'common',
  Order: 'order',
  Ship: 'ship',
} as const

interface UploadMasterTabStore {
  uploadMasterTabState: (typeof UploadMasterTab)[keyof typeof UploadMasterTab]
  setUploadMasterTabsState: (trTab: (typeof UploadMasterTab)[keyof typeof UploadMasterTab]) => void
}

export const useUploadMasterTabStore = create<UploadMasterTabStore>()(
  persist(
    (set) => ({
      uploadMasterTabState: UploadMasterTab.Common,
      setUploadMasterTabsState: (trTab) => {
        set(() => ({ uploadMasterTabState: trTab }))
      },
    }),
    {
      name: 'upload-master-tab',
    }
  )
)
