/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstUserOutput
 */
export interface MstUserOutput {
    /**
     * 
     * @type {number}
     * @memberof MstUserOutput
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof MstUserOutput
     */
    companyName: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserOutput
     */
    companyHojinNo: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserOutput
     */
    companyAddress: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserOutput
     */
    companyGln: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserOutput
     */
    businessCategory: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserOutput
     */
    tenantId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserOutput
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof MstUserOutput
     */
    userMail: string;
    /**
     * 
     * @type {string}
     * @memberof MstUserOutput
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof MstUserOutput
     */
    authType: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserOutput
     */
    authTypeName: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstUserOutput
     */
    enabled: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MstUserOutput
     */
    updateDatetime: Date | null;
}

/**
 * Check if a given object implements the MstUserOutput interface.
 */
export function instanceOfMstUserOutput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "companyName" in value;
    isInstance = isInstance && "companyHojinNo" in value;
    isInstance = isInstance && "companyAddress" in value;
    isInstance = isInstance && "companyGln" in value;
    isInstance = isInstance && "businessCategory" in value;
    isInstance = isInstance && "tenantId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "userMail" in value;
    isInstance = isInstance && "userName" in value;
    isInstance = isInstance && "authType" in value;
    isInstance = isInstance && "authTypeName" in value;
    isInstance = isInstance && "enabled" in value;
    isInstance = isInstance && "updateDatetime" in value;

    return isInstance;
}

export function MstUserOutputFromJSON(json: any): MstUserOutput {
    return MstUserOutputFromJSONTyped(json, false);
}

export function MstUserOutputFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstUserOutput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'companyName': json['company_name'],
        'companyHojinNo': json['company_hojin_no'],
        'companyAddress': json['company_address'],
        'companyGln': json['company_gln'],
        'businessCategory': json['business_category'],
        'tenantId': json['tenant_id'],
        'userId': json['user_id'],
        'userMail': json['user_mail'],
        'userName': json['user_name'],
        'authType': json['auth_type'],
        'authTypeName': json['auth_type_name'],
        'enabled': json['enabled'],
        'updateDatetime': (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
    };
}

export function MstUserOutputToJSON(value?: MstUserOutput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'company_name': value.companyName,
        'company_hojin_no': value.companyHojinNo,
        'company_address': value.companyAddress,
        'company_gln': value.companyGln,
        'business_category': value.businessCategory,
        'tenant_id': value.tenantId,
        'user_id': value.userId,
        'user_mail': value.userMail,
        'user_name': value.userName,
        'auth_type': value.authType,
        'auth_type_name': value.authTypeName,
        'enabled': value.enabled,
        'update_datetime': (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
    };
}

