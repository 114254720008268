import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Grid, Typography } from '@mui/material'
import { ActionButton } from 'components/commonparts'

interface Props {
  handleSubmit: () => void
}

function RevertMFAPanel({ handleSubmit }: Props) {
  const navigate = useNavigate()
  return (
    <Box>
      <Typography sx={{ marginTop: '1rem' }}>
        <span style={{ fontWeight: 'bold', color: 'red' }}>二段階認証の設定は既に完了しています。</span>
        <br />
        この設定を取り消したい場合のみ、下記のボタンを押してください。
      </Typography>
      <Grid container justifyContent="center" alignItems="center" sx={{ marginTop: '1rem' }}>
        <ActionButton caption="二段階認証解除" onClick={handleSubmit} />
      </Grid>
    </Box>
  )
}

export default RevertMFAPanel
