import type { Dispatch, SetStateAction, SyntheticEvent } from 'react'
import React, { useEffect, useState } from 'react'

import { Autocomplete, CircularProgress, TextField } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { useSupplier } from 'hooks/useSupplier'
import _ from 'lodash'

import type { MstSupplier } from 'api-client'
import type { AutocompleteSupplier } from 'hooks/useSupplier'

interface SelectSupplierProps {
  trCompanyId: number | undefined
  orgSupplierId: string | null | undefined
  setOrgSupplierId: Dispatch<SetStateAction<string | null | undefined>>
  setSupplierInfo: Dispatch<SetStateAction<MstSupplier | undefined>>
  isRequred: boolean
  setIsValid: Dispatch<SetStateAction<boolean>>
}

function SelectSupplier({
  trCompanyId,
  orgSupplierId,
  setOrgSupplierId,
  setSupplierInfo,
  isRequred,
  setIsValid,
}: SelectSupplierProps) {
  const { loginUserInfo } = useAuthContext()

  const { suppliers, autocompletSuppliers, fetchSupplier, isLoading } = useSupplier()

  const [trValue, setTrValue] = useState<AutocompleteSupplier | null | undefined>(undefined)
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    if (trCompanyId === undefined || trCompanyId === null) {
      fetchSupplier({ companyId: loginUserInfo!.companyId })
    } else {
      fetchSupplier({ companyId: trCompanyId })
    }
  }, [trCompanyId])

  useEffect(() => {
    // orgSupplierIdが空の場合はクリアにする
    if (orgSupplierId === undefined && trValue) {
      setTrValue(null)
    }
  }, [orgSupplierId])

  // 必須チェック処理
  useEffect(() => {
    // 必須チェックを上位コンポーネントに通知
    if (trValue) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [trValue])

  useEffect(() => {
    // customerCompanyIdが指定されている場合は、centerIdから検索し入力値を表示する
    // ケース ・入力選択時 ・クエリパラメータ取得時
    if (autocompletSuppliers.length > 0 && orgSupplierId) {
      setTrValue(_.find(autocompletSuppliers, { orgSupplierId }))
    }

    if (isFirstRender) {
      setIsFirstRender(false)
    }
  }, [autocompletSuppliers])

  useEffect(() => {
    if (suppliers) {
      setSupplierInfo(_.find(suppliers, { orgSupplierId }))
    }
  }, [suppliers])

  return (
    <Autocomplete
      disablePortal
      id="supplier"
      // valueを明示的に指定(controlled component)しなければならないが、未指定の場合はundefinedが返るので
      // undefinedの場合はnullを指定する
      value={trValue ?? null}
      options={autocompletSuppliers}
      loading={isLoading}
      // fetch中はdisabledにする
      disabled={isLoading}
      onChange={(e: SyntheticEvent<Element, Event>, selectedValue: AutocompleteSupplier | null) => {
        // 仕入れ先情報を上位コンポーネントに通知
        setOrgSupplierId(selectedValue?.orgSupplierId)
        setSupplierInfo(_.find(suppliers, { orgSupplierId: selectedValue?.orgSupplierId }))

        setTrValue(selectedValue)
      }}
      isOptionEqualToValue={(option, value) => option.orgSupplierId === value.orgSupplierId}
      renderInput={(params) => (
        <TextField
          label="仕入先"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <div>{isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</div>
            ),
          }}
          // 必須入力チェック関するprops
          error={isRequred && !trValue}
          helperText={isRequred && !trValue && '必須'}
          required={isRequred}
        />
      )}
    />
  )
}

export default SelectSupplier
