/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Stock
 */
export interface Stock {
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    companyId: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    centerId: number | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    orgCenterId: string | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    centerName: string | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    orgItemCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    itemName: string | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    itemCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    orgSupplierId: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Stock
     */
    recDate: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Stock
     */
    orderDate: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    weekNo: number | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    dateType: string | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    orderQuantityRec: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    arrivalQuantity: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    salesorderQuantity: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    shipQuantity: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    shipQuantityRec: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    stockQuantity: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    stockQuantityRec: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    stockDays: number | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    orderQuantityReason: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Stock
     */
    enableUpdateShip: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Stock
     */
    enableUpdateArrival: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    shipQuantityExpected: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Stock
     */
    shipExpectedCalcDatetime: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    expectedRank: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    arrivalQuantityPallet: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    shipQuantityPallet: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    stockQuantityPallet: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    generalCustomerShipQuantity: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    customerOrderQuantityRecommend: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    stockDaysRank: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    safetyStockRank: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    shipQuantityScheduled: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    orderQuantityRecommend: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Stock
     */
    orderRecommendCalcDatetime: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    stockQuantityRecMorning: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Stock
     */
    updateDatetime: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Stock
     */
    enableShip: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Stock
     */
    enableArrival: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Stock
     */
    supplierEnableOrder: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Stock
     */
    enableOrder: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Stock
     */
    isUpdatedArrival: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Stock
     */
    isUpdatedShip: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    supplierCompanyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    supplierName: string | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    orderUnitType: string | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    orderUnitTypeName: string | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    caseLot: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    minOrderQuantity: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    orderLeadtime: number | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    orderLimitTime: string | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    makerName: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Stock
     */
    salesStartdate: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Stock
     */
    salesEnddate: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    spec: string | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    longside: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    shortside: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    height: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    weight: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    maxPalletQuantity: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    palletQuantityFace: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    palletQuantityTier: number | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    jan: string | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    janCase: string | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    gtin13: string | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    gtin14: string | null;
    /**
     * 
     * @type {string}
     * @memberof Stock
     */
    itf: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Stock
     */
    arrivalFixFlg: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    stockDaysSetting: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    safetyStockSetting: number | null;
    /**
     * 
     * @type {number}
     * @memberof Stock
     */
    minStockQuantity: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Stock
     */
    startDate: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Stock
     */
    stopShip: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof Stock
     */
    stopArrival: boolean | null;
}

/**
 * Check if a given object implements the Stock interface.
 */
export function instanceOfStock(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "centerId" in value;
    isInstance = isInstance && "orgCenterId" in value;
    isInstance = isInstance && "centerName" in value;
    isInstance = isInstance && "orgItemCode" in value;
    isInstance = isInstance && "itemName" in value;
    isInstance = isInstance && "itemCode" in value;
    isInstance = isInstance && "orgSupplierId" in value;
    isInstance = isInstance && "recDate" in value;
    isInstance = isInstance && "orderDate" in value;
    isInstance = isInstance && "weekNo" in value;
    isInstance = isInstance && "dateType" in value;
    isInstance = isInstance && "orderQuantityRec" in value;
    isInstance = isInstance && "arrivalQuantity" in value;
    isInstance = isInstance && "salesorderQuantity" in value;
    isInstance = isInstance && "shipQuantity" in value;
    isInstance = isInstance && "shipQuantityRec" in value;
    isInstance = isInstance && "stockQuantity" in value;
    isInstance = isInstance && "stockQuantityRec" in value;
    isInstance = isInstance && "stockDays" in value;
    isInstance = isInstance && "orderQuantityReason" in value;
    isInstance = isInstance && "enableUpdateShip" in value;
    isInstance = isInstance && "enableUpdateArrival" in value;
    isInstance = isInstance && "shipQuantityExpected" in value;
    isInstance = isInstance && "shipExpectedCalcDatetime" in value;
    isInstance = isInstance && "expectedRank" in value;
    isInstance = isInstance && "arrivalQuantityPallet" in value;
    isInstance = isInstance && "shipQuantityPallet" in value;
    isInstance = isInstance && "stockQuantityPallet" in value;
    isInstance = isInstance && "generalCustomerShipQuantity" in value;
    isInstance = isInstance && "customerOrderQuantityRecommend" in value;
    isInstance = isInstance && "stockDaysRank" in value;
    isInstance = isInstance && "safetyStockRank" in value;
    isInstance = isInstance && "shipQuantityScheduled" in value;
    isInstance = isInstance && "orderQuantityRecommend" in value;
    isInstance = isInstance && "orderRecommendCalcDatetime" in value;
    isInstance = isInstance && "stockQuantityRecMorning" in value;
    isInstance = isInstance && "updateDatetime" in value;
    isInstance = isInstance && "enableShip" in value;
    isInstance = isInstance && "enableArrival" in value;
    isInstance = isInstance && "supplierEnableOrder" in value;
    isInstance = isInstance && "enableOrder" in value;
    isInstance = isInstance && "isUpdatedArrival" in value;
    isInstance = isInstance && "isUpdatedShip" in value;
    isInstance = isInstance && "supplierCompanyId" in value;
    isInstance = isInstance && "supplierName" in value;
    isInstance = isInstance && "orderUnitType" in value;
    isInstance = isInstance && "orderUnitTypeName" in value;
    isInstance = isInstance && "caseLot" in value;
    isInstance = isInstance && "minOrderQuantity" in value;
    isInstance = isInstance && "orderLeadtime" in value;
    isInstance = isInstance && "orderLimitTime" in value;
    isInstance = isInstance && "makerName" in value;
    isInstance = isInstance && "salesStartdate" in value;
    isInstance = isInstance && "salesEnddate" in value;
    isInstance = isInstance && "spec" in value;
    isInstance = isInstance && "longside" in value;
    isInstance = isInstance && "shortside" in value;
    isInstance = isInstance && "height" in value;
    isInstance = isInstance && "weight" in value;
    isInstance = isInstance && "maxPalletQuantity" in value;
    isInstance = isInstance && "palletQuantityFace" in value;
    isInstance = isInstance && "palletQuantityTier" in value;
    isInstance = isInstance && "jan" in value;
    isInstance = isInstance && "janCase" in value;
    isInstance = isInstance && "gtin13" in value;
    isInstance = isInstance && "gtin14" in value;
    isInstance = isInstance && "itf" in value;
    isInstance = isInstance && "arrivalFixFlg" in value;
    isInstance = isInstance && "stockDaysSetting" in value;
    isInstance = isInstance && "safetyStockSetting" in value;
    isInstance = isInstance && "minStockQuantity" in value;
    isInstance = isInstance && "startDate" in value;
    isInstance = isInstance && "stopShip" in value;
    isInstance = isInstance && "stopArrival" in value;

    return isInstance;
}

export function StockFromJSON(json: any): Stock {
    return StockFromJSONTyped(json, false);
}

export function StockFromJSONTyped(json: any, ignoreDiscriminator: boolean): Stock {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'centerId': json['center_id'],
        'orgCenterId': json['org_center_id'],
        'centerName': json['center_name'],
        'orgItemCode': json['org_item_code'],
        'itemName': json['item_name'],
        'itemCode': json['item_code'],
        'orgSupplierId': json['org_supplier_id'],
        'recDate': (json['rec_date'] === null ? null : new Date(json['rec_date'])),
        'orderDate': (json['order_date'] === null ? null : new Date(json['order_date'])),
        'weekNo': json['week_no'],
        'dateType': json['date_type'],
        'orderQuantityRec': json['order_quantity_rec'],
        'arrivalQuantity': json['arrival_quantity'],
        'salesorderQuantity': json['salesorder_quantity'],
        'shipQuantity': json['ship_quantity'],
        'shipQuantityRec': json['ship_quantity_rec'],
        'stockQuantity': json['stock_quantity'],
        'stockQuantityRec': json['stock_quantity_rec'],
        'stockDays': json['stock_days'],
        'orderQuantityReason': json['order_quantity_reason'],
        'enableUpdateShip': json['enable_update_ship'],
        'enableUpdateArrival': json['enable_update_arrival'],
        'shipQuantityExpected': json['ship_quantity_expected'],
        'shipExpectedCalcDatetime': (json['ship_expected_calc_datetime'] === null ? null : new Date(json['ship_expected_calc_datetime'])),
        'expectedRank': json['expected_rank'],
        'arrivalQuantityPallet': json['arrival_quantity_pallet'],
        'shipQuantityPallet': json['ship_quantity_pallet'],
        'stockQuantityPallet': json['stock_quantity_pallet'],
        'generalCustomerShipQuantity': json['general_customer_ship_quantity'],
        'customerOrderQuantityRecommend': json['customer_order_quantity_recommend'],
        'stockDaysRank': json['stock_days_rank'],
        'safetyStockRank': json['safety_stock_rank'],
        'shipQuantityScheduled': json['ship_quantity_scheduled'],
        'orderQuantityRecommend': json['order_quantity_recommend'],
        'orderRecommendCalcDatetime': (json['order_recommend_calc_datetime'] === null ? null : new Date(json['order_recommend_calc_datetime'])),
        'stockQuantityRecMorning': json['stock_quantity_rec_morning'],
        'updateDatetime': (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'enableShip': json['enable_ship'],
        'enableArrival': json['enable_arrival'],
        'supplierEnableOrder': json['supplier_enable_order'],
        'enableOrder': json['enable_order'],
        'isUpdatedArrival': json['is_updated_arrival'],
        'isUpdatedShip': json['is_updated_ship'],
        'supplierCompanyId': json['supplier_company_id'],
        'supplierName': json['supplier_name'],
        'orderUnitType': json['order_unit_type'],
        'orderUnitTypeName': json['order_unit_type_name'],
        'caseLot': json['case_lot'],
        'minOrderQuantity': json['min_order_quantity'],
        'orderLeadtime': json['order_leadtime'],
        'orderLimitTime': json['order_limit_time'],
        'makerName': json['maker_name'],
        'salesStartdate': (json['sales_startdate'] === null ? null : new Date(json['sales_startdate'])),
        'salesEnddate': (json['sales_enddate'] === null ? null : new Date(json['sales_enddate'])),
        'spec': json['spec'],
        'longside': json['longside'],
        'shortside': json['shortside'],
        'height': json['height'],
        'weight': json['weight'],
        'maxPalletQuantity': json['max_pallet_quantity'],
        'palletQuantityFace': json['pallet_quantity_face'],
        'palletQuantityTier': json['pallet_quantity_tier'],
        'jan': json['jan'],
        'janCase': json['jan_case'],
        'gtin13': json['gtin_13'],
        'gtin14': json['gtin_14'],
        'itf': json['itf'],
        'arrivalFixFlg': json['arrival_fix_flg'],
        'stockDaysSetting': json['stock_days_setting'],
        'safetyStockSetting': json['safety_stock_setting'],
        'minStockQuantity': json['min_stock_quantity'],
        'startDate': (json['start_date'] === null ? null : new Date(json['start_date'])),
        'stopShip': json['stop_ship'],
        'stopArrival': json['stop_arrival'],
    };
}

export function StockToJSON(value?: Stock | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'center_id': value.centerId,
        'org_center_id': value.orgCenterId,
        'center_name': value.centerName,
        'org_item_code': value.orgItemCode,
        'item_name': value.itemName,
        'item_code': value.itemCode,
        'org_supplier_id': value.orgSupplierId,
        'rec_date': (value.recDate === null ? null : value.recDate.toISOString()),
        'order_date': (value.orderDate === null ? null : value.orderDate.toISOString()),
        'week_no': value.weekNo,
        'date_type': value.dateType,
        'order_quantity_rec': value.orderQuantityRec,
        'arrival_quantity': value.arrivalQuantity,
        'salesorder_quantity': value.salesorderQuantity,
        'ship_quantity': value.shipQuantity,
        'ship_quantity_rec': value.shipQuantityRec,
        'stock_quantity': value.stockQuantity,
        'stock_quantity_rec': value.stockQuantityRec,
        'stock_days': value.stockDays,
        'order_quantity_reason': value.orderQuantityReason,
        'enable_update_ship': value.enableUpdateShip,
        'enable_update_arrival': value.enableUpdateArrival,
        'ship_quantity_expected': value.shipQuantityExpected,
        'ship_expected_calc_datetime': (value.shipExpectedCalcDatetime === null ? null : value.shipExpectedCalcDatetime.toISOString()),
        'expected_rank': value.expectedRank,
        'arrival_quantity_pallet': value.arrivalQuantityPallet,
        'ship_quantity_pallet': value.shipQuantityPallet,
        'stock_quantity_pallet': value.stockQuantityPallet,
        'general_customer_ship_quantity': value.generalCustomerShipQuantity,
        'customer_order_quantity_recommend': value.customerOrderQuantityRecommend,
        'stock_days_rank': value.stockDaysRank,
        'safety_stock_rank': value.safetyStockRank,
        'ship_quantity_scheduled': value.shipQuantityScheduled,
        'order_quantity_recommend': value.orderQuantityRecommend,
        'order_recommend_calc_datetime': (value.orderRecommendCalcDatetime === null ? null : value.orderRecommendCalcDatetime.toISOString()),
        'stock_quantity_rec_morning': value.stockQuantityRecMorning,
        'update_datetime': (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'enable_ship': value.enableShip,
        'enable_arrival': value.enableArrival,
        'supplier_enable_order': value.supplierEnableOrder,
        'enable_order': value.enableOrder,
        'is_updated_arrival': value.isUpdatedArrival,
        'is_updated_ship': value.isUpdatedShip,
        'supplier_company_id': value.supplierCompanyId,
        'supplier_name': value.supplierName,
        'order_unit_type': value.orderUnitType,
        'order_unit_type_name': value.orderUnitTypeName,
        'case_lot': value.caseLot,
        'min_order_quantity': value.minOrderQuantity,
        'order_leadtime': value.orderLeadtime,
        'order_limit_time': value.orderLimitTime,
        'maker_name': value.makerName,
        'sales_startdate': (value.salesStartdate === null ? null : value.salesStartdate.toISOString()),
        'sales_enddate': (value.salesEnddate === null ? null : value.salesEnddate.toISOString()),
        'spec': value.spec,
        'longside': value.longside,
        'shortside': value.shortside,
        'height': value.height,
        'weight': value.weight,
        'max_pallet_quantity': value.maxPalletQuantity,
        'pallet_quantity_face': value.palletQuantityFace,
        'pallet_quantity_tier': value.palletQuantityTier,
        'jan': value.jan,
        'jan_case': value.janCase,
        'gtin_13': value.gtin13,
        'gtin_14': value.gtin14,
        'itf': value.itf,
        'arrival_fix_flg': value.arrivalFixFlg,
        'stock_days_setting': value.stockDaysSetting,
        'safety_stock_setting': value.safetyStockSetting,
        'min_stock_quantity': value.minStockQuantity,
        'start_date': (value.startDate === null ? null : value.startDate.toISOString()),
        'stop_ship': value.stopShip,
        'stop_arrival': value.stopArrival,
    };
}

