import React from 'react'
import { Link } from 'react-router-dom'

import { AuthType } from 'constants/constants'
import { protectedRoutes } from 'constants/routes'

import {
  ChevronRight,
  ChevronLeft,
  HomeRounded,
  TimelineRounded,
  PlaylistAddRounded,
  InventoryRounded,
  LocalShippingRounded,
  SettingsRounded,
  ContactSupportOutlined,
  ManageAccountsOutlined,
} from '@mui/icons-material'
import { Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { DrawerHeader } from 'style'

interface SidebarProps {
  menuOpen: boolean
  handleDrawerClose: () => void
}

function Sidebar({ menuOpen, handleDrawerClose }: SidebarProps) {
  const { loginUserInfo, user } = useAuthContext()

  const borderProps = {
    borderBottom: 1,
    borderColor: 'grey.600',
  }

  const smallTitleProps = {
    color: '#fff',
    fontSize: 'small',
    px: 2,
    ...borderProps,
  }

  const nestLinkMenuItemProp = {
    px: 3.5,
    ...borderProps,
  }

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={menuOpen}
      sx={{
        width: 210,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 210,
          boxSizing: 'border-box',
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: '#1b335f',
        },
      }}
    >
      <DrawerHeader sidebar>
        <IconButton onClick={handleDrawerClose} color="primary">
          {menuOpen ? <ChevronLeft sx={{ fontSize: '2.5rem' }} /> : <ChevronRight sx={{ fontSize: '2.5rem' }} />}
        </IconButton>
      </DrawerHeader>
      <List>
        <ListItemText primaryTypographyProps={{ ...smallTitleProps, paddingTop: 1 }}>
          {/* NOTE: 全角スペース対応 */}
          {/* eslint-disable-next-line no-irregular-whitespace */}
          ▼　メニュー
        </ListItemText>

        <ListItem disablePadding sx={{ ...borderProps }}>
          <ListItemButton sx={{ display: 'block' }}>
            <MenuItem component={Link} to={protectedRoutes.Top.path} sx={{ color: 'white', px: 0, py: 0 }}>
              <ListItemIcon>
                <HomeRounded />
              </ListItemIcon>
              <ListItemText>{protectedRoutes.Top.name}</ListItemText>
            </MenuItem>
          </ListItemButton>
        </ListItem>

        <ListItem disablePadding sx={{ ...borderProps }}>
          <ListItemButton sx={{ display: 'block' }}>
            <MenuItem component={Link} to={protectedRoutes.Dashboard.path} sx={{ color: 'white', px: 0, py: 0 }}>
              <ListItemIcon>
                <TimelineRounded />
              </ListItemIcon>
              <ListItemText>{protectedRoutes.Dashboard.name}</ListItemText>
            </MenuItem>
          </ListItemButton>
        </ListItem>

        {/* 権限区分が「0:閲覧のみ」以外は表示 */}
        {loginUserInfo?.authType !== AuthType.ReadOnly && (
          <ListItem disablePadding sx={{ ...borderProps }}>
            <ListItemButton sx={{ display: 'block', minWidth: 0 }}>
              <MenuItem component={Link} to={protectedRoutes.UploadRecord.path} sx={{ color: 'white', px: 0, py: 0 }}>
                <ListItemIcon>
                  <PlaylistAddRounded />
                </ListItemIcon>
                <ListItemText>{protectedRoutes.UploadRecord.name}</ListItemText>
              </MenuItem>
            </ListItemButton>
          </ListItem>
        )}

        <ListItem sx={{ ...borderProps }}>
          <ListItemIcon>
            <InventoryRounded />
          </ListItemIcon>
          <ListItemText sx={{ color: 'white' }}>発注案</ListItemText>
        </ListItem>
        <ListItem disablePadding sx={{ ...nestLinkMenuItemProp }}>
          <ListItemButton sx={{ display: 'block' }}>
            <MenuItem component={Link} to={protectedRoutes.StockCenter.path} sx={{ color: 'white', px: 0, py: 0 }}>
              <ListItemText>{protectedRoutes.StockCenter.name}</ListItemText>
            </MenuItem>
          </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding sx={{ ...nestLinkMenuItemProp }}>
          <ListItemButton sx={{ display: 'block' }}>
            <MenuItem component={Link} to={protectedRoutes.StockCenterOld.path} sx={{ color: 'white', px: 0, py: 0 }}>
              <ListItemText>{protectedRoutes.StockCenterOld.name}</ListItemText>
            </MenuItem>
          </ListItemButton>
        </ListItem> */}
        <ListItem disablePadding sx={{ ...nestLinkMenuItemProp }}>
          <ListItemButton sx={{ display: 'block' }}>
            <MenuItem component={Link} to={protectedRoutes.StockAdjust.path} sx={{ color: 'white', px: 0, py: 0 }}>
              <ListItemText>{protectedRoutes.StockAdjust.name}</ListItemText>
            </MenuItem>
          </ListItemButton>
        </ListItem>
        {/* <ListItem disablePadding sx={{ ...nestLinkMenuItemProp }}>
          <ListItemButton sx={{ display: 'block' }}>
            <MenuItem component={Link} to={protectedRoutes.StockAdjustOld.path} sx={{ color: 'white', px: 0, py: 0 }}>
              <ListItemText>{protectedRoutes.StockAdjustOld.name}</ListItemText>
            </MenuItem>
          </ListItemButton>
        </ListItem> */}

        <ListItem sx={{ ...borderProps }}>
          <ListItemIcon>
            <LocalShippingRounded />
          </ListItemIcon>
          <ListItemText sx={{ color: 'white' }}>出荷予測</ListItemText>
        </ListItem>
        <ListItem disablePadding sx={{ ...nestLinkMenuItemProp }}>
          <ListItemButton sx={{ display: 'block' }}>
            <MenuItem
              component={Link}
              to={protectedRoutes.ShipExpectedCenter.path}
              sx={{ color: 'white', px: 0, py: 0 }}
            >
              <ListItemText>{protectedRoutes.ShipExpectedCenter.name}</ListItemText>
            </MenuItem>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ ...nestLinkMenuItemProp }}>
          <ListItemButton sx={{ display: 'block' }}>
            <MenuItem component={Link} to={protectedRoutes.ShipExpectedItem.path} sx={{ color: 'white', px: 0, py: 0 }}>
              <ListItemText>{protectedRoutes.ShipExpectedItem.name}</ListItemText>
            </MenuItem>
          </ListItemButton>
        </ListItem>

        {/* 権限区分が「0:閲覧のみ」以外は表示 */}
        {loginUserInfo?.authType !== AuthType.ReadOnly && (
          <ListItem disablePadding sx={{ ...borderProps }}>
            <ListItemButton sx={{ display: 'block' }}>
              <MenuItem component={Link} to={protectedRoutes.UploadMaster.path} sx={{ color: 'white', px: 0, py: 0 }}>
                <ListItemIcon>
                  <SettingsRounded />
                </ListItemIcon>
                <ListItemText>{protectedRoutes.UploadMaster.name}</ListItemText>
              </MenuItem>
            </ListItemButton>
          </ListItem>
        )}

        <ListItem disablePadding sx={{ ...borderProps }}>
          <ListItemButton sx={{ display: 'block' }}>
            <MenuItem component={Link} to={protectedRoutes.Faq.path} sx={{ color: 'white', px: 0, py: 0 }}>
              <ListItemIcon>
                <ContactSupportOutlined />
              </ListItemIcon>
              <ListItemText>{protectedRoutes.Faq.name}</ListItemText>
            </MenuItem>
          </ListItemButton>
        </ListItem>

        {loginUserInfo?.authType === AuthType.Admin && (
          <>
            <ListItemText primaryTypographyProps={{ ...smallTitleProps, paddingTop: 1 }}>
              {/* NOTE: 全角スペース対応 */}
              {/* eslint-disable-next-line no-irregular-whitespace */}
              ▼　管理者メニュー
            </ListItemText>
            <ListItem disablePadding sx={{ ...borderProps }}>
              <ListItemButton sx={{ display: 'block' }}>
                <MenuItem component={Link} to={protectedRoutes.UserList.path} sx={{ color: 'white', px: 0, py: 0 }}>
                  <ListItemIcon>
                    <ManageAccountsOutlined />
                  </ListItemIcon>
                  <ListItemText>{protectedRoutes.UserList.name}</ListItemText>
                </MenuItem>
              </ListItemButton>
            </ListItem>
          </>
        )}

        {user?.tenantId === null && (
          <>
            <ListItemText primaryTypographyProps={{ ...smallTitleProps, paddingTop: 1 }}>
              {/* NOTE: 全角スペース対応 */}
              {/* eslint-disable-next-line no-irregular-whitespace */}
              ▼　システム管理者メニュー
            </ListItemText>
            <ListItem disablePadding sx={{ ...borderProps }}>
              <ListItemButton sx={{ display: 'block' }}>
                <MenuItem component={Link} to={protectedRoutes.CompanyList.path} sx={{ color: 'white', px: 0, py: 0 }}>
                  <ListItemIcon>
                    <ManageAccountsOutlined />
                  </ListItemIcon>
                  <ListItemText>{protectedRoutes.CompanyList.name}</ListItemText>
                </MenuItem>
              </ListItemButton>
            </ListItem>
          </>
        )}
      </List>
    </Drawer>
  )
}

export default Sidebar
