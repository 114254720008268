/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Codes
 */
export interface Codes {
    /**
     * 
     * @type {string}
     * @memberof Codes
     */
    typeCode: string;
    /**
     * 
     * @type {string}
     * @memberof Codes
     */
    typeName: string;
    /**
     * 
     * @type {number}
     * @memberof Codes
     */
    sortOrder: number;
}

/**
 * Check if a given object implements the Codes interface.
 */
export function instanceOfCodes(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "typeCode" in value;
    isInstance = isInstance && "typeName" in value;
    isInstance = isInstance && "sortOrder" in value;

    return isInstance;
}

export function CodesFromJSON(json: any): Codes {
    return CodesFromJSONTyped(json, false);
}

export function CodesFromJSONTyped(json: any, ignoreDiscriminator: boolean): Codes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeCode': json['type_code'],
        'typeName': json['type_name'],
        'sortOrder': json['sort_order'],
    };
}

export function CodesToJSON(value?: Codes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type_code': value.typeCode,
        'type_name': value.typeName,
        'sort_order': value.sortOrder,
    };
}

