import React from 'react'

import { Rating } from '@mui/material'

import type { CustomCellRendererProps } from 'ag-grid-react'

function RatingRenderer({ value, node }: CustomCellRendererProps) {
  return <div className="rating-cell">{node.group && <Rating value={value || 0} max={3} readOnly />}</div>
}

export default RatingRenderer
