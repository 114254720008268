/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MstUserInput
 */
export interface MstUserInput {
    /**
     * 
     * @type {number}
     * @memberof MstUserInput
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof MstUserInput
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserInput
     */
    companyHojinNo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserInput
     */
    companyAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserInput
     */
    companyGln?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserInput
     */
    businessCategory?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserInput
     */
    tenantId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserInput
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof MstUserInput
     */
    userMail: string;
    /**
     * 
     * @type {string}
     * @memberof MstUserInput
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof MstUserInput
     */
    authType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstUserInput
     */
    authTypeName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstUserInput
     */
    enabled: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MstUserInput
     */
    updateDatetime?: Date | null;
}

/**
 * Check if a given object implements the MstUserInput interface.
 */
export function instanceOfMstUserInput(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "userMail" in value;
    isInstance = isInstance && "userName" in value;
    isInstance = isInstance && "enabled" in value;

    return isInstance;
}

export function MstUserInputFromJSON(json: any): MstUserInput {
    return MstUserInputFromJSONTyped(json, false);
}

export function MstUserInputFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstUserInput {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'companyName': !exists(json, 'company_name') ? undefined : json['company_name'],
        'companyHojinNo': !exists(json, 'company_hojin_no') ? undefined : json['company_hojin_no'],
        'companyAddress': !exists(json, 'company_address') ? undefined : json['company_address'],
        'companyGln': !exists(json, 'company_gln') ? undefined : json['company_gln'],
        'businessCategory': !exists(json, 'business_category') ? undefined : json['business_category'],
        'tenantId': !exists(json, 'tenant_id') ? undefined : json['tenant_id'],
        'userId': json['user_id'],
        'userMail': json['user_mail'],
        'userName': json['user_name'],
        'authType': !exists(json, 'auth_type') ? undefined : json['auth_type'],
        'authTypeName': !exists(json, 'auth_type_name') ? undefined : json['auth_type_name'],
        'enabled': json['enabled'],
        'updateDatetime': !exists(json, 'update_datetime') ? undefined : (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
    };
}

export function MstUserInputToJSON(value?: MstUserInput | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'company_name': value.companyName,
        'company_hojin_no': value.companyHojinNo,
        'company_address': value.companyAddress,
        'company_gln': value.companyGln,
        'business_category': value.businessCategory,
        'tenant_id': value.tenantId,
        'user_id': value.userId,
        'user_mail': value.userMail,
        'user_name': value.userName,
        'auth_type': value.authType,
        'auth_type_name': value.authTypeName,
        'enabled': value.enabled,
        'update_datetime': value.updateDatetime === undefined ? undefined : (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
    };
}

