import React, { useEffect } from 'react'

import { Replay } from '@mui/icons-material'
import { Box, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { useFileDownload } from 'hooks/useFileDownload'
import { useInfoMessage } from 'hooks/useInfoMessage'
import { useUpload } from 'hooks/useUpload'
import _ from 'lodash'
import { convertFromDateTime } from 'utils/convertFromLocalDate'

import DownloadCsvButton from '../Buttons/DownloadCsvButton'
import DownloadExcelButton from '../Buttons/DownloadExcelButton'
import UploadButton from '../Buttons/UploadButton'

const DataType = {
  MstCalendar: 'mst_calendar',
  MstCenter: 'mst_center',
  MstCenterStockItem: 'mst_center_stock_item',
  MstCustomer: 'mst_customer',
  MstCustomerCenter: 'mst_customer_center',
  MstCustomerCenterRef: 'mst_customer_center_ref',
  MstCustomerRef: 'mst_customer_ref',
  MstItem: 'mst_item',
  MstSupplier: 'mst_supplier',
  MstSupplierRef: 'mst_supplier_ref',
  MstTransportRelation: 'mst_transport_relation',
  TOrderArrival: 't_order_arrival',
  TStock: 't_stock',
  TShippingOrder: 't_shippingorder',
} as const

interface FileUploadProps {
  dataType: keyof typeof DataType
  title: string
  isDownload: boolean
}

function FileUpload({ dataType, title, isDownload }: FileUploadProps) {
  const { loginUserInfo } = useAuthContext()

  const { fetchUpload, isLoading: uploadIsLoading } = useUpload()
  const { fetchCsvDownload, csvIsLoading, fetchXlsxDownload, xlsxIsLoading } = useFileDownload()
  const { infomessage, fetchInfomessage, isLoading: infomessageIsLoading } = useInfoMessage()

  // info_type="last_update"に該当する、一番新しい日付のinfo_timeを表示
  const lastUpdate = () => {
    const lastUpdateArray = _.filter(infomessage.list, (value) => value.infoType === 'last_update')
    return convertFromDateTime(_.first(lastUpdateArray)?.infoTime)
  }

  const onInfomessage = () => {
    fetchInfomessage({ companyId: loginUserInfo?.companyId, target: DataType[dataType], limit: 10 })
  }

  const onUpload = async (file: File) => {
    // ファイルアップロード後、操作履歴を取得
    await fetchUpload({ dataType: DataType[dataType], postedFile: file })
    onInfomessage()
  }

  const onCsvDownLoad = () => {
    fetchCsvDownload(title, { dataType: DataType[dataType] })
  }

  const onExcelDownLoad = () => {
    fetchXlsxDownload(title, { dataType: DataType[dataType] })
  }

  useEffect(() => {
    onInfomessage()
  }, [])

  return (
    <Paper variant="outlined">
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Typography color="royalblue">
          最終更新日:
          {infomessage.list.length > 0 && lastUpdate()}
        </Typography>
        {/* 更新ボタン */}
        <IconButton onClick={onInfomessage}>
          <Replay />
        </IconButton>
      </Box>

      <Typography component="div" variant="mediumBold">
        {title}
      </Typography>
      <Grid container>
        <UploadButton onClick={onUpload} isLoading={uploadIsLoading} />
        {isDownload && (
          <>
            <DownloadCsvButton onClick={onCsvDownLoad} isLoading={csvIsLoading} />
            <DownloadExcelButton onClick={onExcelDownLoad} isLoading={xlsxIsLoading} />
          </>
        )}
      </Grid>

      <Typography component="div" variant="medium" sx={{ marginTop: 1 }}>
        操作履歴
      </Typography>
      {infomessageIsLoading ? (
        <CircularProgress size={40} color="primary" />
      ) : (
        <>
          {/* 3件表示する */}
          {_.take(infomessage.list, 3).map((value, key) => {
            let color
            if (value.infoType === 'error') {
              color = 'firebrick'
            } else if (value.infoType === 'warning') {
              color = 'darkorange'
            } else {
              color = 'black'
            }

            return (
              // eslint-disable-next-line react/no-array-index-key
              <Typography key={key} color={color} sx={{ marginTop: 1 }}>
                {value.message
                  ? `${convertFromDateTime(value.infoTime)} ${value.message}`
                  : `${convertFromDateTime(value.infoTime)} ${value.infoTypeName}`}
              </Typography>
            )
          })}
        </>
      )}
    </Paper>
  )
}

export default FileUpload
