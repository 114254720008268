import type { Dispatch, SetStateAction, SyntheticEvent } from 'react'
import React, { useEffect, useState } from 'react'

import { Autocomplete, CircularProgress, FormControl, TextField } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { useCenter } from 'hooks/useCenter'
import _ from 'lodash'

import type { MstCenter } from 'api-client'
import type { AutocompleteCenter } from 'hooks/useCenter'

interface SelectCenterProps {
  trCompanyId: number | null | undefined
  centerId: number | null | undefined
  setCenterId: Dispatch<SetStateAction<number | null | undefined>>
  setCenterInfo: Dispatch<SetStateAction<MstCenter | undefined>>
  isRequired: boolean
  setIsValid: Dispatch<SetStateAction<boolean>>
}

function SelectCenter({
  trCompanyId,
  centerId,
  setCenterId,
  setCenterInfo,
  isRequired,
  setIsValid,
}: SelectCenterProps) {
  const { loginUserInfo } = useAuthContext()

  const { centers, autocompletCenters, fetchCenters, isLoading } = useCenter()

  const [trCenter, setTrCenter] = useState<AutocompleteCenter[]>([])
  const [trValue, setTrValue] = useState<AutocompleteCenter | undefined | null>(undefined)
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    // 初回レンダリング以外はtrCompanyIdが変わることにクリアにする
    if (!isFirstRender) {
      setTrValue(undefined)
      setCenterId(undefined)
    }

    if (trCompanyId === undefined || trCompanyId === null || trCompanyId === loginUserInfo?.companyId) {
      fetchCenters({ companyId: loginUserInfo!.companyId })
    } else {
      // hooksを呼び出して直後にセッターに入れても、すぐに更新されないため別のuseStateで監視する
      fetchCenters({ companyId: trCompanyId })
    }
  }, [trCompanyId])

  useEffect(() => {
    // センターAPIが呼び出されレスポンスが返ってきた場合のみ実行
    if (centers?.length) {
      setTrCenter(autocompletCenters)
      setTrValue(_.find(autocompletCenters, { centerId }))
    }

    if (isFirstRender) {
      setIsFirstRender(false)
    }
  }, [centers])

  // クリアボタン処理
  useEffect(() => {
    // centerIdがundefinedまたは入力値がある場合は(クリアボタン押下時)の場合は入力値をクリアにする
    if (centerId === undefined && trValue) {
      setTrValue(undefined)
    }
  }, [centerId])

  // 必須チェック処理
  useEffect(() => {
    // 必須チェックを上位コンポーネントに通知
    if (trValue) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [trValue])

  return (
    <FormControl>
      <Autocomplete
        disablePortal
        id="center"
        // valueを明示的に指定(controlled component)しなければならないが、未指定の場合はundefinedが返るので
        // undefinedの場合はnullを指定する
        value={trValue ?? null}
        options={trCenter}
        loading={isLoading}
        // fetch中はdisabledにする
        disabled={isLoading}
        onChange={(e: SyntheticEvent<Element, Event>, selectedValue: AutocompleteCenter | null) => {
          // センター情報を上位コンポーネントに通知
          setCenterId(selectedValue?.centerId as number)
          setCenterInfo(_.find(centers, { centerId: selectedValue?.centerId }))

          setTrValue(selectedValue)
        }}
        isOptionEqualToValue={(option, value) => option.centerId === value.centerId}
        renderInput={(params) => (
          <TextField
            label="センター"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <div>{isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</div>
              ),
            }}
            // 必須入力チェック関するprops
            error={isRequired && !trValue}
            helperText={isRequired && !trValue && '必須'}
            required={isRequired}
          />
        )}
      />
    </FormControl>
  )
}

export default SelectCenter
