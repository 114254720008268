import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { protectedRoutes } from 'constants/routes'

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { BackButton, MessageInfo } from 'components/commonparts'
import { TypeGroup } from 'components/commonparts/SelectTypeCode/SelectTypeCode'
import { useCompany } from 'hooks/useCompany'
import _ from 'lodash'

import type { Groups } from 'api-client'

function CompanyDetail() {
  const { typeCode } = useAuthContext()

  const navigate = useNavigate()

  const {
    companySearch,
    fetchCompanySearch,
    compnaySearchIsLoading,

    fetchCompanyCreate,
    companyCreateResult,

    fetchCompanyUpdate,
    companyUpdateIsLoading,
    companyUpdateResult,
  } = useCompany()

  const [companyId, setCompanyId] = useState<number | null | undefined>(undefined)
  const [companyName, setCompanyName] = useState<string | null | undefined>('')
  const [companyHojinNo, setCompanyHojinNo] = useState<string | null | undefined>('')
  const [companyAddress, setCompanyAddress] = useState<string | null | undefined>('')
  const [companyGln, setCompanyGln] = useState<string | null | undefined>('')
  const [businessCategory, setBusinessCategory] = useState<string | null | undefined>('')
  const [trTypecode, setTrTypecode] = useState<Groups | undefined>(undefined)
  const [tenantId, setTenantId] = useState<string | undefined | null>('')
  const [deleteFlg, setdeleteFlg] = useState(false)

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  // クエリパラメーターを取得
  const trQueryParam = {
    companyId: Number(query.get('company_id')),
  }

  const onCompanySearch = () => {
    fetchCompanySearch({ companyId: trQueryParam.companyId })
  }

  const onCompanyCreate = () => {
    fetchCompanyCreate({
      companyInput: {
        companyId: trQueryParam.companyId,
        companyName,
        companyHojinNo,
        companyAddress,
        companyGln,
        businessCategory,
        tenantId,
        deleteFlg,
      },
    })
  }

  const onCompanyUpdate = () => {
    fetchCompanyUpdate({
      companyInput: {
        companyId: trQueryParam.companyId,
        companyName,
        companyHojinNo,
        companyAddress,
        companyGln,
        businessCategory,
        tenantId,
        deleteFlg,
      },
    })
  }

  useEffect(() => {
    // クエリパラメータに企業IDが含まれている場合は/company/search APIを呼ぶ
    if (trQueryParam.companyId) {
      onCompanySearch()
    } else if (typeCode.length > 0) {
      const targetTypeCode = _.find(typeCode, { typeGroup: TypeGroup.BusinessCategory })

      // ユーザー作成の場合は権限区分のデフォルト値を設定する
      setBusinessCategory(_.find(targetTypeCode?.codes)!.typeCode)
    }
  }, [trQueryParam.companyId])

  // 初期入力設定
  useEffect(() => {
    if (companySearch.list.length > 0) {
      const company = _.find(companySearch.list)

      setCompanyId(company?.companyId)
      setCompanyName(company?.companyName)
      setCompanyHojinNo(company?.companyHojinNo)
      setCompanyAddress(company?.companyAddress)
      setCompanyGln(company?.companyGln)
      setBusinessCategory(company?.businessCategory)
      setTenantId(company?.tenantId)
      setdeleteFlg(!!company?.deleteFlg)
    }
  }, [companySearch])

  useEffect(() => {
    // 権限区分の設定
    if (typeCode.length > 0) {
      const targetTypeCode = _.find(typeCode, { typeGroup: TypeGroup.BusinessCategory })
      setTrTypecode(targetTypeCode)
    }
  }, [typeCode])

  // 更新後に画面遷移し/updateを呼び出す
  useEffect(() => {
    const trCreateRowData = _.find(companyCreateResult?.errors)?.rowdata
    const trUpdateRowData = _.find(companyUpdateResult?.errors)?.rowdata

    if (trCreateRowData) {
      navigate(
        // NOTE: ResponseUpdateのrowdataに型がない
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        `?company_id=${trCreateRowData.company_id}`
      )
    } else if (trUpdateRowData) {
      onCompanySearch()
    }
  }, [companyCreateResult, companyUpdateResult])

  return (
    <Container maxWidth="xl">
      <Typography component="div" variant="largeBold">
        ユーザー企業情報{trQueryParam.companyId ? '更新' : '追加'}
      </Typography>
      <Container maxWidth="md">
        <Box sx={{ border: 1, borderColor: 'grey.500', padding: 1 }} width="80%">
          <Grid container>
            <Grid container justifyContent="center" alignContent="center">
              {compnaySearchIsLoading && (
                <CircularProgress size={100} color="primary" sx={{ zIndex: 1, position: 'absolute' }} />
              )}
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
              {/* company_idがある場合(修正画面)のみ表示 */}
              {trQueryParam.companyId ? (
                <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
                  <Grid item md={4} sx={{ marginTop: 0 }}>
                    <Typography fontWeight="bold" marginRight={2}>
                      プロダクト統一企業ID
                    </Typography>
                  </Grid>
                  <Grid item md={6}>
                    <TextField disabled value={companyId ?? ''} sx={{ width: 400 }} />
                  </Grid>
                </Grid>
              ) : (
                <div />
              )}
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
              <Grid item md={4} sx={{ marginTop: 0 }}>
                <Typography fontWeight="bold" marginRight={2}>
                  企業名
                </Typography>
              </Grid>
              <Grid item md={6}>
                <TextField
                  value={companyName ?? ''}
                  onChange={(e) => setCompanyName(e.target.value)}
                  sx={{ width: 400 }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
              <Grid item md={4} sx={{ marginTop: 0 }}>
                <Typography fontWeight="bold" marginRight={2}>
                  会社法人等番号（登記所）
                </Typography>
              </Grid>
              <Grid item md={6}>
                <TextField
                  value={companyHojinNo ?? ''}
                  onChange={(e) => setCompanyHojinNo(e.target.value)}
                  sx={{ width: 400 }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
              <Grid item md={4} sx={{ marginTop: 0 }}>
                <Typography fontWeight="bold" marginRight={2}>
                  本社住所
                </Typography>
              </Grid>
              <Grid item md={6}>
                <TextField
                  value={companyAddress ?? ''}
                  onChange={(e) => setCompanyAddress(e.target.value)}
                  sx={{ width: 400 }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
              <Grid item md={4} sx={{ marginTop: 0 }}>
                <Typography fontWeight="bold" marginRight={2}>
                  GS1事業者コード
                </Typography>
              </Grid>
              <Grid item md={6}>
                <TextField
                  value={companyGln ?? ''}
                  onChange={(e) => setCompanyGln(e.target.value)}
                  sx={{ width: 400 }}
                />
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
              <Grid item md={4} sx={{ marginTop: 0 }}>
                <Typography fontWeight="bold" marginRight={2}>
                  業態
                </Typography>
              </Grid>
              {trTypecode ? (
                <Grid item md={6}>
                  <Select
                    defaultValue={businessCategory}
                    value={businessCategory}
                    onChange={(e) => {
                      setBusinessCategory(e.target.value)
                    }}
                  >
                    {trTypecode.codes.map((value) => (
                      <MenuItem key={value.sortOrder} value={value.typeCode}>
                        {value.typeName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              ) : (
                <div />
              )}
            </Grid>
            {trQueryParam.companyId ? (
              <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
                <Grid item md={4} sx={{ marginTop: 0 }}>
                  <Typography fontWeight="bold" marginRight={2}>
                    テナントID
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <TextField disabled value={tenantId ?? ''} sx={{ width: 400 }} />
                </Grid>
              </Grid>
            ) : (
              <div />
            )}

            <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
              <Grid item md={4} sx={{ marginTop: 0 }}>
                <Typography fontWeight="bold" marginRight={2}>
                  利用停止
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Checkbox
                  checked={deleteFlg}
                  onChange={(e) => {
                    setdeleteFlg(e.target.checked)
                  }}
                  color="success"
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid direction="row" container justifyContent="space-between" width="75%" paddingTop={1}>
          <Grid item>
            <MessageInfo result={trQueryParam.companyId ? companyUpdateResult?.result : companyCreateResult?.result} />
          </Grid>
          <Grid item>
            <BackButton herf={`/${protectedRoutes.CompanyList.path}`} />
            <Button variant="contained" onClick={trQueryParam.companyId ? onCompanyUpdate : onCompanyCreate}>
              {trQueryParam.companyId ? '更新' : '追加'}
              {companyUpdateIsLoading && <CircularProgress size={24} color="primary" />}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}

export default CompanyDetail
