export const convertFromDate = (date: Date | null | undefined) => {
  if (!date) return ''

  const lacaleDate = new Date(date).toLocaleDateString()
  return lacaleDate === 'Invalid Date' ? '' : lacaleDate
}

export const convertFromTime = (date: Date | null | undefined) => {
  if (!date) return ''

  const lacaleTime = new Date(date).toLocaleTimeString()
  return lacaleTime === 'Invalid Date' ? '' : lacaleTime
}

export const convertFromDateTime = (date: Date | null | undefined) =>
  `${convertFromDate(date)} ${convertFromTime(date)}`

// 日付で指定されたフォーマット通りの日付の文字列を返す
export const dateToStr24HPad0DayOfWeek = (
  date: Date | null | undefined,
  format?: string,
  isMonthDateZeroPadding = true
) => {
  if (!date) return ''

  const weekday = ['日', '月', '火', '水', '木', '金', '土']
  let trFormat = format || 'YYYY/MM/DD(WW) hh:mm:ss'

  const newDate = new Date(date)

  trFormat = trFormat.replace(/YYYY/g, `${newDate.getFullYear()}`)
  trFormat = trFormat.replace(/MM/g, `${isMonthDateZeroPadding ? '0' : ''}${newDate.getMonth() + 1}`.slice(-2))
  trFormat = trFormat.replace(/DD/g, `${isMonthDateZeroPadding ? '0' : ''}${newDate.getDate()}`.slice(-2))
  trFormat = trFormat.replace(/WW/g, weekday[newDate.getDay()])
  trFormat = trFormat.replace(/hh/g, `0${newDate.getHours()}`.slice(-2))
  trFormat = trFormat.replace(/mm/g, `0${newDate.getMinutes()}`.slice(-2))
  trFormat = trFormat.replace(/ss/g, `0${newDate.getSeconds()}`.slice(-2))
  return trFormat
}
