/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Col1 } from './Col1';
import {
    Col1FromJSON,
    Col1FromJSONTyped,
    Col1ToJSON,
} from './Col1';
import type { Col10 } from './Col10';
import {
    Col10FromJSON,
    Col10FromJSONTyped,
    Col10ToJSON,
} from './Col10';
import type { Col11 } from './Col11';
import {
    Col11FromJSON,
    Col11FromJSONTyped,
    Col11ToJSON,
} from './Col11';
import type { Col12 } from './Col12';
import {
    Col12FromJSON,
    Col12FromJSONTyped,
    Col12ToJSON,
} from './Col12';
import type { Col13 } from './Col13';
import {
    Col13FromJSON,
    Col13FromJSONTyped,
    Col13ToJSON,
} from './Col13';
import type { Col14 } from './Col14';
import {
    Col14FromJSON,
    Col14FromJSONTyped,
    Col14ToJSON,
} from './Col14';
import type { Col15 } from './Col15';
import {
    Col15FromJSON,
    Col15FromJSONTyped,
    Col15ToJSON,
} from './Col15';
import type { Col16 } from './Col16';
import {
    Col16FromJSON,
    Col16FromJSONTyped,
    Col16ToJSON,
} from './Col16';
import type { Col17 } from './Col17';
import {
    Col17FromJSON,
    Col17FromJSONTyped,
    Col17ToJSON,
} from './Col17';
import type { Col18 } from './Col18';
import {
    Col18FromJSON,
    Col18FromJSONTyped,
    Col18ToJSON,
} from './Col18';
import type { Col19 } from './Col19';
import {
    Col19FromJSON,
    Col19FromJSONTyped,
    Col19ToJSON,
} from './Col19';
import type { Col2 } from './Col2';
import {
    Col2FromJSON,
    Col2FromJSONTyped,
    Col2ToJSON,
} from './Col2';
import type { Col20 } from './Col20';
import {
    Col20FromJSON,
    Col20FromJSONTyped,
    Col20ToJSON,
} from './Col20';
import type { Col21 } from './Col21';
import {
    Col21FromJSON,
    Col21FromJSONTyped,
    Col21ToJSON,
} from './Col21';
import type { Col22 } from './Col22';
import {
    Col22FromJSON,
    Col22FromJSONTyped,
    Col22ToJSON,
} from './Col22';
import type { Col23 } from './Col23';
import {
    Col23FromJSON,
    Col23FromJSONTyped,
    Col23ToJSON,
} from './Col23';
import type { Col24 } from './Col24';
import {
    Col24FromJSON,
    Col24FromJSONTyped,
    Col24ToJSON,
} from './Col24';
import type { Col3 } from './Col3';
import {
    Col3FromJSON,
    Col3FromJSONTyped,
    Col3ToJSON,
} from './Col3';
import type { Col4 } from './Col4';
import {
    Col4FromJSON,
    Col4FromJSONTyped,
    Col4ToJSON,
} from './Col4';
import type { Col5 } from './Col5';
import {
    Col5FromJSON,
    Col5FromJSONTyped,
    Col5ToJSON,
} from './Col5';
import type { Col6 } from './Col6';
import {
    Col6FromJSON,
    Col6FromJSONTyped,
    Col6ToJSON,
} from './Col6';
import type { Col7 } from './Col7';
import {
    Col7FromJSON,
    Col7FromJSONTyped,
    Col7ToJSON,
} from './Col7';
import type { Col8 } from './Col8';
import {
    Col8FromJSON,
    Col8FromJSONTyped,
    Col8ToJSON,
} from './Col8';
import type { Col9 } from './Col9';
import {
    Col9FromJSON,
    Col9FromJSONTyped,
    Col9ToJSON,
} from './Col9';

/**
 * センター・商品・得意先毎出荷予測
 * @export
 * @interface ShipExpectedCustomer
 */
export interface ShipExpectedCustomer {
    /**
     * 
     * @type {number}
     * @memberof ShipExpectedCustomer
     */
    companyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShipExpectedCustomer
     */
    orgItemCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShipExpectedCustomer
     */
    itemCode: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpectedCustomer
     */
    centerId: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpectedCustomer
     */
    customerCompanyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShipExpectedCustomer
     */
    customerCompanyName: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpectedCustomer
     */
    expectedRank: number | null;
    /**
     * 
     * @type {Col1}
     * @memberof ShipExpectedCustomer
     */
    col1: Col1 | null;
    /**
     * 
     * @type {Col2}
     * @memberof ShipExpectedCustomer
     */
    col2: Col2 | null;
    /**
     * 
     * @type {Col3}
     * @memberof ShipExpectedCustomer
     */
    col3: Col3 | null;
    /**
     * 
     * @type {Col4}
     * @memberof ShipExpectedCustomer
     */
    col4: Col4 | null;
    /**
     * 
     * @type {Col5}
     * @memberof ShipExpectedCustomer
     */
    col5: Col5 | null;
    /**
     * 
     * @type {Col6}
     * @memberof ShipExpectedCustomer
     */
    col6: Col6 | null;
    /**
     * 
     * @type {Col7}
     * @memberof ShipExpectedCustomer
     */
    col7: Col7 | null;
    /**
     * 
     * @type {Col8}
     * @memberof ShipExpectedCustomer
     */
    col8: Col8 | null;
    /**
     * 
     * @type {Col9}
     * @memberof ShipExpectedCustomer
     */
    col9: Col9 | null;
    /**
     * 
     * @type {Col10}
     * @memberof ShipExpectedCustomer
     */
    col10: Col10 | null;
    /**
     * 
     * @type {Col11}
     * @memberof ShipExpectedCustomer
     */
    col11: Col11 | null;
    /**
     * 
     * @type {Col12}
     * @memberof ShipExpectedCustomer
     */
    col12: Col12 | null;
    /**
     * 
     * @type {Col13}
     * @memberof ShipExpectedCustomer
     */
    col13: Col13 | null;
    /**
     * 
     * @type {Col14}
     * @memberof ShipExpectedCustomer
     */
    col14: Col14 | null;
    /**
     * 
     * @type {Col15}
     * @memberof ShipExpectedCustomer
     */
    col15: Col15 | null;
    /**
     * 
     * @type {Col16}
     * @memberof ShipExpectedCustomer
     */
    col16: Col16 | null;
    /**
     * 
     * @type {Col17}
     * @memberof ShipExpectedCustomer
     */
    col17: Col17 | null;
    /**
     * 
     * @type {Col18}
     * @memberof ShipExpectedCustomer
     */
    col18: Col18 | null;
    /**
     * 
     * @type {Col19}
     * @memberof ShipExpectedCustomer
     */
    col19: Col19 | null;
    /**
     * 
     * @type {Col20}
     * @memberof ShipExpectedCustomer
     */
    col20: Col20 | null;
    /**
     * 
     * @type {Col21}
     * @memberof ShipExpectedCustomer
     */
    col21: Col21 | null;
    /**
     * 
     * @type {Col22}
     * @memberof ShipExpectedCustomer
     */
    col22: Col22 | null;
    /**
     * 
     * @type {Col23}
     * @memberof ShipExpectedCustomer
     */
    col23: Col23 | null;
    /**
     * 
     * @type {Col24}
     * @memberof ShipExpectedCustomer
     */
    col24: Col24 | null;
}

/**
 * Check if a given object implements the ShipExpectedCustomer interface.
 */
export function instanceOfShipExpectedCustomer(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "orgItemCode" in value;
    isInstance = isInstance && "itemCode" in value;
    isInstance = isInstance && "centerId" in value;
    isInstance = isInstance && "customerCompanyId" in value;
    isInstance = isInstance && "customerCompanyName" in value;
    isInstance = isInstance && "expectedRank" in value;
    isInstance = isInstance && "col1" in value;
    isInstance = isInstance && "col2" in value;
    isInstance = isInstance && "col3" in value;
    isInstance = isInstance && "col4" in value;
    isInstance = isInstance && "col5" in value;
    isInstance = isInstance && "col6" in value;
    isInstance = isInstance && "col7" in value;
    isInstance = isInstance && "col8" in value;
    isInstance = isInstance && "col9" in value;
    isInstance = isInstance && "col10" in value;
    isInstance = isInstance && "col11" in value;
    isInstance = isInstance && "col12" in value;
    isInstance = isInstance && "col13" in value;
    isInstance = isInstance && "col14" in value;
    isInstance = isInstance && "col15" in value;
    isInstance = isInstance && "col16" in value;
    isInstance = isInstance && "col17" in value;
    isInstance = isInstance && "col18" in value;
    isInstance = isInstance && "col19" in value;
    isInstance = isInstance && "col20" in value;
    isInstance = isInstance && "col21" in value;
    isInstance = isInstance && "col22" in value;
    isInstance = isInstance && "col23" in value;
    isInstance = isInstance && "col24" in value;

    return isInstance;
}

export function ShipExpectedCustomerFromJSON(json: any): ShipExpectedCustomer {
    return ShipExpectedCustomerFromJSONTyped(json, false);
}

export function ShipExpectedCustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipExpectedCustomer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'orgItemCode': json['org_item_code'],
        'itemCode': json['item_code'],
        'centerId': json['center_id'],
        'customerCompanyId': json['customer_company_id'],
        'customerCompanyName': json['customer_company_name'],
        'expectedRank': json['expected_rank'],
        'col1': Col1FromJSON(json['col1']),
        'col2': Col2FromJSON(json['col2']),
        'col3': Col3FromJSON(json['col3']),
        'col4': Col4FromJSON(json['col4']),
        'col5': Col5FromJSON(json['col5']),
        'col6': Col6FromJSON(json['col6']),
        'col7': Col7FromJSON(json['col7']),
        'col8': Col8FromJSON(json['col8']),
        'col9': Col9FromJSON(json['col9']),
        'col10': Col10FromJSON(json['col10']),
        'col11': Col11FromJSON(json['col11']),
        'col12': Col12FromJSON(json['col12']),
        'col13': Col13FromJSON(json['col13']),
        'col14': Col14FromJSON(json['col14']),
        'col15': Col15FromJSON(json['col15']),
        'col16': Col16FromJSON(json['col16']),
        'col17': Col17FromJSON(json['col17']),
        'col18': Col18FromJSON(json['col18']),
        'col19': Col19FromJSON(json['col19']),
        'col20': Col20FromJSON(json['col20']),
        'col21': Col21FromJSON(json['col21']),
        'col22': Col22FromJSON(json['col22']),
        'col23': Col23FromJSON(json['col23']),
        'col24': Col24FromJSON(json['col24']),
    };
}

export function ShipExpectedCustomerToJSON(value?: ShipExpectedCustomer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'org_item_code': value.orgItemCode,
        'item_code': value.itemCode,
        'center_id': value.centerId,
        'customer_company_id': value.customerCompanyId,
        'customer_company_name': value.customerCompanyName,
        'expected_rank': value.expectedRank,
        'col1': Col1ToJSON(value.col1),
        'col2': Col2ToJSON(value.col2),
        'col3': Col3ToJSON(value.col3),
        'col4': Col4ToJSON(value.col4),
        'col5': Col5ToJSON(value.col5),
        'col6': Col6ToJSON(value.col6),
        'col7': Col7ToJSON(value.col7),
        'col8': Col8ToJSON(value.col8),
        'col9': Col9ToJSON(value.col9),
        'col10': Col10ToJSON(value.col10),
        'col11': Col11ToJSON(value.col11),
        'col12': Col12ToJSON(value.col12),
        'col13': Col13ToJSON(value.col13),
        'col14': Col14ToJSON(value.col14),
        'col15': Col15ToJSON(value.col15),
        'col16': Col16ToJSON(value.col16),
        'col17': Col17ToJSON(value.col17),
        'col18': Col18ToJSON(value.col18),
        'col19': Col19ToJSON(value.col19),
        'col20': Col20ToJSON(value.col20),
        'col21': Col21ToJSON(value.col21),
        'col22': Col22ToJSON(value.col22),
        'col23': Col23ToJSON(value.col23),
        'col24': Col24ToJSON(value.col24),
    };
}

