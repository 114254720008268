import type { FormEvent } from 'react'
import React, { useEffect, useState } from 'react'

import { Box, Typography } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { InfoMessageDataList, OrderAlertDataList, SearchCondition } from 'components/parts/Top'
import { useInfoMessage } from 'hooks/useInfoMessage'
import { useOrderAlert } from 'hooks/useOrderAlert'
import { useSearchParamStore } from 'store/searchParamStore'
import { initDataListStatus } from 'types/types'
import { getLocalSearchParam } from 'utils/getLocalSearchParam'
import { sortToRequestParam, sortToSortModel } from 'utils/sortToRequestParam'

import type { GetOrderAlertOrderAlertGetRequest } from 'api-client'
import type { DataListStatus } from 'types/types'

function Top() {
  const { loginUserInfo } = useAuthContext()

  const searchParamState = useSearchParamStore((state) => state.searchParamState)
  const setSearchParamState = useSearchParamStore((state) => state.setSearchParamState)

  const { infomessage, fetchInfomessage, isLoading: infoMessageIsLoading } = useInfoMessage()
  const { orderAlert, fetchOrderAlert, isLoading: orderAlertIsLoading, result } = useOrderAlert()

  // 自社の検索条件をローカルストレージから取得する
  const localSearchParam = getLocalSearchParam(searchParamState, loginUserInfo?.tenantId, loginUserInfo?.companyId)

  const localCenterId = localSearchParam?.top.centerId || 0
  const localOrgSupplierId = localSearchParam?.top.orgSupplierId || undefined
  const localSort = localSearchParam?.top.sort || []
  const localPageNo = localSearchParam?.top.pageNo || 0
  const localLimit = localSearchParam?.top.limit || 100

  // クエリパラメータかローカルストレージに保存している検索条件を初期値に指定
  const initSearchParam: GetOrderAlertOrderAlertGetRequest = {
    // NOTE:centerIdは必須項目になっていてundefinedできないため、0を初期値としておく
    // SearchConditionコンポーネントの方で初期値をundefinedする対応を行う
    centerId: localCenterId,
    orgSupplierId: localOrgSupplierId,
    sort: localSort,
    pageNo: localPageNo,
    limit: localLimit,
  }

  const [searchParam, setSearchParam] = useState<GetOrderAlertOrderAlertGetRequest>(initSearchParam)
  const [listStatus, setListStatus] = useState<DataListStatus>({
    ...initDataListStatus(),
  })

  const onSearch = (requestParam: GetOrderAlertOrderAlertGetRequest, e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // 検索API実行
    fetchOrderAlert({
      ...requestParam,
      limit: listStatus.paginationModel.pageSize,
      pageNo: 0,
      sort: sortToRequestParam(listStatus.sortModel),
    })
    setSearchParam(requestParam)

    if (loginUserInfo?.tenantId && loginUserInfo?.companyId) {
      setSearchParamState<GetOrderAlertOrderAlertGetRequest>(loginUserInfo?.tenantId, loginUserInfo?.companyId, 'Top', {
        ...requestParam,
        sort: sortToRequestParam(listStatus.sortModel),
      })
    }
  }

  // ソート順とページ番号の変更通知
  const onListStatusChange = (status: DataListStatus) => {
    const trPageNo = status.paginationModel.page + 1

    fetchOrderAlert({
      ...searchParam,
      limit: status.paginationModel.pageSize,
      pageNo: trPageNo,
      sort: sortToRequestParam(status.sortModel),
    })

    if (loginUserInfo?.tenantId && loginUserInfo?.companyId) {
      setSearchParamState<GetOrderAlertOrderAlertGetRequest>(loginUserInfo?.tenantId, loginUserInfo?.companyId, 'Top', {
        ...searchParam,
        limit: status.paginationModel.pageSize,
        pageNo: trPageNo,
        sort: sortToRequestParam(status.sortModel),
      })
    }

    setListStatus({ ...listStatus, sortModel: status.sortModel, paginationModel: status.paginationModel })
  }

  useEffect(() => {
    fetchInfomessage({ companyId: loginUserInfo?.companyId, limit: 30, sort: ['info_time,desc'] })

    // ローカルストレージに保存している検索条件がある場合は初回レンダリング時に検索を実行する
    if (localCenterId) {
      fetchOrderAlert({ ...searchParam, sort: localSort })

      setListStatus({
        ...listStatus,
        sortModel: sortToSortModel(initSearchParam.sort),
        paginationModel: {
          pageSize: initSearchParam.limit ?? 100,
          page: initSearchParam.pageNo ?? 0,
        },
      })
    }
  }, [])

  return (
    <Box>
      <Typography variant="largeBold">トップページ</Typography>
      <Box sx={{ marginTop: '1.5rem' }}>
        <Typography variant="mediumBold">お知らせ</Typography>
        <Box border={1}>
          {/* 企業全体のお知らせ情報を30件取得し縦スクロールで5件ずつ表示する */}
          <InfoMessageDataList list={infomessage} isLoading={infoMessageIsLoading} />
        </Box>
      </Box>

      <Box sx={{ marginTop: '2rem' }}>
        <Typography variant="mediumBold">発注アラート</Typography>
        <SearchCondition searchParam={initSearchParam} onSearch={onSearch} result={result} />
        <Box maxWidth="770px">
          <OrderAlertDataList
            list={orderAlert}
            isLoading={orderAlertIsLoading}
            listStatus={listStatus}
            onListStatusChange={onListStatusChange}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default Top
