import React from 'react'

import DownloadIcon from '@mui/icons-material/Download'
import { Button, CircularProgress } from '@mui/material'

interface DownloadExcelButtonProps {
  onClick?: () => void
  isLoading?: boolean
}

function DownloadExcelButton({ onClick, isLoading }: DownloadExcelButtonProps) {
  return (
    <Button
      variant="contained"
      startIcon={isLoading ? <CircularProgress size={24} color="primary" /> : <DownloadIcon />}
      onClick={onClick}
    >
      Excel
    </Button>
  )
}

export default DownloadExcelButton
