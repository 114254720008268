import React from 'react'

import { type GridColDef } from '@mui/x-data-grid'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import clsx from 'clsx'
import { dateToStr24HPad0DayOfWeek } from 'utils/convertFromLocalDate'

import type { GridCellParams } from '@mui/x-data-grid-premium'
import type { InfoMessageList } from 'api-client'

interface DataListProps {
  list: InfoMessageList
  isLoading: boolean
}

function InfoMessageDataList({ list, isLoading }: DataListProps) {
  const columns: GridColDef[] = [
    {
      headerName: '',
      field: 'infoTime',
      minWidth: 180,
      type: 'dateTime',
    },
    {
      headerName: '',
      field: 'targetName',
      minWidth: 150,
      type: 'string',
    },
    {
      headerName: '',
      field: 'processTypeName',
      minWidth: 150,
      type: 'string',
    },
    {
      headerName: '',
      field: 'infoTypeName',
      minWidth: 100,
      type: 'string',
    },
    {
      headerName: '',
      field: 'message',
      type: 'string',
      minWidth: 1200,
      cellClassName: (params: GridCellParams<any, any, number>) =>
        // clsxを用いて、動的なクラス名を設定する
        clsx('super-app', {
          infotypeErrror: params.row.infoType === 'error',
          infotypewarning: params.row.infoType === 'warning',
        }),
    },
  ]

  return (
    <DataGridPremium
      // 画面サイズによって1画面で収まるように高さを調節する
      sx={{ height: '190px' }}
      // ヘッダー・フッターを隠す
      hideFooter
      columnHeaderHeight={0}
      rows={list.list}
      rowCount={list.maxcount as number}
      columns={columns}
      loading={isLoading}
      getRowId={(row) => dateToStr24HPad0DayOfWeek(row.infoTime) + row.message}
      density="compact"
    />
  )
}

export default InfoMessageDataList
