import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { productTitle } from 'constants/constants'
import { auth } from 'constants/fireabaseConfig'
import { protectedRoutes } from 'constants/routes'

import { AccountCircleOutlined, Logout, PhoneIphone, WarningRounded, SyncProblem } from '@mui/icons-material'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import MenuIcon from '@mui/icons-material/Menu'
import {
  Box,
  Chip,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Toolbar,
  Typography,
} from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { signOut } from 'firebase/auth'
import { useCurrentStatusStore } from 'store/currentStatusStore'
import { AppBar } from 'style'
import { dateToStr24HPad0DayOfWeek } from 'utils/convertFromLocalDate'

interface HeaderProps {
  isLogin: boolean
  menuOpen?: boolean
  handleDrawerOpen?: () => void
}

function Header({ isLogin, menuOpen, handleDrawerOpen }: HeaderProps) {
  const { loginUserInfo, loginUserInfoResult } = useAuthContext()
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const { hasCurrentStatuses } = useCurrentStatusStore()

  const handleUserMenuClick = (e: React.MouseEvent) => {
    setAnchorEl(e.currentTarget as Element)
  }
  const handleUserMenuClose = () => {
    setAnchorEl(null)
  }

  const logout = async (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault()

    const tenantId = auth.currentUser?.tenantId

    try {
      await signOut(auth).then(() => {
        // テナントIDを付与した状態でログイン画面に遷移させる
        // テナントIDがない場合はクエリパラメータを付与せず遷移させる
        window.location.href = `${protectedRoutes.Login.path}${tenantId ? `?tenantId=${tenantId}` : ''}`
      })
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: '#282c34',
      }}
      open={menuOpen}
    >
      <Toolbar>
        {isLogin && (
          <IconButton
            color="primary"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(menuOpen && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
        )}
        {isLogin && (
          <Box sx={{ flexGrow: 1, display: 'flex' }}>
            <Typography
              component="div"
              justifyContent="end"
              variant="largeBold"
              noWrap
              sx={{ color: 'white', marginBottom: 0 }}
            >
              {productTitle}
            </Typography>
            {hasCurrentStatuses() && (
              <Box sx={{ display: 'flex', marginLeft: '30px', alignItems: 'center' }}>
                <SyncProblem color="error" fontSize="large" />
                <Typography sx={{ marginLeft: '5px', marginTop: '5px' }} color="white">
                  データ更新処理が実行中です...
                </Typography>
              </Box>
            )}
          </Box>
        )}

        <Typography component="div" variant="medium" sx={{ color: 'white', marginRight: 0.5, marginBottom: 0 }}>
          {isLogin && loginUserInfo?.companyName}
        </Typography>
        <Typography component="div" variant="medium" sx={{ color: 'white', marginRight: 1.5, marginBottom: 0 }}>
          {/* 氏名10文字まで表示する */}
          {isLogin && loginUserInfo?.userName.substring(0, 10)}
        </Typography>
        <Typography component="div" variant="medium" sx={{ color: 'white', marginRight: 1, marginBottom: 0 }}>
          {isLogin && dateToStr24HPad0DayOfWeek(loginUserInfo?.systemDate, 'YYYY/MM/DD (WW)', false)}
        </Typography>
        {isLogin && !loginUserInfo?.isMfaEnabled && (
          <Chip
            label="2段階認証の設定をおすすめします→"
            color="warning"
            icon={<WarningRounded />}
            size="small"
            sx={{ marginLeft: '1rem', marginRight: '1rem' }}
          />
        )}
        <IconButton onClick={handleUserMenuClick} sx={{ color: 'white' }}>
          {isLogin && <AccountCircleOutlined fontSize="large" />}
        </IconButton>
        <Menu keepMounted open={open} anchorEl={anchorEl} onClose={handleUserMenuClose}>
          <MenuList>
            <MenuItem
              onClick={() => {
                navigate(
                  `/${protectedRoutes.UserDetail.path}?company_id=${loginUserInfo?.companyId}&user_id=${loginUserInfo?.userId}`
                )
                handleUserMenuClose()
              }}
            >
              <ListItemIcon>
                <ManageAccountsIcon color="action" fontSize="small" />
              </ListItemIcon>
              <ListItemText>ユーザー情報更新</ListItemText>
            </MenuItem>
            <MenuItem component={Link} to={protectedRoutes.AuthSetting.path} onClick={handleUserMenuClose}>
              <ListItemIcon>
                <PhoneIphone color="action" fontSize="small" />
              </ListItemIcon>
              <ListItemText>{protectedRoutes.AuthSetting.name}</ListItemText>
            </MenuItem>
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <Logout color="action" fontSize="small" />
              </ListItemIcon>
              <ListItemText>ログアウト</ListItemText>
            </MenuItem>
          </MenuList>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}

export default Header
