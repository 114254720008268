/* eslint-disable arrow-body-style */
import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react'

import { Box, Grid, Typography } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { type CellValueChangedEvent, type IRowNode, type ValueParserParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import clsx from 'clsx'
import { ActionButton, CanselButton } from 'components/commonparts'
import AppSnackbar from 'components/commonparts/AppSnackbar/AppSnackbar'
import ShowLastUpdateDatetime from 'components/commonparts/ShowLastUpdateDatetime/ShowLastUpdateDatetime'
import { format, isSameDay } from 'date-fns'
import { ja } from 'date-fns/locale'
import _ from 'lodash'
import { AG_GRID_LOCALE } from 'utils/agGridLocale'
import { convertDateToRequestParam } from 'utils/convertDateToRequestParam'
import { getAPI } from 'utils/getAPI'

import ExpectedRankHeader from './ExpectedRankHeader'
import ItemNameRenderer from './ItemNameRenderer'
import LoadingOverlay from './LoadingOverlay'
import RatingRenderer from './RatingRenderer'
import StockCellEditor from './StockCellEditor'
import {
  ROW_TYPE_ARRIVAL,
  ROW_TYPE_REASON,
  ROW_TYPE_SALES_ORDER,
  ROW_TYPE_SHIP,
  ROW_TYPE_STOCK,
  ROW_TYPE_STOCK_DAYS,
} from './table-model'

import type { StockCell, StockRow } from './table-model'
import type {
  CellClassParams,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  GridApi,
  IAggFuncParams,
  ICellEditorParams,
  ValueFormatterParams,
} from 'ag-grid-enterprise'
import 'ag-grid-enterprise'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import type { Daylist, ResponseResult, Stock, StockUpdateBase, Stocks } from 'api-client'
import './stock-grid.scss'

interface StockGridProps {
  trCompanyId: number
  data: Stocks
  isLoading: boolean
  tableEditable: boolean
  unitType: 'case' | 'pallet'
  onChange: (edited: boolean, gridRow: StockRow[]) => void
  onReload: () => void
}

export function StockGrid({
  trCompanyId,
  data,
  isLoading,
  tableEditable,
  unitType,
  onChange,
  onReload,
}: StockGridProps) {
  const { loginUserInfo } = useAuthContext()
  const systemDate = useMemo(() => loginUserInfo?.systemDate, [loginUserInfo])
  const [resultMessage, setResultMessage] = useState<ResponseResult | undefined>(undefined)

  const localeText = useMemo<{
    [key: string]: string
  }>(() => AG_GRID_LOCALE, [])

  const gridRef = useRef<AgGridReact<StockRow>>(null)
  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      suppressHeaderMenuButton: true,
      sortable: false,
      filter: false,
    }),
    []
  )
  const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 410px)' }), [])
  const gridStyle = useMemo(() => ({ width: '100%', height: 'calc(100vh - 430px)' }), [])
  const [daylist, setDaylist] = useState<Daylist[]>([])
  const [rowData, setRowData] = useState<StockRow[]>([])
  // const [updateSnackOpen, setUpdateSnackOpen] = useState(false)
  const [updating, setUpdating] = useState(false)

  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarSeverity, setSnackBarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('success')
  const [snackBarMessage, setSnackBarMessage] = useState('')

  const HEADER_GROUP_REC = 'headerGroupRec'
  const HEADER_GROUP_TODAY = 'headerGroupToday'
  const HEADER_GROUP_EXPECTED = 'headerGroupExpected'
  const TOTAL_ROW_ITEM_CODE = '0000000000'
  const TOTAL_ROW_NAME = 'センター合計'

  const { typeCode } = useAuthContext()

  const reasonTypes = useMemo<{ code: number; value: string }[]>(() => {
    const reasonGroup = typeCode.find((rg) => rg.typeGroup === 'order_quantity_reason')
    if (!reasonGroup) return []

    return reasonGroup.codes.map((c) => ({
      code: parseInt(c.typeCode, 10),
      value: c.typeName,
    }))

    return []
  }, [typeCode])

  /**
   * 日付リストのすべての日付カラム名（col1, col2,...）を返す
   */
  const allDayHeaders: string[] = useMemo(() => {
    if (!daylist) return []

    const headerList = daylist
      .sort((a: Daylist, b: Daylist) => (a.dateno || 0) - (b.dateno || 0))
      .map((day) => day.colname)
    return headerList.filter((header): header is string => header !== null && header !== undefined)
  }, [daylist])

  /**
   * 指定された日付カラムの1日前のカラム名を返す
   *
   * @param col 日付カラム名
   * @returns
   */
  function beforeCol(col: string) {
    const index = parseInt(col.replace('col', ''), 10)
    return `col${index - 1}`
  }

  /**
   * 指定された日付カラムを含む、全ての未来日付のカラム名を返す
   * @param col 日付カラム名
   * @returns
   */
  function allNextCols(col: string): string[] {
    if (!allDayHeaders) return []
    const index = allDayHeaders.indexOf(col)
    if (index !== -1) {
      return allDayHeaders.slice(index)
    }

    return []
  }

  function firstColName(): string {
    return allDayHeaders[0] || 'col1'
  }

  /**
   * 指定された行が属する商品の全ての行（入荷数、出荷数、在庫数、在庫日数）のなかから、rowTypeCodeで指定された種別の行を返す
   *
   * @param rowNode
   * @param rowTypeCode
   * @returns
   */
  function getRowInGroup(rowNode: IRowNode<StockRow>, rowTypeCode: string): IRowNode<StockRow> | undefined {
    const rowsInGroup = rowNode.parent?.childrenAfterGroup
    if (!rowsInGroup) return undefined
    return rowsInGroup.find((row) => row?.data?.rowType.code === rowTypeCode)
  }

  /**
   * 指定されたセルの在庫数を再計算する
   * @param rowNode 行データ
   * @param field カラム名
   * @returns
   */
  function calcStock(rowNode: IRowNode<StockRow>, field: string) {
    // 前日の在庫数を取得（負の場合は0とする）
    const beforeStockRow = getRowInGroup(rowNode, 'stock')
    const beforeCell = beforeStockRow?.data?.[beforeCol(field) as keyof StockRow] as StockCell
    const beforeStock = Math.max(beforeCell?.value || 0, 0)

    // 当日の入荷数を取得
    const arrivalRow = getRowInGroup(rowNode, 'arrival')
    const arrivalCell = arrivalRow?.data?.[field as keyof StockRow] as StockCell
    const arrival = arrivalCell.value || 0

    // 当日の出荷数を取得
    const shipRow = getRowInGroup(rowNode, 'ship')
    const shipCell = shipRow?.data?.[field as keyof StockRow] as StockCell
    const ship = shipCell.value || 0

    // 当日の在庫数（自分自身）を取得
    const stockRow = getRowInGroup(rowNode, 'stock')
    const stockCell = stockRow?.data?.[field as keyof StockRow] as StockCell

    // 在庫数を計算（在庫数 + 入荷数 - 出荷数）
    return {
      ...stockCell,
      value: beforeStock + arrival - ship,
    }
  }

  /**
   * 指定されたセルの在庫日数を再計算する
   * @param rowNode 行データ
   * @param field カラム名
   * @returns
   */
  function calcStockDays(rowNode: IRowNode<StockRow>, field: string) {
    // 当日の在庫数を取得
    const stockRow = getRowInGroup(rowNode, 'stock')
    const stockCell = stockRow?.data?.[field as keyof StockRow] as StockCell
    const stock = stockCell.value || 0

    // 同じグループの出荷行を取得
    const shipRow = getRowInGroup(rowNode, 'ship')

    // 当日の在庫日数（自分自身）を取得
    const stockDaysRow = getRowInGroup(rowNode, 'stockDays')
    const stockDaysCell = stockDaysRow?.data?.[field as keyof StockRow] as StockCell

    const blankData = {
      ...stockDaysCell,
      value: null,
    }

    // 当日を含めて7日以上のデータが無い（未来データが無い）場合は無効とする
    const futureColsWithToday = allNextCols(field)
    if (futureColsWithToday.length <= 7) {
      return blankData
    }

    const futureCols = futureColsWithToday.slice(1)
    const shipNums: number[] = []

    futureCols.forEach((col) => {
      const shipCell = shipRow?.data?.[col as keyof StockRow] as StockCell
      if (shipCell.enableData && shipCell.value != null) {
        shipNums.push(shipCell.value)
      }
    })

    if (shipNums.length < 7) {
      return blankData
    }

    const total = shipNums.slice(0, 7).reduce((acc, cur) => acc + cur, 0)
    if (total === 0 && stock === 0) {
      return blankData
    }

    const stockDays = stock / (total / 7)

    return {
      ...stockDaysCell,
      value: stockDays,
    }
  }

  function calcTotalByRowType(rowTypeCode: string, allRowNodes: IRowNode<StockRow>[], gridApi: GridApi<StockRow>) {
    const totalRow = allRowNodes.find((node) => node.data?.isTotal && node.data?.rowType.code === rowTypeCode)
    if (totalRow) {
      const rows = allRowNodes.filter((node) => !node.data?.isTotal && node.data?.rowType.code === rowTypeCode)
      allDayHeaders.forEach((col) => {
        const totalCol = totalRow.data?.[col as keyof StockRow] as StockCell
        if (!totalCol) return

        const total = rows.reduce((acc, row) => {
          const cell = row.data?.[col as keyof StockRow] as StockCell

          return acc + (cell?.value || 0)
        }, 0)

        const firstCell = rows?.[0].data?.[col as keyof StockRow] as StockCell

        totalRow.setDataValue(col, {
          ...totalCol,
          enableData: firstCell?.enableData || false,
          value: total,
        })
      })
      gridApi.refreshCells({
        rowNodes: [totalRow],
        force: true,
      })
    }
  }

  function calcTotal() {
    const gridApi = gridRef.current?.api
    if (!gridApi) return

    const allRowNodes: IRowNode<StockRow>[] = []

    gridApi.forEachNode((node) => {
      allRowNodes.push(node)
    })

    calcTotalByRowType('arrival', allRowNodes, gridApi)
    calcTotalByRowType('ship', allRowNodes, gridApi)
    calcTotalByRowType('salesOrder', allRowNodes, gridApi)
    calcTotalByRowType('stock', allRowNodes, gridApi)

    const totalStockDaysRow = allRowNodes.find((node) => node.data?.isTotal && node.data?.rowType.code === 'stockDays')
    if (totalStockDaysRow) {
      allNextCols(firstColName()).forEach((col) => {
        const res = calcStockDays(totalStockDaysRow, col)
        totalStockDaysRow.setDataValue(col, calcStockDays(totalStockDaysRow, col))
      })
      gridApi.refreshCells({
        rowNodes: [totalStockDaysRow],
        force: true,
      })
    }
  }

  /**
   * セルが編集可能かどうかを判定する
   */
  const isCellEditable = useCallback(
    (colEditable: boolean, rowTypeCode: string | undefined, obj: StockCell | null | undefined): boolean => {
      if (!rowTypeCode || !tableEditable || unitType === 'pallet') {
        return false
      }

      const rowEditable = ['arrival', 'ship', 'reason'].includes(rowTypeCode)
      const cellEditable = obj?.enableUpdate || false
      return rowEditable && colEditable && cellEditable
    },
    [tableEditable, unitType]
  )

  /**
   * セルの表示フォーマットを設定する
   */
  const valueFormatter = useCallback(
    (params: ValueFormatterParams<StockRow, StockCell>) => {
      const obj = params.value
      const rowTypeCode = params.data?.rowType?.code

      if (obj === null || obj === undefined) {
        return ''
      }
      if (rowTypeCode === 'reason') {
        return reasonTypes?.find((r) => r.code === obj.value)?.value || ''
      }

      if (unitType === 'pallet' && ['arrival', 'ship', 'stock'].includes(rowTypeCode || '')) {
        if (obj.value === null || obj.value === undefined) {
          return '-'
        }

        return obj.value.toFixed(1)
      }

      if (rowTypeCode === 'stockDays') {
        if (obj.value === null || obj.value === undefined) {
          return '-'
        }

        if (obj.value <= 0) {
          return '0'
        }

        if (obj.value > 999) {
          return '999 +'
        }

        return obj.value.toFixed(1)
      }

      if (obj.value === null || obj.value === undefined) {
        return '-'
      }

      return obj.value.toString()
    },
    [unitType]
  )

  /**
   * セルの値を変更した際の処理
   *
   * 無限ループを避けるため、編集の可能性がある入出荷行のセルのみを対象とする
   * 編集にともない、在庫数・在庫日数の再計算を行う
   */
  const onCellValueChanged = useCallback(
    (params: CellValueChangedEvent<StockRow>) => {
      const rowType = params.data?.rowType?.code

      if (['arrival', 'ship', 'reason'].includes(rowType) && !params.data.isTotal) {
        const gridApi = gridRef.current?.api
        if (!gridApi) return

        if (!params.node.id || !params.colDef.field) return
        const { field } = params.colDef

        const rowsInGroup = params.node.parent?.childrenAfterGroup
        if (!rowsInGroup) return

        const thisRow = rowsInGroup.find((row) => row.id === params.node.id)
        const thisRowNode = gridApi.getRowNode(thisRow?.id || '')
        if (!thisRowNode) return

        if (rowType === 'reason') {
          if (typeof params.newValue === 'number' || params.newValue === null) {
            thisRowNode.setDataValue(field, {
              ...params.oldValue,
              value: params.newValue,
              isCurrentUpdate: true,
            })
          }
          return
        }

        if (params.newValue.value == null) {
          thisRowNode.setDataValue(field, {
            ...params.newValue,
            value: 0,
          })
          return
        }

        const stockRow = rowsInGroup.find((row) => row.data?.rowType.code === 'stock')
        if (!stockRow || !stockRow.id) return

        const stockRowNode = gridApi.getRowNode(stockRow.id)
        if (!stockRowNode || !stockRowNode.data) return
        allNextCols(field).forEach((col) => {
          stockRowNode.setDataValue(col, calcStock(params.node, col))
        })

        const stockDaysRow = rowsInGroup.find((row) => row.data?.rowType.code === 'stockDays')
        if (!stockDaysRow || !stockDaysRow.id) return
        allNextCols(field).forEach((col) => {
          stockDaysRow.setDataValue(col, calcStockDays(params.node, col))
        })

        gridApi.refreshCells({
          rowNodes: [stockRowNode],
          force: true,
        })

        // 入出荷値を変更した場合、他のセルの在庫アラート状態が変わる可能性があるため
        // shipのスタイルを再計算するための行リフレッシュを行う
        if (['arrival', 'ship'].includes(rowType) && !params.data.isTotal) {
          const refreshTarget: IRowNode<StockRow>[] = []
          if (rowType === 'arrival') {
            const shipRow = getRowInGroup(thisRowNode, 'ship')
            if (shipRow) {
              refreshTarget.push(shipRow)
            }
          } else {
            refreshTarget.push(thisRowNode)
          }
          gridApi.redrawRows({
            rowNodes: refreshTarget,
          })
        }

        onChange(true, rowData)
        calcTotal()
      }
    },
    [daylist]
  )

  /**
   * セルの値を変更した際の処理
   * セル情報をオブジェクトとして保持しているため定義が必要
   */
  const valueParser = useCallback((params: ValueParserParams) => {
    return {
      ...params.oldValue,
      value: params.newValue,
    }
  }, [])

  /**
   * セルのクラスを設定する
   * @param colEditable 編集可能列かどうか
   * @param rectype 日付種別（rec, today, expected）
   * @param obj セル情報
   * @param rowTypeCode 行種別（arrival, ship, stock, stockDays）
   *
   */
  const cellClass = ({
    colEditable,
    rectype,
    obj,
    rowTypeCode,
    field,
    node,
  }: {
    colEditable: boolean
    rectype: string
    obj: StockCell
    rowTypeCode: string
    field: string | undefined
    node: IRowNode<StockRow>
  }) => {
    const classes = ['number-cell']

    if (['arrival'].includes(rowTypeCode)) {
      if (obj.orderDate && systemDate && isSameDay(obj.orderDate, systemDate)) {
        classes.push('todays-order-cell')
      }
    }

    if (['stock'].includes(rowTypeCode)) {
      if (!obj?.value || obj.value <= 0) {
        classes.push('negative-value')
      }
    }

    if (['stockDays'].includes(rowTypeCode)) {
      const stockDaysSetting = node.data?.stockDaysSetting || 0
      if (!obj?.value || obj.value <= stockDaysSetting) {
        classes.push('stock-days-warning')
      }
    }

    // 本日以降、かつ出荷数が0以上、かつ前日の在庫数よりも多い場合、セルを赤文字にする
    if (['ship'].includes(rowTypeCode) && field) {
      const stockRow = getRowInGroup(node, 'stock')

      if (stockRow) {
        if (obj?.value != null && obj.value > 0 && ['today', 'expected'].includes(rectype)) {
          const prevStockCell = stockRow.data?.[beforeCol(field) as keyof StockRow] as StockCell
          if (prevStockCell?.value != null && obj.value > prevStockCell.value) {
            classes.push('over-stock-value')
          }
        }
      }
    }

    if (['salesOrder'].includes(rowTypeCode) && field) {
      const shipRow = getRowInGroup(node, 'ship')
      const shipCell = shipRow?.data?.[field as keyof StockRow] as StockCell

      if (shipCell && obj.value) {
        if (shipCell.enableUpdate && obj.value > (shipCell.value || 0)) {
          classes.push('over-order-value')
        }
      }
    }

    if (isCellEditable(colEditable, rowTypeCode, obj)) {
      classes.push('editable-cell')
    }

    if (obj && obj.isCurrentUpdate) {
      classes.push('current-updated-cell')
    }

    if (obj && obj.isUpdate) {
      classes.push('updated-cell')
    }

    if (rectype === 'today') {
      classes.push('today-cell')
    }

    if (['arrival'].includes(rowTypeCode)) {
      if (obj.enableUpdate && obj.fixFlg) {
        classes.push('future-arrival-exists')
      }
    }

    return classes
  }

  /**
   * 日付カラムの表示名を取得する
   * @param day
   * @returns
   */
  function getheaderName(day: Daylist) {
    return day.datevalue ? format(day.datevalue, 'M/d(E)', { locale: ja }) : ''
  }

  /**
   * 日付カラムのカラム定義を生成する
   * @param rectype 日付種別（rec, today, expected）
   * @param colEditable 編集可能列かどうか
   * @returns
   */
  const genDayCols = (rectype: string, colEditable: boolean) => {
    if (!daylist) return []

    return daylist
      .filter((day: Daylist) => day.rectype === rectype)
      .sort((a: Daylist, b: Daylist) => (a.dateno || 0) - (b.dateno || 0))
      .map((day: Daylist, idx: number) => ({
        headerName: getheaderName(day),
        field: day.colname ? day.colname : undefined,
        width: 110,
        hide: idx >= 13,
        type: 'rightAligned',
        headerClass: clsx(`value-header-${rectype}`, {
          sunday: day.datevalue?.getDay() === 0,
        }),
        editable: (params: EditableCallbackParams<StockRow>) => {
          const obj = params.data?.[params.colDef.field as keyof StockRow] as StockCell
          return isCellEditable(colEditable, params.data?.rowType?.code, obj)
        },
        valueFormatter,
        cellClass: (params: CellClassParams<StockRow>) =>
          cellClass({
            colEditable,
            rectype,
            obj: params.value,
            rowTypeCode: params.data?.rowType?.code || '',
            field: params.colDef.field,
            node: params.node,
          }),
        valueParser,
        cellEditorSelector: (params: ICellEditorParams<StockRow>) => {
          switch (params.data?.rowType?.code) {
            case 'arrival':
            case 'ship':
              return {
                component: StockCellEditor,
              }
            case 'reason': {
              const values = reasonTypes?.map((r) => r.code) || []
              return {
                component: 'agRichSelectCellEditor',
                params: {
                  values: [null, ...values],
                  formatValue: (value: number) => {
                    const reason = reasonTypes.find((r) => r.code === value)
                    return reason ? reason.value : ''
                  },
                },
              }
            }
            default:
              return {
                component: StockCellEditor,
              }
          }
        },
      }))
  }

  const textSort = (a: string, b: string) => {
    if (a === 'センター合計') {
      return -1
    }
    if (b === 'センター合計') {
      return 1
    }
    // カスタムソートロジック
    if (a < b) {
      return -1
    }
    if (a > b) {
      return 1
    }
    return 0
  }

  /**
   * カラム定義
   * @returns
   */
  const updateColumnDefs = (): (ColDef | ColGroupDef)[] => [
    {
      field: 'companyId',
      hide: true,
      aggFunc: (params: IAggFuncParams<StockRow>) => params.rowNode?.allLeafChildren[0]?.data?.companyId,
    },
    {
      field: 'centerId',
      hide: true,
      aggFunc: (params: IAggFuncParams<StockRow>) => params.rowNode?.allLeafChildren[0]?.data?.centerId,
    },
    {
      field: 'itemCode',
      hide: true,
      aggFunc: (params: IAggFuncParams<StockRow>) => params.rowNode?.allLeafChildren[0]?.data?.itemCode,
    },
    {
      field: 'isTotal',
      hide: true,
      aggFunc: (params: IAggFuncParams<StockRow>) => params.rowNode?.allLeafChildren[0]?.data?.isTotal,
    },
    {
      headerName: '商品',
      width: 320,
      pinned: true,
      aggFunc: (params: IAggFuncParams<StockRow>) => params.rowNode?.allLeafChildren[0]?.data?.itemName,
      cellRenderer: ItemNameRenderer,
      field: 'itemName',
      suppressHeaderMenuButton: false,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: 'agTextColumnFilter',
          },
          {
            filter: 'agSetColumnFilter',
            filterParams: {
              comparator: textSort,
            },
          },
        ],
      },
      menuTabs: ['filterMenuTab'],
      cellRendererParams: {
        companyId: null,
      },
      sortable: true,
      comparator: (a, b, nodeA, nodeB, isInverted) => {
        if (!nodeA || !nodeB) {
          return 0
        }

        // 実データ行の並び順はそのまま
        if (!nodeA.group || !nodeB.group) {
          return 0
        }

        // センター合計グループを必ず先頭に設定する
        if (a === TOTAL_ROW_NAME || b === TOTAL_ROW_NAME) {
          if (isInverted) {
            return a === TOTAL_ROW_NAME ? 1 : -1
          }
          return a === TOTAL_ROW_NAME ? -1 : 1
        }

        // その他グループは標準ソート
        return a > b ? 1 : -1
      },
    },
    {
      headerName: '社内商品コード',
      width: 160,
      minWidth: 160,
      showRowGroup: 'orgItemCode',
      cellRenderer: 'agGroupCellRenderer',
      pinned: true,
      field: 'rowType.name',
      cellRendererParams: {
        suppressCount: true,
      },
    },
    {
      field: 'orgItemCode',
      rowGroup: true,
      filter: 'agTextColumnFilter',
      menuTabs: ['filterMenuTab'],
      hide: true,
    },
    {
      headerName: '予測精度',
      headerComponent: ExpectedRankHeader,
      width: 105,
      sortable: true,
      aggFunc: (params: IAggFuncParams<StockRow>) => params?.rowNode?.allLeafChildren[0]?.data?.expectedRank,
      cellRenderer: RatingRenderer,
    },
    {
      headerName: '発注条件',
      children: [
        {
          headerName: '商品コード',
          width: 150,
          aggFunc: (params: IAggFuncParams<StockRow>) => {
            const repData = params.rowNode?.allLeafChildren[0]?.data
            if (repData?.isTotal) {
              return ''
            }

            return repData?.itemCode
          },
        },
        {
          headerName: '発注単位',
          width: 150,
          aggFunc: (params: IAggFuncParams<StockRow>) => params?.rowNode?.allLeafChildren[0]?.data?.orderUnitTypeName,
        },
        {
          headerName: 'ケースロット数',
          width: 150,
          aggFunc: (params: IAggFuncParams<StockRow>) => params?.rowNode?.allLeafChildren[0]?.data?.caseLot,
        },
        {
          headerName: '最低発注数',
          width: 150,
          aggFunc: (params: IAggFuncParams<StockRow>) => params?.rowNode?.allLeafChildren[0]?.data?.minOrderQuantity,
        },
      ],
    },
    {
      headerName: '在庫設定',
      children: [
        {
          headerName: '在庫日数',
          width: 100,
          aggFunc: (params: IAggFuncParams<StockRow>) => params?.rowNode?.allLeafChildren[0]?.data?.stockDaysSetting,
        },
        {
          headerName: '安全在庫',
          width: 100,
          aggFunc: (params: IAggFuncParams<StockRow>) => params?.rowNode?.allLeafChildren[0]?.data?.safetyStockSetting,
        },
        {
          headerName: '適用開始日',
          width: 150,
          aggFunc: (params: IAggFuncParams<StockRow>) => {
            const startDate = params?.rowNode?.allLeafChildren[0]?.data?.startDate

            if (startDate) {
              return format(startDate, 'yyyy/MM/dd')
            }
            return '-'
          },
        },
        {
          headerName: '出荷停止',
          width: 100,
          aggFunc: (params: IAggFuncParams<StockRow>) => {
            const stopShip = params?.rowNode?.allLeafChildren[0]?.data?.stopShip
            return stopShip ? '✓' : ''
          },
        },
        {
          headerName: '入荷停止',
          width: 100,
          aggFunc: (params: IAggFuncParams<StockRow>) => {
            const stopArrival = params?.rowNode?.allLeafChildren[0]?.data?.stopArrival
            return stopArrival ? '✓' : ''
          },
        },
      ],
    },
    {
      groupId: HEADER_GROUP_REC,
      headerName: '実績',
      headerClass: 'value-header-rec',
      children: genDayCols('rec', false),
    },
    {
      groupId: HEADER_GROUP_TODAY,
      headerName: '当日',
      headerClass: 'value-header-today',
      children: genDayCols('today', true),
    },
    {
      groupId: HEADER_GROUP_EXPECTED,
      headerName: '予定',
      headerClass: 'value-header-expected',
      children: genDayCols('expected', true),
    },
  ]

  const columnDefs = useMemo<(ColDef | ColGroupDef)[]>(updateColumnDefs, [daylist])

  /**
   * Grid上で扱いやすくするため、Stockデータの入荷数・出荷数・在庫数・在庫日数をそれぞれ行に分割する
   *
   * @param dataList 商品リスト（Stock）
   * @param dayList  日付リスト（Daylist）
   * @returns 入荷数・出荷数・在庫数・在庫日数をそれぞれ行に分割したデータ
   */
  const resultToRows = (dataList: Array<Stock>, dayList: Array<Daylist>): StockRow[] => {
    const dataByGroup = _.groupBy(dataList, 'orgItemCode')
    const rows: StockRow[] = []

    /*
     * センター合計行作成
     */
    if (dataList.length !== 0) {
      const totalRow = {
        companyId: trCompanyId,
        centerId: 0,
        orgItemCode: '合計',
        itemCode: TOTAL_ROW_ITEM_CODE,
        itemName: TOTAL_ROW_NAME,
        janCase: null,
        expectedRank: null,
        orderUnitTypeName: null,
        caseLot: null,
        minOrderQuantity: null,
        stockDaysSetting: null,
        safetyStockSetting: null,
        minStockQuantity: null,
        startDate: null,
        stopShip: null,
        stopArrival: null,
        isTotal: true,
      }
      const totalArrivalRow: StockRow = {
        ...totalRow,
        rowType: ROW_TYPE_ARRIVAL,
      }

      const totalShipRow: StockRow = {
        ...totalRow,
        rowType: ROW_TYPE_SHIP,
      }

      const totalSalesOrderRow: StockRow = {
        ...totalRow,
        rowType: ROW_TYPE_SALES_ORDER,
      }

      const totalStockRow: StockRow = {
        ...totalRow,
        rowType: ROW_TYPE_STOCK,
      }

      const totalStockDaysRow: StockRow = {
        ...totalRow,
        rowType: ROW_TYPE_STOCK_DAYS,
      }

      dayList.forEach((day) => {
        if (day.datevalue === null) {
          return
        }

        const totalCommonColumn = {
          recDate: day.datevalue,
          orderDate: null,
          isCurrentUpdate: false,
          oldValue: null,
          value: 0,
          enableUpdate: false,
          isUpdate: false,
          recType: day.rectype,
          // ship行は再計算時に適切な値をセット
          enableData: false,
        }

        if (day.dateno !== null) {
          totalArrivalRow[`col${day.dateno}`] = {
            ...totalCommonColumn,
          }
          totalShipRow[`col${day.dateno}`] = {
            ...totalCommonColumn,
          }
          totalSalesOrderRow[`col${day.dateno}`] = {
            ...totalCommonColumn,
          }
          totalStockRow[`col${day.dateno}`] = {
            ...totalCommonColumn,
          }
          totalStockDaysRow[`col${day.dateno}`] = {
            ...totalCommonColumn,
          }
        }
      })

      rows.push(totalArrivalRow)
      rows.push(totalShipRow)
      rows.push(totalSalesOrderRow)
      rows.push(totalStockRow)
      rows.push(totalStockDaysRow)
    }

    /*
     * データ行作成
     */
    _.forEach(dataByGroup, (stocks, key) => {
      const {
        companyId,
        centerId,
        orgItemCode,
        itemCode,
        itemName,
        janCase,
        expectedRank,
        orderUnitTypeName,
        caseLot,
        minOrderQuantity,
        stockDaysSetting,
        safetyStockSetting,
        minStockQuantity,
        startDate,
        stopShip,
        stopArrival,
      } = stocks[0]

      if (!companyId || !centerId || !itemCode || !orgItemCode) {
        return
      }

      const parentData = {
        companyId,
        centerId,
        orgItemCode,
        itemCode,
        itemName,
        janCase,
        expectedRank,
        orderUnitTypeName,
        caseLot,
        minOrderQuantity,
        stockDaysSetting,
        safetyStockSetting,
        minStockQuantity,
        startDate,
        stopShip,
        stopArrival,
        isTotal: false,
      }
      const arrivalRow: StockRow = {
        ...parentData,
        rowType: ROW_TYPE_ARRIVAL,
      }

      const shipRow: StockRow = {
        ...parentData,
        rowType: ROW_TYPE_SHIP,
      }
      const salesOrderRow: StockRow = {
        ...parentData,
        rowType: ROW_TYPE_SALES_ORDER,
      }
      const stockRow: StockRow = {
        ...parentData,
        rowType: ROW_TYPE_STOCK,
      }
      const stockDaysRow: StockRow = {
        ...parentData,
        rowType: ROW_TYPE_STOCK_DAYS,
      }

      const reasonRow: StockRow = {
        ...parentData,
        rowType: ROW_TYPE_REASON,
      }

      dayList.forEach((day) => {
        if (day.datevalue === null) {
          return
        }

        const matchedData = stocks.find(
          (stock) => stock.recDate && day.datevalue && isSameDay(stock.recDate, day.datevalue)
        )

        if (day.dateno !== null) {
          if (matchedData && matchedData.recDate) {
            const commonColumn = {
              recDate: matchedData.recDate,
              orderDate: matchedData.orderDate,
              isCurrentUpdate: false,
              oldValue: null,
              enableData: false,
            }
            arrivalRow[`col${day.dateno}`] = {
              ...commonColumn,
              // value: matchedData.arrivalQuantity,
              value: unitType === 'case' ? matchedData.arrivalQuantity : matchedData.arrivalQuantityPallet,
              enableUpdate: matchedData.enableUpdateArrival || false,
              isUpdate: matchedData.isUpdatedArrival || false,
              recType: day.rectype,
              fixFlg: matchedData.arrivalFixFlg || false,
            }

            shipRow[`col${day.dateno}`] = {
              ...commonColumn,
              value: unitType === 'case' ? matchedData.shipQuantity : matchedData.shipQuantityPallet,
              enableUpdate: matchedData.enableUpdateShip || false,
              isUpdate: matchedData.isUpdatedShip || false,
              recType: day.rectype,
              enableData: matchedData.enableShip || false,
            }

            salesOrderRow[`col${day.dateno}`] = {
              ...commonColumn,
              value: matchedData.salesorderQuantity,
              enableUpdate: false,
              isUpdate: false,
              recType: day.rectype,
            }

            stockRow[`col${day.dateno}`] = {
              ...commonColumn,
              value: unitType === 'case' ? matchedData.stockQuantity : matchedData.stockQuantityPallet,
              enableUpdate: false,
              isUpdate: false,
              recType: day.rectype,
            }

            stockDaysRow[`col${day.dateno}`] = {
              ...commonColumn,
              value: matchedData.stockDays,
              enableUpdate: false,
              isUpdate: false,
              recType: day.rectype,
            }

            reasonRow[`col${day.dateno}`] = {
              ...commonColumn,
              value: matchedData.orderQuantityReason ? parseInt(matchedData.orderQuantityReason, 10) : null,
              enableUpdate: true,
              isUpdate: false,
              recType: day.rectype,
            }
          } else {
            arrivalRow[`col${day.dateno}`] = null
            shipRow[`col${day.dateno}`] = null
            stockRow[`col${day.dateno}`] = null
            salesOrderRow[`col${day.dateno}`] = null
            stockDaysRow[`col${day.dateno}`] = null
            reasonRow[`col${day.dateno}`] = null
          }
        }
      })

      rows.push(arrivalRow)
      rows.push(shipRow)
      rows.push(salesOrderRow)
      rows.push(stockRow)
      rows.push(stockDaysRow)
      rows.push(reasonRow)
    })

    return rows
  }

  /**
   * 再検索等でデータが更新されたタイミングで呼び出すイベント
   * 以下の処理を行う
   *
   * - 日付リストから絡む定義を作成
   * - グリッド用のデータ加工
   *
   */
  useEffect(() => {
    setDaylist(data.daylist)
    setRowData(resultToRows(data.list, data.daylist))
  }, [data])

  useEffect(() => {
    const gridApi = gridRef.current?.api
    if (!gridApi) return

    if (isLoading || updating) {
      gridApi.showLoadingOverlay()
    } else {
      gridApi.hideOverlay()
    }
  }, [isLoading, updating])

  /**
   * グリッドにデータが格納され準備ができた状態で呼び出すイベント
   * 以下の処理を行う
   *
   * - 在庫の計算
   * - 在庫日数の計算
   *
   * @returns
   */
  const onModelUpdated = () => {
    const gridApi = gridRef.current?.api
    if (!gridApi) return

    gridApi.forEachNode((node) => {
      if (node.data && node.data.rowType.code === 'stock') {
        allNextCols('col8').forEach((col) => {
          node.setDataValue(col, calcStock(node, col))
        })
      }
    })

    // 在庫日数の計算は在庫数に依存するため、在庫数の計算が完了してから行う
    gridApi.forEachNode((node) => {
      if (node.data && node.data.rowType.code === 'stockDays') {
        allNextCols(firstColName()).forEach((col) => {
          node.setDataValue(col, calcStockDays(node, col))
        })
      }
    })

    calcTotal()
    onChange(false, rowData)

    gridApi.ensureColumnVisible('col6', 'start')
  }

  /**
   * 編集されたセルのデータ一覧を取得する
   * @returns
   */
  function getEditedCells(): StockUpdateBase[] {
    const gridApi = gridRef.current?.api
    if (!gridApi) return []

    const rowNodes: IRowNode<StockRow>[] = []
    gridApi.forEachNode((node) => {
      if (node && ['arrival', 'ship', 'reason'].includes(node.data?.rowType?.code || '')) {
        rowNodes.push(node)
      }
    })

    const dataList: StockUpdateBase[] = []
    const allCols = allNextCols(firstColName())

    // 日付カラムの全てのセル情報を取得
    const allCells = rowNodes
      .filter((node) => !!node.data)
      .map((node) => {
        return allCols.map((col) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const cellData = node.data![col as keyof StockRow] as StockCell

          return {
            ...cellData,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            companyId: node.data!.companyId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            centerId: node.data!.centerId,
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            itemCode: node.data!.itemCode,
            rowTypeCode: node.data?.rowType.code,
          }
        })
      })
      .flat()

    // 今回変更された内容をセルから取得し、リクエスト用のデータに変換する（セル単位からレコード単位に変換）
    allCells.forEach((cellData) => {
      if (cellData && cellData.isCurrentUpdate) {
        const existedData = dataList.find(
          (d) =>
            d.companyId === cellData.companyId &&
            d.centerId === cellData.centerId &&
            d.itemCode === cellData.itemCode &&
            isSameDay(d.recDate, cellData.recDate)
        )
        if (existedData) {
          switch (cellData.rowTypeCode) {
            case 'arrival':
              existedData.arrivalQuantityPlan = cellData.value
              break
            case 'ship':
              existedData.shipQuantityPlan = cellData.value
              break
            case 'reason':
              existedData.orderQuantityReason = cellData.value ? cellData.value.toString() : null
              break
            default:
              break
          }
        } else {
          const newData = {
            companyId: cellData.companyId,
            centerId: cellData.centerId,
            itemCode: cellData.itemCode,
            recDate: cellData.recDate,
            orderDate: cellData.orderDate,
          }
          switch (cellData.rowTypeCode) {
            case 'arrival':
              dataList.push({
                ...newData,
                arrivalQuantityPlan: cellData.value,
              })
              break
            case 'ship':
              dataList.push({
                ...newData,
                shipQuantityPlan: cellData.value,
              })
              break
            case 'reason':
              dataList.push({
                ...newData,
                orderQuantityReason: cellData.value ? cellData.value.toString() : null,
              })
              break

            default:
              break
          }
        }
      }
    })

    const findSameRecord = (sub: StockUpdateBase, rowTypeCode: string, hasUpdateFlg: boolean) => {
      return allCells.find(
        (c) =>
          c.companyId === sub.companyId &&
          c.centerId === sub.centerId &&
          c.itemCode === sub.itemCode &&
          isSameDay(c.recDate, sub.recDate) &&
          c.rowTypeCode === rowTypeCode &&
          (hasUpdateFlg ? c.isUpdate : true)
      )
    }

    // リクエスト内にすでに更新された内容が含まれない場合その内容が失われてしまうため、
    // 既存の更新内容が存在すればそれをセットする
    const requestDataList = dataList.map((dt) => {
      const d = { ...dt }
      if (d.arrivalQuantityPlan === undefined) {
        const updatedCell = findSameRecord(d, 'arrival', true)
        if (updatedCell) {
          d.arrivalQuantityPlan = updatedCell.value
        }
      }

      if (d.shipQuantityPlan === undefined) {
        const updatedCell = findSameRecord(d, 'ship', true)
        if (updatedCell) {
          d.shipQuantityPlan = updatedCell.value
        }
      }

      if (d.orderQuantityReason === undefined) {
        const updatedCell = findSameRecord(d, 'reason', false)
        if (updatedCell && updatedCell.value) {
          d.orderQuantityReason = updatedCell.value.toString()
        }
      }

      return d
    })

    // 自動的にUTC認識されて日付がずれるため、共用関数convertDateToRequestParamで調整する。
    return requestDataList.map((d) => ({
      ...d,
      recDate: convertDateToRequestParam(d.recDate),
      orderDate: d.orderDate ? convertDateToRequestParam(d.orderDate) : d.orderDate,
    }))
  }

  /**
   * 編集した入出荷数を保存する
   *
   * @returns
   */
  const onListUpdate = async () => {
    const datalist = getEditedCells()
    if (datalist.length === 0) {
      return
    }

    setUpdating(true)

    const api = await getAPI()
    if (!api) return

    try {
      const response = await api.stockUpdateStockUpdatePost({
        stockUpdate: {
          body: datalist,
        },
      })

      if (response.result.status === 'error') {
        setSnackBarMessage(response.errors[0]?.errorMessage || 'エラーが発生しました')
        setSnackBarSeverity('error')
        setSnackBarOpen(true)
        return
      }

      onReload()
      setSnackBarMessage('正常に更新されました')
      setSnackBarSeverity('success')
      setSnackBarOpen(true)
    } catch (e) {
      setResultMessage({
        status: 'error',
        message: '入荷数修正でエラーが発生しました。',
        systemDate: null,
        pageNo: 0,
        systemInfo: null,
      })
    } finally {
      setUpdating(false)
    }
  }

  const onSnackbarClose = () => {
    setSnackBarOpen(false)
  }

  return (
    <Box>
      <Grid direction="row" container justifyContent="space-between">
        <Grid item>
          <Typography component="div" variant="largeBold">
            倉庫別発注商品一覧
          </Typography>
        </Grid>
        <Grid item sx={{ marginBottom: '10px', marginRight: '-0.7rem' }}>
          <Grid direction="row" container alignItems="flex-end">
            <Grid item sx={{ marginRight: '20px' }}>
              <ShowLastUpdateDatetime<Stock> list={data.list} lastUpdateDatetime={data.lastUpdateDatetime} />
            </Grid>
            <Grid item>
              <ActionButton onClick={onListUpdate} disabled={false} />
              <CanselButton
                onClick={() => {
                  onReload()
                }}
                caption="初期化"
                disabled={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div style={containerStyle}>
        <div style={{ height: '100%', boxSizing: 'border-box' }}>
          <div style={gridStyle} className="ag-theme-quartz">
            <AgGridReact
              ref={gridRef}
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              localeText={localeText}
              rowData={rowData}
              groupDisplayType="custom"
              groupDefaultExpanded={1}
              suppressAggFuncInHeader
              rowHeight={36}
              reactiveCustomComponents
              onCellValueChanged={onCellValueChanged}
              onModelUpdated={onModelUpdated}
              loadingOverlayComponent={LoadingOverlay}
            />
          </div>
        </div>
      </div>
      <AppSnackbar
        message={snackBarMessage}
        open={snackBarOpen}
        onClose={onSnackbarClose}
        severity={snackBarSeverity}
      />
    </Box>
  )
}
