/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MonthReports } from './MonthReports';
import {
    MonthReportsFromJSON,
    MonthReportsFromJSONTyped,
    MonthReportsToJSON,
} from './MonthReports';
import type { ResponseResult } from './ResponseResult';
import {
    ResponseResultFromJSON,
    ResponseResultFromJSONTyped,
    ResponseResultToJSON,
} from './ResponseResult';

/**
 * 
 * @export
 * @interface ResponseMonthReport
 */
export interface ResponseMonthReport {
    /**
     * 
     * @type {ResponseResult}
     * @memberof ResponseMonthReport
     */
    result: ResponseResult;
    /**
     * 
     * @type {MonthReports}
     * @memberof ResponseMonthReport
     */
    data: MonthReports;
}

/**
 * Check if a given object implements the ResponseMonthReport interface.
 */
export function instanceOfResponseMonthReport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ResponseMonthReportFromJSON(json: any): ResponseMonthReport {
    return ResponseMonthReportFromJSONTyped(json, false);
}

export function ResponseMonthReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseMonthReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ResponseResultFromJSON(json['result']),
        'data': MonthReportsFromJSON(json['data']),
    };
}

export function ResponseMonthReportToJSON(value?: ResponseMonthReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ResponseResultToJSON(value.result),
        'data': MonthReportsToJSON(value.data),
    };
}

