import { useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type { Groups, ResponseResult } from 'api-client'

export const useTypecode = () => {
  const [typeCode, setTypeCode] = useState<Groups[]>([])
  const [result, setResult] = useState<ResponseResult | undefined>(undefined)

  const [isLoading, setIsLoading] = useState(false)
  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchTypeCode = () => {
    setIsLoading(false)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getSysTypecodeTypecodeGet()
      })
      .then((response) => {
        setTypeCode(response.data)
        setResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(() => {
        setResult({
          status: 'error',
          message: `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    typeCode,
    result,
    fetchTypeCode,
    isLoading,
  }
}
