/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShipExpectedCustomer } from './ShipExpectedCustomer';
import {
    ShipExpectedCustomerFromJSON,
    ShipExpectedCustomerFromJSONTyped,
    ShipExpectedCustomerToJSON,
} from './ShipExpectedCustomer';

/**
 * 
 * @export
 * @interface ShipExpectedCustomers
 */
export interface ShipExpectedCustomers {
    /**
     * 
     * @type {number}
     * @memberof ShipExpectedCustomers
     */
    datacount: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShipExpectedCustomers
     */
    maxcount: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ShipExpectedCustomers
     */
    lastUpdateDatetime: Date | null;
    /**
     * 
     * @type {Array<ShipExpectedCustomer>}
     * @memberof ShipExpectedCustomers
     */
    list: Array<ShipExpectedCustomer>;
}

/**
 * Check if a given object implements the ShipExpectedCustomers interface.
 */
export function instanceOfShipExpectedCustomers(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "datacount" in value;
    isInstance = isInstance && "maxcount" in value;
    isInstance = isInstance && "lastUpdateDatetime" in value;
    isInstance = isInstance && "list" in value;

    return isInstance;
}

export function ShipExpectedCustomersFromJSON(json: any): ShipExpectedCustomers {
    return ShipExpectedCustomersFromJSONTyped(json, false);
}

export function ShipExpectedCustomersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipExpectedCustomers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datacount': json['datacount'],
        'maxcount': json['maxcount'],
        'lastUpdateDatetime': (json['last_update_datetime'] === null ? null : new Date(json['last_update_datetime'])),
        'list': ((json['list'] as Array<any>).map(ShipExpectedCustomerFromJSON)),
    };
}

export function ShipExpectedCustomersToJSON(value?: ShipExpectedCustomers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datacount': value.datacount,
        'maxcount': value.maxcount,
        'last_update_datetime': (value.lastUpdateDatetime === null ? null : value.lastUpdateDatetime.toISOString()),
        'list': ((value.list as Array<any>).map(ShipExpectedCustomerToJSON)),
    };
}

