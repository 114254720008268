import React from 'react'

import { FormControl, Grid, InputLabel, Typography } from '@mui/material'
import { CustomDetailInput, detailCompornentMarginBottom } from 'style'

import type { MstSupplier } from 'api-client'

interface SupplierDetailProps {
  supplier: MstSupplier | undefined
}

function SupplierDetail({ supplier }: SupplierDetailProps) {
  return (
    <Grid item direction="row" container sx={{ marginBottom: detailCompornentMarginBottom }}>
      <Typography variant="h2">仕入先詳細</Typography>
      <Grid item xs={12}>
        <FormControl>
          <InputLabel>仕入先企業ID</InputLabel>
          <CustomDetailInput size="small" value={supplier?.orgSupplierId ?? ''} />
        </FormControl>
        <FormControl>
          <InputLabel>仕入先企業名</InputLabel>
          <CustomDetailInput size="small" doubleWidth value={supplier?.supplierName ?? ''} />
        </FormControl>
        <FormControl>
          <InputLabel>統一企業ID</InputLabel>
          <CustomDetailInput size="small" value={supplier?.supplierCompanyId ?? ''} />
        </FormControl>
        <FormControl>
          <InputLabel>1回あたり最低発注数</InputLabel>
          <CustomDetailInput size="small" value={supplier?.minOrderQuantityOnce ?? ''} />
        </FormControl>
        <FormControl>
          <InputLabel>発注リードタイム</InputLabel>
          <CustomDetailInput size="small" value={supplier?.orderLeadtime ?? ''} />
        </FormControl>
        <FormControl>
          <InputLabel>締め時間</InputLabel>
          <CustomDetailInput size="small" value={supplier?.orderLimitTime ?? ''} />
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default SupplierDetail
