import React, { useState } from 'react'

import { Snackbar, Alert } from '@mui/material'

interface Props {
  open: boolean
  message: string
  onClose: () => void
  severity: 'error' | 'warning' | 'info' | 'success'
}

function AppSnackbar({ open, message, onClose, severity = 'info' }: Props) {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={4000}
      sx={{
        '&.MuiSnackbar-root': { top: '70px', right: '20px' },
      }}
    >
      <Alert severity={severity} variant="filled">
        {message}
      </Alert>
    </Snackbar>
  )
}

export default AppSnackbar
