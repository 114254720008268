import type { Dispatch, SetStateAction } from 'react'
import React from 'react'

import { ERROR_RATE_LABEL, STOCK_DAYS_LABEL } from 'constants/constants'

import { Box, Button, CircularProgress, Grid } from '@mui/material'
import { initialChartState, type InitialChartState } from 'pages/Dashboard/Dashboard'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import { mediumFontSize } from 'style'

import type { MonthReport, Stock } from 'api-client'
import type { CurveType } from 'recharts/types/shape/Curve'

// rechartsライブラリのprops
export interface LineCart {
  height: string | number
  xAxis: string
  // 右側の縦軸に表示する項目
  yAxisRight?: string
  yAxisLabel?: string
  line: {
    chartType: string
    name: string
    type?: CurveType
    dataKey: string
    stroke?: string
    fill?: string
    yAxisId?: string
    // 点線かどうか
    dashedLine?: boolean
    // グラフにドットを付与するかどうか
    dot?: boolean
    // グラフを非表示にする項目
    hide?: boolean
  }[]
}

interface ChartProps {
  list: Stock[] | MonthReport[]
  chartDetail: LineCart
  chartState: InitialChartState
  setChartState: Dispatch<SetStateAction<InitialChartState>>
  chartZoom: (chartState: InitialChartState, setChartState: Dispatch<SetStateAction<InitialChartState>>) => void
  chartZoomOut: (setChartState: Dispatch<SetStateAction<InitialChartState>>) => void
  recDateFormatter: (trRecDateToNumber: number) => string
  isLoading: boolean
}

// rechartsライブラリを用いたChartコンポーネント
// 拡大縮小機能については下記を参考
// https://recharts.org/en-US/examples/HighlightAndZoomLineChart
function Chart({
  list,
  chartDetail,
  // 拡大縮小機能に使われるリアクティブなゲッター
  chartState,
  // 拡大縮小機能に使われるリアクティブなセッター
  setChartState,
  chartZoom,
  chartZoomOut,
  recDateFormatter,
  isLoading,
}: ChartProps) {
  const { refAreaLeft, refAreaRight, left, right } = chartState

  return (
    <Box sx={{ width: '100%' }}>
      {isLoading ? (
        <Grid container alignItems="center" justifyContent="center" direction="column">
          <Grid item>
            <CircularProgress color="inherit" size={70} />
          </Grid>
        </Grid>
      ) : (
        <>
          <Button
            type="button"
            variant="contained"
            color="info"
            onClick={() => chartZoomOut(setChartState)}
            // グラフ拡大時のみ有効にする
            disabled={left === initialChartState.left && right === initialChartState.right}
            sx={{ marginBottom: 1 }}
          >
            グラフを元に戻す
          </Button>
          <ResponsiveContainer width="100%" height={chartDetail.height}>
            <ComposedChart
              data={list.slice()}
              onMouseDown={(e) => {
                // グラフの外側を押下するとeventがnullで渡ってしまうため。nullの場合を無視する
                if (e === null) return

                setChartState((state) => ({ ...state, refAreaLeft: e.activeLabel }))
              }}
              onMouseMove={(e) => refAreaLeft && setChartState((state) => ({ ...state, refAreaRight: e.activeLabel }))}
              // eslint-disable-next-line react/jsx-no-bind
              onMouseUp={() => chartZoom(chartState, setChartState)}
            >
              <XAxis
                allowDataOverflow
                dataKey={chartDetail.xAxis}
                // グラフ上でマウスで範囲選択時に取得した数値を元に表示範囲を指定する
                domain={[left as string, right as string]}
                type="number"
                tickFormatter={recDateFormatter}
              />
              <YAxis
                yAxisId="1"
                label={{
                  value: chartDetail.yAxisLabel ? chartDetail.yAxisLabel : '',
                  angle: 0,
                  dy: 20,
                  dx: -10,
                  position: 'insideTop',
                  fill: 'black',
                }}
              />
              {chartDetail.yAxisRight && <YAxis orientation="right" yAxisId={chartDetail.yAxisRight} />}
              <Tooltip
                labelFormatter={(value) => recDateFormatter(value)}
                itemStyle={{ color: 'black' }}
                formatter={(value, name) => {
                  if (name === ERROR_RATE_LABEL && typeof value === 'number') {
                    // 誤差率の値をパーセンテージ表記に変換
                    const percentageValue = `${(value * 100).toFixed()}%`
                    return [percentageValue, name]
                  }
                  if (name === STOCK_DAYS_LABEL && typeof value === 'number' && !Number.isInteger(value)) {
                    // 在庫日数を適切な桁数に変換
                    return [value.toFixed(1), name]
                  }
                  // 他の項目はそのまま表示
                  return [value, name]
                }}
              />
              <Legend
                iconSize={30}
                iconType="plainline"
                wrapperStyle={{ fontSize: mediumFontSize }}
                // 凡例のテキストカラーはformatterから修正ができる
                // eslint-disable-next-line arrow-body-style, react/no-unstable-nested-components
                formatter={(value, data: any) => {
                  return (
                    <span style={{ color: 'black' }}>{data.payload.style.visibility === 'visible' ? value : ''}</span>
                  )
                }}
              />
              <CartesianGrid stroke="#eee" strokeDasharray="3 3" />
              {chartDetail.line.map((value) => {
                if (value.chartType === 'StackedBar') {
                  return (
                    <Bar
                      key={value.name}
                      name={value.name}
                      dataKey={value.dataKey}
                      fill={value.fill}
                      yAxisId={value.yAxisId || '1'}
                      stackId="a"
                      animationDuration={300}
                    />
                  )
                }

                return (
                  <Line
                    key={value.name}
                    name={value.name}
                    type={value.type}
                    dataKey={value.dataKey}
                    stroke={value.stroke}
                    yAxisId={value.yAxisId || '1'}
                    animationDuration={300}
                    // 線の太さ
                    strokeWidth={3}
                    style={{ visibility: value.hide ? 'hidden' : 'visible' }}
                    // dashedLineか通常のlineかどうか
                    strokeDasharray={value.dashedLine ? '3 4' : undefined}
                    // グラフにドットを付与するかどうか
                    dot={value.dot ?? true}
                  />
                )
              })}
              {refAreaLeft && refAreaRight ? (
                <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
              ) : null}
            </ComposedChart>
          </ResponsiveContainer>
        </>
      )}
    </Box>
  )
}

export default Chart
