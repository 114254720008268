import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { protectedRoutes } from 'constants/routes'

import { Button, Container, Grid, Typography } from '@mui/material'
import { MessageInfo } from 'components/commonparts'
import ShowLastUpdateDatetime from 'components/commonparts/ShowLastUpdateDatetime/ShowLastUpdateDatetime'
import { DataList } from 'components/parts/CompanyList'
import { useCompany } from 'hooks/useCompany'
import { initDataListStatus } from 'types/types'

import type { CompanyOutput } from 'api-client'
import type { DataListStatus } from 'types/types'

function CompanyList() {
  const navigate = useNavigate()

  const { companySearch, fetchCompanySearch, compnaySearchIsLoading, companySearchResult } = useCompany()

  const [listStatus, setListStatus] = useState<DataListStatus>({
    ...initDataListStatus(),
  })

  // ソート順とページ番号の変更通知
  const onListStatusChange = (status: DataListStatus) => {
    fetchCompanySearch({
      limit: status.paginationModel.pageSize,
      pageNo: status.paginationModel.page + 1,
    })

    setListStatus({ ...listStatus, sortModel: status.sortModel, paginationModel: status.paginationModel })
  }

  useEffect(() => {
    fetchCompanySearch({
      limit: listStatus.paginationModel.pageSize,
      pageNo: 0,
    })
  }, [])

  return (
    <Container maxWidth="xl">
      <Typography component="div" variant="largeBold">
        ユーザー企業一覧
      </Typography>
      <Grid direction="row" container justifyContent="space-between">
        <Grid item marginTop={1} marginBottom={1}>
          <Button variant="contained" onClick={() => navigate(`/${protectedRoutes.CompanyDetail.path}`)}>
            企業を追加
          </Button>
        </Grid>
        <Grid item>
          <ShowLastUpdateDatetime<CompanyOutput>
            list={companySearch.list}
            lastUpdateDatetime={companySearch.lastUpdateDatetime}
          />
        </Grid>
      </Grid>
      <DataList
        list={companySearch}
        isLoading={compnaySearchIsLoading}
        listStatus={listStatus}
        onListStatusChange={onListStatusChange}
      />
      <Grid direction="row" container justifyContent="space-between">
        <Grid item>
          <MessageInfo result={companySearchResult} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default CompanyList
