import { useState } from 'react'

import { initRequestParam } from 'constants/constants'
import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'

import { useCurrentStatusStore } from '../store/currentStatusStore'

import type { GetInfomessageInfomessageGetRequest, InfoMessageList, ResponseResult } from 'api-client'
import type { ErrorResponse } from 'types/types'

export const useInfoMessage = () => {
  const [infomessage, setInfomessaeg] = useState<InfoMessageList>({
    datacount: 0,
    maxcount: 0,
    lastUpdateDatetime: null,
    list: [],
  })
  const [result, setResult] = useState<ResponseResult | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchInfomessage = (searchParam: GetInfomessageInfomessageGetRequest) => {
    setInfomessaeg(initRequestParam)
    setResult(undefined)
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getInfomessageInfomessageGet(searchParam)
      })
      .then((response) => {
        setInfomessaeg(response.data)
        setResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    infomessage,
    result,
    fetchInfomessage,
    isLoading,
  }
}
