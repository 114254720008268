/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShipPreviousStockFlg } from './ShipPreviousStockFlg';
import {
    ShipPreviousStockFlgFromJSON,
    ShipPreviousStockFlgFromJSONTyped,
    ShipPreviousStockFlgToJSON,
} from './ShipPreviousStockFlg';
import type { TodayShipRecDelayFlg } from './TodayShipRecDelayFlg';
import {
    TodayShipRecDelayFlgFromJSON,
    TodayShipRecDelayFlgFromJSONTyped,
    TodayShipRecDelayFlgToJSON,
} from './TodayShipRecDelayFlg';

/**
 * 
 * @export
 * @interface MstCenter
 */
export interface MstCenter {
    /**
     * 
     * @type {number}
     * @memberof MstCenter
     */
    centerId: number | null;
    /**
     * 
     * @type {number}
     * @memberof MstCenter
     */
    companyId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenter
     */
    orgCenterId: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenter
     */
    centerName: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenter
     */
    centerAddress: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableShipdayMon: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableShipdayTue: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableShipdayWed: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableShipdayThu: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableShipdayFri: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableShipdaySat: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableShipdaySun: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableArrivalMon: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableArrivalTue: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableArrivalWed: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableArrivalThu: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableArrivalFri: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableArrivalSat: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableArrivalSun: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableOrderdayMon: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableOrderdayTue: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableOrderdayWed: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableOrderdayThu: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableOrderdayFri: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableOrderdaySat: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    enableOrderdaySun: boolean | null;
    /**
     * 
     * @type {ShipPreviousStockFlg}
     * @memberof MstCenter
     */
    shipPreviousStockFlg: ShipPreviousStockFlg | null;
    /**
     * 
     * @type {TodayShipRecDelayFlg}
     * @memberof MstCenter
     */
    todayShipRecDelayFlg: TodayShipRecDelayFlg | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenter
     */
    centerGroupName: string | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenter
     */
    centerGln: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenter
     */
    insertDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenter
     */
    insertUser: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenter
     */
    updateDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenter
     */
    updateUser: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MstCenter
     */
    deleteDatetime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof MstCenter
     */
    deleteUser: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MstCenter
     */
    deleteFlg: boolean | null;
}

/**
 * Check if a given object implements the MstCenter interface.
 */
export function instanceOfMstCenter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "centerId" in value;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "orgCenterId" in value;
    isInstance = isInstance && "centerName" in value;
    isInstance = isInstance && "centerAddress" in value;
    isInstance = isInstance && "enableShipdayMon" in value;
    isInstance = isInstance && "enableShipdayTue" in value;
    isInstance = isInstance && "enableShipdayWed" in value;
    isInstance = isInstance && "enableShipdayThu" in value;
    isInstance = isInstance && "enableShipdayFri" in value;
    isInstance = isInstance && "enableShipdaySat" in value;
    isInstance = isInstance && "enableShipdaySun" in value;
    isInstance = isInstance && "enableArrivalMon" in value;
    isInstance = isInstance && "enableArrivalTue" in value;
    isInstance = isInstance && "enableArrivalWed" in value;
    isInstance = isInstance && "enableArrivalThu" in value;
    isInstance = isInstance && "enableArrivalFri" in value;
    isInstance = isInstance && "enableArrivalSat" in value;
    isInstance = isInstance && "enableArrivalSun" in value;
    isInstance = isInstance && "enableOrderdayMon" in value;
    isInstance = isInstance && "enableOrderdayTue" in value;
    isInstance = isInstance && "enableOrderdayWed" in value;
    isInstance = isInstance && "enableOrderdayThu" in value;
    isInstance = isInstance && "enableOrderdayFri" in value;
    isInstance = isInstance && "enableOrderdaySat" in value;
    isInstance = isInstance && "enableOrderdaySun" in value;
    isInstance = isInstance && "shipPreviousStockFlg" in value;
    isInstance = isInstance && "todayShipRecDelayFlg" in value;
    isInstance = isInstance && "centerGroupName" in value;
    isInstance = isInstance && "centerGln" in value;
    isInstance = isInstance && "insertDatetime" in value;
    isInstance = isInstance && "insertUser" in value;
    isInstance = isInstance && "updateDatetime" in value;
    isInstance = isInstance && "updateUser" in value;
    isInstance = isInstance && "deleteDatetime" in value;
    isInstance = isInstance && "deleteUser" in value;
    isInstance = isInstance && "deleteFlg" in value;

    return isInstance;
}

export function MstCenterFromJSON(json: any): MstCenter {
    return MstCenterFromJSONTyped(json, false);
}

export function MstCenterFromJSONTyped(json: any, ignoreDiscriminator: boolean): MstCenter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'centerId': json['center_id'],
        'companyId': json['company_id'],
        'orgCenterId': json['org_center_id'],
        'centerName': json['center_name'],
        'centerAddress': json['center_address'],
        'enableShipdayMon': json['enable_shipday_mon'],
        'enableShipdayTue': json['enable_shipday_tue'],
        'enableShipdayWed': json['enable_shipday_wed'],
        'enableShipdayThu': json['enable_shipday_thu'],
        'enableShipdayFri': json['enable_shipday_fri'],
        'enableShipdaySat': json['enable_shipday_sat'],
        'enableShipdaySun': json['enable_shipday_sun'],
        'enableArrivalMon': json['enable_arrival_mon'],
        'enableArrivalTue': json['enable_arrival_tue'],
        'enableArrivalWed': json['enable_arrival_wed'],
        'enableArrivalThu': json['enable_arrival_thu'],
        'enableArrivalFri': json['enable_arrival_fri'],
        'enableArrivalSat': json['enable_arrival_sat'],
        'enableArrivalSun': json['enable_arrival_sun'],
        'enableOrderdayMon': json['enable_orderday_mon'],
        'enableOrderdayTue': json['enable_orderday_tue'],
        'enableOrderdayWed': json['enable_orderday_wed'],
        'enableOrderdayThu': json['enable_orderday_thu'],
        'enableOrderdayFri': json['enable_orderday_fri'],
        'enableOrderdaySat': json['enable_orderday_sat'],
        'enableOrderdaySun': json['enable_orderday_sun'],
        'shipPreviousStockFlg': ShipPreviousStockFlgFromJSON(json['ship_previous_stock_flg']),
        'todayShipRecDelayFlg': TodayShipRecDelayFlgFromJSON(json['today_ship_rec_delay_flg']),
        'centerGroupName': json['center_group_name'],
        'centerGln': json['center_gln'],
        'insertDatetime': (json['insert_datetime'] === null ? null : new Date(json['insert_datetime'])),
        'insertUser': json['insert_user'],
        'updateDatetime': (json['update_datetime'] === null ? null : new Date(json['update_datetime'])),
        'updateUser': json['update_user'],
        'deleteDatetime': (json['delete_datetime'] === null ? null : new Date(json['delete_datetime'])),
        'deleteUser': json['delete_user'],
        'deleteFlg': json['delete_flg'],
    };
}

export function MstCenterToJSON(value?: MstCenter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'center_id': value.centerId,
        'company_id': value.companyId,
        'org_center_id': value.orgCenterId,
        'center_name': value.centerName,
        'center_address': value.centerAddress,
        'enable_shipday_mon': value.enableShipdayMon,
        'enable_shipday_tue': value.enableShipdayTue,
        'enable_shipday_wed': value.enableShipdayWed,
        'enable_shipday_thu': value.enableShipdayThu,
        'enable_shipday_fri': value.enableShipdayFri,
        'enable_shipday_sat': value.enableShipdaySat,
        'enable_shipday_sun': value.enableShipdaySun,
        'enable_arrival_mon': value.enableArrivalMon,
        'enable_arrival_tue': value.enableArrivalTue,
        'enable_arrival_wed': value.enableArrivalWed,
        'enable_arrival_thu': value.enableArrivalThu,
        'enable_arrival_fri': value.enableArrivalFri,
        'enable_arrival_sat': value.enableArrivalSat,
        'enable_arrival_sun': value.enableArrivalSun,
        'enable_orderday_mon': value.enableOrderdayMon,
        'enable_orderday_tue': value.enableOrderdayTue,
        'enable_orderday_wed': value.enableOrderdayWed,
        'enable_orderday_thu': value.enableOrderdayThu,
        'enable_orderday_fri': value.enableOrderdayFri,
        'enable_orderday_sat': value.enableOrderdaySat,
        'enable_orderday_sun': value.enableOrderdaySun,
        'ship_previous_stock_flg': ShipPreviousStockFlgToJSON(value.shipPreviousStockFlg),
        'today_ship_rec_delay_flg': TodayShipRecDelayFlgToJSON(value.todayShipRecDelayFlg),
        'center_group_name': value.centerGroupName,
        'center_gln': value.centerGln,
        'insert_datetime': (value.insertDatetime === null ? null : value.insertDatetime.toISOString()),
        'insert_user': value.insertUser,
        'update_datetime': (value.updateDatetime === null ? null : value.updateDatetime.toISOString()),
        'update_user': value.updateUser,
        'delete_datetime': (value.deleteDatetime === null ? null : value.deleteDatetime.toISOString()),
        'delete_user': value.deleteUser,
        'delete_flg': value.deleteFlg,
    };
}

