/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 出荷数量12
 * @export
 * @interface Col12
 */
export interface Col12 {
}

/**
 * Check if a given object implements the Col12 interface.
 */
export function instanceOfCol12(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function Col12FromJSON(json: any): Col12 {
    return Col12FromJSONTyped(json, false);
}

export function Col12FromJSONTyped(json: any, ignoreDiscriminator: boolean): Col12 {
    return json;
}

export function Col12ToJSON(value?: Col12 | null): any {
    return value;
}

