/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrentStatus } from './CurrentStatus';
import {
    CurrentStatusFromJSON,
    CurrentStatusFromJSONTyped,
    CurrentStatusToJSON,
} from './CurrentStatus';

/**
 * レスポンスに設定するシステム情報
 * @export
 * @interface SystemInfo
 */
export interface SystemInfo {
    /**
     * 
     * @type {Date}
     * @memberof SystemInfo
     */
    systemDate: Date | null;
    /**
     * 
     * @type {Array<CurrentStatus>}
     * @memberof SystemInfo
     */
    sysCurrentStatus: Array<CurrentStatus>;
    /**
     * 
     * @type {number}
     * @memberof SystemInfo
     */
    readonly sysProcessingCount: number;
    /**
     * 
     * @type {string}
     * @memberof SystemInfo
     */
    readonly companyStatus: string;
    /**
     * 
     * @type {string}
     * @memberof SystemInfo
     */
    readonly systemStatus: string;
}

/**
 * Check if a given object implements the SystemInfo interface.
 */
export function instanceOfSystemInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "systemDate" in value;
    isInstance = isInstance && "sysCurrentStatus" in value;
    isInstance = isInstance && "sysProcessingCount" in value;
    isInstance = isInstance && "companyStatus" in value;
    isInstance = isInstance && "systemStatus" in value;

    return isInstance;
}

export function SystemInfoFromJSON(json: any): SystemInfo {
    return SystemInfoFromJSONTyped(json, false);
}

export function SystemInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SystemInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'systemDate': (json['system_date'] === null ? null : new Date(json['system_date'])),
        'sysCurrentStatus': ((json['sys_current_status'] as Array<any>).map(CurrentStatusFromJSON)),
        'sysProcessingCount': json['sys_processing_count'],
        'companyStatus': json['company_status'],
        'systemStatus': json['system_status'],
    };
}

export function SystemInfoToJSON(value?: SystemInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'system_date': (value.systemDate === null ? null : value.systemDate.toISOString().substring(0,10)),
        'sys_current_status': ((value.sysCurrentStatus as Array<any>).map(CurrentStatusToJSON)),
    };
}

