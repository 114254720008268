import React from 'react'

import { Button } from '@mui/material'

interface CanselButtonProps {
  onClick: () => void
  caption?: string
  disabled?: boolean
}

function CanselButton({ onClick, caption = 'キャンセル', disabled }: CanselButtonProps) {
  return (
    <Button variant="contained" color="secondary" onClick={onClick} disabled={disabled}>
      {caption}
    </Button>
  )
}

export default CanselButton
