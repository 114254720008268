import React from 'react'
import { Link } from 'react-router-dom'

import { protectedRoutes } from 'constants/routes'

import { type GridColDef } from '@mui/x-data-grid'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import clsx from 'clsx'
import ShowLastUpdateDatetime from 'components/commonparts/ShowLastUpdateDatetime/ShowLastUpdateDatetime'

import type { GridCellParams, GridRenderCellParams } from '@mui/x-data-grid-premium'
import type { OrderAlert, OrderAlertList } from 'api-client'
import type { DataListStatus } from 'types/types'

import './order-alert-data-list.scss'

interface DataListProps {
  list: OrderAlertList
  isLoading: boolean
  listStatus: DataListStatus
  onListStatusChange: (status: DataListStatus) => void
}

function OrderAlertDataList({ list, isLoading, listStatus, onListStatusChange }: DataListProps) {
  const columns: GridColDef[] = [
    {
      headerName: '仕入先名',
      field: 'supplierName',
      type: 'string',
      minWidth: 368,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          className="text-blue-400 underline"
          style={{ textDecoration: 'none' }}
          // 倉庫別発注商品一覧リンク
          to={`/${protectedRoutes.StockCenter.path}?company_id=${params.row.companyId}&center_id=${params.row.centerId}&center_id=${params.row.centerId}&org_supplier_id=${params.row.orgSupplierId}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      headerName: '締め時間',
      field: 'orderLimitTime',
      type: 'string',
      minWidth: 100,
    },
    {
      headerName: '発注商品数',
      field: 'todayOrderCount',
      minWidth: 130,
    },
    {
      headerName: '在庫引当アラート',
      field: 'stockAlertCount',
      minWidth: 170,
      cellClassName: (params: GridCellParams<OrderAlert, number>) =>
        clsx({
          'alert-cell': params?.value && params?.value >= 0,
        }),
    },
  ]

  return (
    <>
      <ShowLastUpdateDatetime<OrderAlert> list={list.list} lastUpdateDatetime={list.lastUpdateDatetime} />
      <DataGridPremium
        rows={list.list}
        rowCount={list.maxcount as number}
        columns={columns}
        initialState={{
          pagination: { paginationModel: listStatus.paginationModel },
        }}
        disableRowSelectionOnClick
        paginationMode="server"
        filterMode="server"
        loading={isLoading}
        getRowId={(row) => row.orderDate + row.orgSupplierId + row.supplierName}
        sortModel={listStatus.sortModel}
        onSortModelChange={(state) => {
          onListStatusChange({ sortModel: state, paginationModel: listStatus.paginationModel })
        }}
        pagination
        paginationModel={listStatus.paginationModel}
        onPaginationModelChange={(pgState) => {
          onListStatusChange({ sortModel: listStatus.sortModel, paginationModel: pgState })
        }}
        density="compact"
      />
    </>
  )
}

export default OrderAlertDataList
