import { useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type { GetMstCentersCenterGetRequest, MstCenter, ResponseResult } from 'api-client'
import type { ErrorResponse } from 'types/types'

export interface AutocompleteCenter {
  label: string | null
  centerId: number | null
}

export const useCenter = () => {
  const [centers, setCenters] = useState<MstCenter[]>()
  const [autocompletCenters, setAutocompleteCenters] = useState<AutocompleteCenter[]>([])
  const [result, setResult] = useState<ResponseResult | undefined>(undefined)

  const [isLoading, setIsLoading] = useState(false)

  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchCenters = (searchParam: GetMstCentersCenterGetRequest) => {
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getMstCentersCenterGet(searchParam)
      })
      .then((response) => {
        setCenters(response.data.list)

        // SelectCenterコンポーネントのデータバインド形式に合わせる
        const centersMap = response.data.list.map((value) => ({
          label: value.centerName,
          centerId: value.centerId,
        }))
        setAutocompleteCenters(centersMap)
        setResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    centers,
    autocompletCenters,
    result,
    fetchCenters,
    isLoading,
  }
}
