import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const UploadRecordTab = {
  Order: 'order',
  Ship: 'ship',
} as const

interface UploadRecordTabStore {
  uploadRecordTabState: (typeof UploadRecordTab)[keyof typeof UploadRecordTab]
  setUploadRecordTabsState: (trTab: (typeof UploadRecordTab)[keyof typeof UploadRecordTab]) => void
}

export const useUploadRecordTabStore = create<UploadRecordTabStore>()(
  persist(
    (set) => ({
      uploadRecordTabState: UploadRecordTab.Order,
      setUploadRecordTabsState: (trTab) => {
        set(() => ({ uploadRecordTabState: trTab }))
      },
    }),
    {
      name: 'upload-record-tab',
    }
  )
)
