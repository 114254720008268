import { useState } from 'react'

import { initRequestParam } from 'constants/constants'
import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import _ from 'lodash'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type { GetMonthReportMonthReportGetRequest, MonthReports, ResponseResult } from 'api-client'
import type { ErrorResponse } from 'types/types'

export const useMonthReport = () => {
  const [monthReport, setMonthReport] = useState<MonthReports>(initRequestParam)
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState<ResponseResult | undefined>(undefined)
  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchMonthReport = (searchParam: GetMonthReportMonthReportGetRequest) => {
    setMonthReport(initRequestParam)
    setResult(undefined)
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getMonthReportMonthReportGet({
          ...searchParam,
          centerId: searchParam.centerId || undefined,
          itemCode: searchParam.itemCode || undefined,
        })
      })
      .then((response) => {
        setMonthReport({
          ...response.data,
          list: _.map(response.data.list, (value) => {
            const recDate = new Date(value.recDate as Date)
            return {
              ...value,
              // // rechartsライブラリのdataKeyに合わせてstringにする
              recDateToNumber: recDate.getTime(),
            }
          }),
        })
        setResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    result,
    monthReport,
    fetchMonthReport,
    isLoading,
  }
}
