import React from 'react'

import { Box, Link, Typography } from '@mui/material'

function Faq() {
  const REACT_APP_PDF_MANUAL = "https://wsk-product-dev.web.app/movopsi_manual.pdf";
  return (
    <Box>
      <Typography component="div" variant="largeBold">
        操作マニュアル
      </Typography>
        <Typography sx={{ paddingTop: 5, paddingLeft: 6}}
         component="body" variant='largeBold'>
          <Link
            href={REACT_APP_PDF_MANUAL}
            target="_blank" 
            rel="noopener noreferrer" 
            sx={{ color: '#1a73e8', 
              textDecoration: 'underline', 
              textDecorationColor: '#1a73e8',
              fontWeight: 'bold' }}
          >
            操作マニュアルの表示 (別タブで開きます)
          </Link>
        </Typography>
    </Box>
  )
}

export default Faq
