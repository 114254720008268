import React, { useEffect, useState } from 'react'

import { Replay } from '@mui/icons-material'
import { Box, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { useInfoMessage } from 'hooks/useInfoMessage'
import _ from 'lodash'
import { convertFromDateTime } from 'utils/convertFromLocalDate'
import { getAPI } from 'utils/getAPI'

import AppSnackbar from '../AppSnackbar/AppSnackbar'
import ActionButton from '../Buttons/ActionButton'
import SelectCenter from '../SelectCenter/SelectCenter'

function OptimizeRequest() {
  const { loginUserInfo } = useAuthContext()
  const { infomessage, fetchInfomessage, isLoading: infomessageIsLoading } = useInfoMessage()

  const [centerIsValid, setCenterIsValid] = useState(true)
  const [centerId, setCenterId] = useState<number | null | undefined>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')

  const onInfomessage = () => {
    fetchInfomessage({ companyId: loginUserInfo?.companyId, target: 'exec_optimize', limit: 10 })
  }

  // info_type="last_update"に該当する、一番新しい日付のinfo_timeを表示
  const lastUpdate = () => {
    const lastUpdateArray = _.filter(infomessage.list, (value) => value.infoType === 'last_update')
    return convertFromDateTime(_.first(lastUpdateArray)?.infoTime)
  }

  const execRequest = async () => {
    setIsLoading(true)
    const api = await getAPI()
    if (!api) return

    try {
      const response = await api.execOptimizeExecOptimizeGet({
        companyId: loginUserInfo?.companyId,
        centerId: centerId || undefined,
      })

      if (response.result.status === 'error') {
        setSnackBarMessage(response.result.message || 'エラーが発生しました')
        setSnackBarOpen(true)
      }
    } catch (e) {
      // 必要ならエラー処理
    } finally {
      setIsLoading(false)
      onInfomessage()
    }
  }

  const onSnackbarClose = () => {
    setSnackBarOpen(false)
  }

  useEffect(() => {
    onInfomessage()
  }, [])

  return (
    <Paper variant="outlined">
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        <Typography color="royalblue">
          最終更新日:
          {infomessage.list.length > 0 && lastUpdate()}
        </Typography>
        {/* 更新ボタン */}
        <IconButton onClick={onInfomessage}>
          <Replay />
        </IconButton>
      </Box>
      <Typography component="div" variant="mediumBold">
        発注推奨数算出処理実行
      </Typography>
      <Grid container alignItems="flex-end">
        <SelectCenter
          trCompanyId={loginUserInfo?.companyId}
          centerId={centerId}
          setCenterId={setCenterId}
          setCenterInfo={() => {}}
          isRequired={false}
          setIsValid={setCenterIsValid}
        />
        <Box sx={{ marginBottom: '4px' }}>
          <ActionButton onClick={execRequest} caption="発注推奨数算出" isLoading={isLoading} />
        </Box>
      </Grid>
      <Typography component="div" variant="medium" sx={{ marginTop: 1 }}>
        操作履歴
      </Typography>
      {infomessageIsLoading ? (
        <CircularProgress size={40} color="primary" />
      ) : (
        <>
          {/* 3件表示する */}
          {_.take(infomessage.list, 3).map((value, key) => {
            let color
            if (value.infoType === 'error') {
              color = 'firebrick'
            } else if (value.infoType === 'warning') {
              color = 'darkorange'
            } else {
              color = 'black'
            }

            return (
              // eslint-disable-next-line react/no-array-index-key
              <Typography key={key} color={color} sx={{ marginTop: 1 }}>
                {value.message
                  ? `${convertFromDateTime(value.infoTime)} ${value.message}`
                  : `${convertFromDateTime(value.infoTime)} ${value.infoTypeName}`}
              </Typography>
            )
          })}
        </>
      )}
      <AppSnackbar message={snackBarMessage} open={snackBarOpen} onClose={onSnackbarClose} severity="error" />
    </Paper>
  )
}

export default OptimizeRequest
