/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InfoMessage
 */
export interface InfoMessage {
    /**
     * 
     * @type {string}
     * @memberof InfoMessage
     */
    processType: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfoMessage
     */
    target: string | null;
    /**
     * 
     * @type {Date}
     * @memberof InfoMessage
     */
    infoTime: Date | null;
    /**
     * 
     * @type {string}
     * @memberof InfoMessage
     */
    infoType: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfoMessage
     */
    message: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfoMessage
     */
    processTypeName: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfoMessage
     */
    targetName: string | null;
    /**
     * 
     * @type {string}
     * @memberof InfoMessage
     */
    infoTypeName: string | null;
}

/**
 * Check if a given object implements the InfoMessage interface.
 */
export function instanceOfInfoMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "processType" in value;
    isInstance = isInstance && "target" in value;
    isInstance = isInstance && "infoTime" in value;
    isInstance = isInstance && "infoType" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "processTypeName" in value;
    isInstance = isInstance && "targetName" in value;
    isInstance = isInstance && "infoTypeName" in value;

    return isInstance;
}

export function InfoMessageFromJSON(json: any): InfoMessage {
    return InfoMessageFromJSONTyped(json, false);
}

export function InfoMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): InfoMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'processType': json['process_type'],
        'target': json['target'],
        'infoTime': (json['info_time'] === null ? null : new Date(json['info_time'])),
        'infoType': json['info_type'],
        'message': json['message'],
        'processTypeName': json['process_type_name'],
        'targetName': json['target_name'],
        'infoTypeName': json['info_type_name'],
    };
}

export function InfoMessageToJSON(value?: InfoMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'process_type': value.processType,
        'target': value.target,
        'info_time': (value.infoTime === null ? null : value.infoTime.toISOString()),
        'info_type': value.infoType,
        'message': value.message,
        'process_type_name': value.processTypeName,
        'target_name': value.targetName,
        'info_type_name': value.infoTypeName,
    };
}

