import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import type { CurrentStatus } from 'api-client'

interface CurrentStatusStore {
  currentStatuses: CurrentStatus[]
  setCurrentStatuses: (currentStatuses: CurrentStatus[] | undefined) => void
  hasCurrentStatuses: () => boolean
}

export const useCurrentStatusStore = create<CurrentStatusStore>()(
  persist(
    (set, get) => ({
      currentStatuses: [],
      hasCurrentStatuses: () => get().currentStatuses.length > 0,
      setCurrentStatuses: (currentStatuses: CurrentStatus[] | undefined) =>
        set((state) => {
          if (currentStatuses) {
            return { currentStatuses }
          }
          return {
            currentStatuses: state.currentStatuses,
          }
        }),
    }),
    {
      version: 1,
      name: 'current-status',
    }
  )
)
