import type { PropsWithChildren } from 'react'
import React from 'react'

import { Grid, Paper } from '@mui/material'

import ClearButton from '../Buttons/ClearButton'
import DownloadCsvButton from '../Buttons/DownloadCsvButton'
import DownloadExcelButton from '../Buttons/DownloadExcelButton'
import SearchButton from '../Buttons/SearchButton'
import MessageInfo from '../MessageInfo/MessageInfo'

import type { ResponseResult } from 'api-client'

type SearchFromProps = {
  searchId?: string
  onClear: () => void
  onCsvDownLoad?: () => void
  onExcelDownLoad?: () => void
  isDownload?: boolean
  isCsvLoading?: boolean
  isXlsxLoading?: boolean
  result?: ResponseResult | undefined
}

function SearchFrom({
  searchId = 'page',
  onClear,
  onCsvDownLoad,
  onExcelDownLoad,
  isDownload = false,
  isCsvLoading,
  isXlsxLoading,
  result,
  children,
}: PropsWithChildren<SearchFromProps>) {
  return (
    <Paper variant="outlined">
      <Grid item container>
        <Grid item xs={12} container>
          {children}
        </Grid>
        <Grid item xs={12} justifyContent="space-between" alignItems="center" container sx={{ marginTop: '0.5rem' }}>
          <Grid item>
            <MessageInfo result={result} />
          </Grid>

          <Grid item>
            <SearchButton searchId={searchId} />
            <ClearButton onClick={onClear} />
            {isDownload && (
              <>
                <DownloadCsvButton onClick={onCsvDownLoad} isLoading={isCsvLoading} />
                <DownloadExcelButton onClick={onExcelDownLoad} isLoading={isXlsxLoading} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SearchFrom
