import React from 'react'
import { useNavigate } from 'react-router-dom'

import { protectedRoutes } from 'constants/routes'

import { Alert, Box, Grid } from '@mui/material'
import { ActionButton } from 'components/commonparts'

interface Props {
  message: string | null
  tenantId: string | null
}

function ActionFinishedPanel({ message, tenantId }: Props) {
  const navigate = useNavigate()

  return (
    <Box>
      <Alert severity="success">{message}</Alert>
      <Grid container justifyContent="center" alignItems="center" sx={{ marginTop: '1rem' }}>
        <ActionButton
          caption="ログインページへ"
          onClick={() => {
            navigate(`/${protectedRoutes.Login.path}?tenantId=${tenantId}`)
          }}
        />
      </Grid>
    </Box>
  )
}

export default ActionFinishedPanel
