/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MstUserOutput } from './MstUserOutput';
import {
    MstUserOutputFromJSON,
    MstUserOutputFromJSONTyped,
    MstUserOutputToJSON,
} from './MstUserOutput';
import type { ResponseResult } from './ResponseResult';
import {
    ResponseResultFromJSON,
    ResponseResultFromJSONTyped,
    ResponseResultToJSON,
} from './ResponseResult';
import type { ResponseUpdateError } from './ResponseUpdateError';
import {
    ResponseUpdateErrorFromJSON,
    ResponseUpdateErrorFromJSONTyped,
    ResponseUpdateErrorToJSON,
} from './ResponseUpdateError';

/**
 * 
 * @export
 * @interface ResponseUserUpdate
 */
export interface ResponseUserUpdate {
    /**
     * 
     * @type {ResponseResult}
     * @memberof ResponseUserUpdate
     */
    result: ResponseResult;
    /**
     * 
     * @type {Array<ResponseUpdateError>}
     * @memberof ResponseUserUpdate
     */
    errors: Array<ResponseUpdateError>;
    /**
     * 
     * @type {number}
     * @memberof ResponseUserUpdate
     */
    updateCount: number | null;
    /**
     * 
     * @type {MstUserOutput}
     * @memberof ResponseUserUpdate
     */
    data: MstUserOutput | null;
}

/**
 * Check if a given object implements the ResponseUserUpdate interface.
 */
export function instanceOfResponseUserUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "errors" in value;
    isInstance = isInstance && "updateCount" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ResponseUserUpdateFromJSON(json: any): ResponseUserUpdate {
    return ResponseUserUpdateFromJSONTyped(json, false);
}

export function ResponseUserUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseUserUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ResponseResultFromJSON(json['result']),
        'errors': ((json['errors'] as Array<any>).map(ResponseUpdateErrorFromJSON)),
        'updateCount': json['update_count'],
        'data': MstUserOutputFromJSON(json['data']),
    };
}

export function ResponseUserUpdateToJSON(value?: ResponseUserUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ResponseResultToJSON(value.result),
        'errors': ((value.errors as Array<any>).map(ResponseUpdateErrorToJSON)),
        'update_count': value.updateCount,
        'data': MstUserOutputToJSON(value.data),
    };
}

