import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { AuthType } from 'constants/constants'

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { MessageInfo } from 'components/commonparts'
import { TypeGroup } from 'components/commonparts/SelectTypeCode/SelectTypeCode'
import { useUser } from 'hooks/useUser'
import _ from 'lodash'

import type { Groups, ResponseResult } from 'api-client'

function UserDetail() {
  const { loginUserInfo, typeCode } = useAuthContext()

  const {
    userSearch,
    fetchUserSearch,
    userSearchResult,
    userSearchIsLoading,
    fetchUserCreate,
    userCreateResult,
    userCreateIsLoading,
    fetchUserUpdate,
    userUpdateResult,
    userUpdateIsLoading,
  } = useUser()

  const [userId, setUserId] = useState<string | undefined>('')
  const [userMail, setUserMail] = useState<string | undefined>('')
  const [userName, setUserName] = useState<string | undefined>('')
  const [authType, setAuthType] = useState<string | undefined | null>(AuthType.General)
  const [enabled, setEnabled] = useState(true)
  const [trTypecode, setTrTypecode] = useState<Groups | undefined>(undefined)
  const [result, setResult] = useState<ResponseResult | undefined>(undefined)

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  // クエリパラメーターを取得
  const trQueryParam = {
    companyId: Number(query.get('company_id')),
    userId: query.get('user_id'),
  }

  const onUserSearch = () => {
    fetchUserSearch({ companyId: trQueryParam.companyId, userId: trQueryParam.userId })
  }

  const onUserCreate = () => {
    if (loginUserInfo?.userId && userMail && userName) {
      fetchUserCreate({
        mstUserInput: {
          companyId: trQueryParam.companyId,
          userId: loginUserInfo.userId,
          userMail,
          userName,
          authType,
          enabled,
        },
      })
    }
  }

  const onUserUpdate = () => {
    if (userId && userMail && userName) {
      fetchUserUpdate({
        mstUserInput: {
          companyId: trQueryParam.companyId,
          userId,
          userMail,
          userName,
          authType,
          enabled,
        },
      })
    }
  }

  useEffect(() => {
    // クエリパラメータにユーザーIDが含まれている場合は/user/search APIを呼ぶ
    if (trQueryParam.companyId && trQueryParam.userId) {
      onUserSearch()
    }
  }, [trQueryParam.userId])

  // 初期入力設定
  useEffect(() => {
    if (userSearch.list.length > 0) {
      const user = _.find(userSearch.list)

      setUserId(user?.userId)
      setUserMail(user?.userMail)
      setUserName(user?.userName)
      setAuthType(user?.authType)
      setEnabled(!!user?.enabled)
    }
  }, [userSearch])

  useEffect(() => {
    // 権限区分の設定
    if (typeCode.length > 0) {
      const targetTypeCode = _.find(typeCode, { typeGroup: TypeGroup.AuthType })
      setTrTypecode(targetTypeCode)
    }
  }, [typeCode])

  // 新規登録後に一覧画面遷移
  useEffect(() => {
    if (userCreateResult?.result.status === 'success') window.history.back()
  }, [userCreateResult])
  useEffect(() => {
    if (userUpdateResult?.result.status === 'success') window.history.back()
  }, [userUpdateResult])

  // メッセージ群
  useEffect(() => {
    setResult(userSearchResult)
  }, [userSearchResult])
  useEffect(() => {
    setResult(userCreateResult?.result)
  }, [userCreateResult])
  useEffect(() => {
    setResult(userUpdateResult?.result)
  }, [userUpdateResult])

  return (
    <Container maxWidth="xl">
      <Typography component="div" variant="largeBold">
        ユーザー情報{trQueryParam.userId ? '更新' : '追加'}
      </Typography>
      <Container maxWidth="md">
        <Box sx={{ border: 1, borderColor: 'grey.500', padding: 1 }} width="75%">
          <Grid container>
            <Grid container justifyContent="center" alignContent="center">
              {userSearchIsLoading && (
                <CircularProgress size={100} color="primary" sx={{ zIndex: 1, position: 'absolute' }} />
              )}
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
              <Grid item md={4} sx={{ marginTop: 0 }}>
                <Typography variant="medium">メールアドレス</Typography>
              </Grid>
              <Grid item md={6}>
                <TextField value={userMail ?? ''} onChange={(e) => setUserMail(e.target.value)} sx={{ width: 400 }} />
              </Grid>
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
              <Grid item md={4} sx={{ marginTop: 0 }}>
                <Typography variant="medium">ユーザー名</Typography>
              </Grid>
              <Grid item md={6}>
                <TextField value={userName ?? ''} onChange={(e) => setUserName(e.target.value)} sx={{ width: 400 }} />
              </Grid>
            </Grid>
            {/* ログインユーザーのauth_type=9の場合のみ表示 */}
            {loginUserInfo?.authType === AuthType.Admin && (
              <>
                <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
                  <Grid item md={4} sx={{ marginTop: 0 }}>
                    <Typography variant="medium">権限区分</Typography>
                  </Grid>

                  {trTypecode ? (
                    <Grid item md={6}>
                      <Select
                        defaultValue={authType}
                        value={authType}
                        onChange={(e) => {
                          setAuthType(e.target.value)
                        }}
                      >
                        {trTypecode.codes.map((value) => (
                          <MenuItem key={value.sortOrder} value={value.typeCode}>
                            {value.typeName}
                          </MenuItem>
                        ))}
                      </Select>
                    </Grid>
                  ) : (
                    <div />
                  )}
                </Grid>
                <Grid container direction="row" alignItems="center" justifyContent="start">
                  <Grid item md={4} sx={{ marginTop: 0 }}>
                    <Typography variant="medium">有効</Typography>
                  </Grid>
                  <Grid item md={6}>
                    <Checkbox
                      checked={enabled}
                      onChange={(e) => {
                        setEnabled(e.target.checked)
                      }}
                      color="success"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
        <Grid direction="row" container justifyContent="space-between" width="75%" paddingTop={1}>
          <Grid item>
            <MessageInfo result={result} />
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={() => window.history.back()}>
              戻る
            </Button>
            <Button variant="contained" onClick={trQueryParam.userId ? onUserUpdate : onUserCreate}>
              {trQueryParam.userId ? '更新' : '追加'}
              {(userCreateIsLoading || userUpdateIsLoading) && <CircularProgress size={24} color="primary" />}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}

export default UserDetail
