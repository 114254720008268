/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 月次集計
 * @export
 * @interface MonthReport
 */
export interface MonthReport {
    /**
     * 
     * @type {number}
     * @memberof MonthReport
     */
    companyId: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthReport
     */
    centerId: number | null;
    /**
     * 
     * @type {string}
     * @memberof MonthReport
     */
    itemCode: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MonthReport
     */
    recDate: Date | null;
    /**
     * 
     * @type {number}
     * @memberof MonthReport
     */
    stockoutRate: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthReport
     */
    stockoutExpectedRate: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthReport
     */
    stockDays: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthReport
     */
    stockDaysExpected: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthReport
     */
    stockDaysRank: number | null;
    /**
     * 
     * @type {number}
     * @memberof MonthReport
     */
    safetyStockRank: number | null;
}

/**
 * Check if a given object implements the MonthReport interface.
 */
export function instanceOfMonthReport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "centerId" in value;
    isInstance = isInstance && "itemCode" in value;
    isInstance = isInstance && "recDate" in value;
    isInstance = isInstance && "stockoutRate" in value;
    isInstance = isInstance && "stockoutExpectedRate" in value;
    isInstance = isInstance && "stockDays" in value;
    isInstance = isInstance && "stockDaysExpected" in value;
    isInstance = isInstance && "stockDaysRank" in value;
    isInstance = isInstance && "safetyStockRank" in value;

    return isInstance;
}

export function MonthReportFromJSON(json: any): MonthReport {
    return MonthReportFromJSONTyped(json, false);
}

export function MonthReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): MonthReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'centerId': json['center_id'],
        'itemCode': json['item_code'],
        'recDate': (json['rec_date'] === null ? null : new Date(json['rec_date'])),
        'stockoutRate': json['stockout_rate'],
        'stockoutExpectedRate': json['stockout_expected_rate'],
        'stockDays': json['stock_days'],
        'stockDaysExpected': json['stock_days_expected'],
        'stockDaysRank': json['stock_days_rank'],
        'safetyStockRank': json['safety_stock_rank'],
    };
}

export function MonthReportToJSON(value?: MonthReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'center_id': value.centerId,
        'item_code': value.itemCode,
        'rec_date': (value.recDate === null ? null : value.recDate.toISOString()),
        'stockout_rate': value.stockoutRate,
        'stockout_expected_rate': value.stockoutExpectedRate,
        'stock_days': value.stockDays,
        'stock_days_expected': value.stockDaysExpected,
        'stock_days_rank': value.stockDaysRank,
        'safety_stock_rank': value.safetyStockRank,
    };
}

