import { useState } from 'react'

import { initRequestParamWithDaylist } from 'constants/constants'
import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import _ from 'lodash'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type { GetShipExpectedShipExpectedGetRequest, ResponseResult, ShipExpecteds } from 'api-client'
import type { ErrorResponse } from 'types/types'

export const useShipExpected = () => {
  const [shipExpected, setShipExpected] = useState<ShipExpecteds>(initRequestParamWithDaylist)
  const [isLoading, setIsLoading] = useState(false)
  const [result, setResult] = useState<ResponseResult | undefined>(undefined)
  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchShipExpected = (searchParam: GetShipExpectedShipExpectedGetRequest) => {
    setShipExpected(initRequestParamWithDaylist)
    setResult(undefined)
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getShipExpectedShipExpectedGet({
          companyId: searchParam.companyId,
          centerId: searchParam.centerId || undefined,
          viewDateType: searchParam.viewDateType || undefined,
          viewUnitType: searchParam.viewUnitType || undefined,
          itemCode: searchParam.itemCode || undefined,
        })
      })
      .then((response) => {
        // MUI DataGridのTreeData形式に対応するため、listに親のtreepassフィールドを追加する
        setShipExpected({
          ...response.data,
          list: _.map(response.data.list, (value) => ({
            ...value,
            // TreeData形式に必要なユニークID
            id: `${value.itemCode}${value.centerId}`,
            // TreeData形式を有効にするデータパス
            ///  倉庫別出荷予測
            treepassItem: [`${value.itemName} (ID：${value.orgItemCode})`],
            /// 商品別出荷予測
            treepassCenter: [`${value.centerName} (ID：${value.centerId})`],
            // TreeDataにおいて親であるかないかのフラグ
            rootpass: true,
            systemDate: response.result.systemDate,
          })),
        })
        setResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    result,
    shipExpected,
    fetchShipExpected,
    isLoading,
  }
}
