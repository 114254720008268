import React from 'react'

import ExpectedRankTooltip from 'components/commonparts/Tooltip/ExpectedRankTooltip'

function ExpectedRankHeader() {
  return (
    <div className="rating-header">
      <div>予測精度</div>
      <ExpectedRankTooltip />
    </div>
  )
}

export default ExpectedRankHeader
