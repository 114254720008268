import type { Dispatch, FormEvent, SetStateAction } from 'react'
import React, { useState } from 'react'

import { Box } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { SearchCustomer, SearchFrom, SelectCenter, SelectTypeCode } from 'components/commonparts'
import { ItemSearch } from 'components/dialog'

import type { GetStockItemTreeStockItemTreeGetRequest, MstItem, ResponseResult } from 'api-client'

interface SearchConditionProps {
  searchParam: GetStockItemTreeStockItemTreeGetRequest
  onSearch: (requestParam: GetStockItemTreeStockItemTreeGetRequest, e: FormEvent<HTMLFormElement>) => void
  setItemInfo: Dispatch<SetStateAction<MstItem | undefined>>
  result?: ResponseResult | undefined
}

function SearchCondition({ searchParam, onSearch, setItemInfo, result }: SearchConditionProps) {
  const { loginUserInfo } = useAuthContext()

  const [centerIsValid, setCenterIsValid] = useState(true)
  const [itemIsValid, setItemIsValid] = useState(true)

  const [centerId, setCenterId] = useState<number | null | undefined>(searchParam.centerId)
  const [customerCompanyId, setCustomerCompanyId] = useState<number | undefined>(
    // companyIdがログインユーザーの企業IDの場合はundefinedにする
    searchParam.companyId === loginUserInfo!.companyId ? undefined : searchParam.companyId
  )
  const [itemCode, setItemCode] = useState<string | null | undefined>(searchParam.itemCode)
  const [optimizeRangeCode, setOptimizeRangeCode] = useState(searchParam.optimizeRange)

  const onClear = () => {
    setCustomerCompanyId(undefined)
    setCenterId(undefined)
    setItemCode('')
    setItemInfo(undefined)
  }

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault()

        // 必須入力チェック
        if (!centerIsValid && !itemIsValid) {
          onSearch(
            {
              companyId: customerCompanyId ?? loginUserInfo!.companyId,
              centerId,
              itemCode,
              optimizeRange: optimizeRangeCode,
            },
            e
          )
        }
      }}
    >
      <SearchFrom onClear={onClear} result={result}>
        <SearchCustomer
          title="会社"
          trCompanyId={loginUserInfo!.companyId}
          customerCompanyId={customerCompanyId}
          setCustomerCompanyId={setCustomerCompanyId}
          setCustomerCompanyInfo={() => {}}
          isRequred={false}
          setIsValid={() => {}}
        />
        <SelectCenter
          trCompanyId={customerCompanyId}
          centerId={centerId}
          setCenterId={setCenterId}
          setCenterInfo={() => {}}
          isRequired
          setIsValid={setCenterIsValid}
        />
        <ItemSearch
          trCompanyId={customerCompanyId}
          itemCode={itemCode}
          setItemCode={setItemCode}
          setItemInfo={setItemInfo}
          isRequred
          setIsValid={setItemIsValid}
        />
        <SelectTypeCode
          typeGroup="OptimizeRange"
          typeCode={optimizeRangeCode}
          setTypeCode={setOptimizeRangeCode}
          setTypeCodeInfo={() => {}}
        />
      </SearchFrom>
    </Box>
  )
}

export default SearchCondition
