import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { protectedRoutes } from 'constants/routes'

import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded'
import ShareRoundedIcon from '@mui/icons-material/ShareRounded'
import { Container, Grid, Typography } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { ActionButton, MessageInfo, PopUpButton } from 'components/commonparts'
import ShowLastUpdateDatetime from 'components/commonparts/ShowLastUpdateDatetime/ShowLastUpdateDatetime'
import { DataList } from 'components/parts/UserList'
import { useUser } from 'hooks/useUser'
import { initDataListStatus } from 'types/types'

import type { MstUserOutput } from 'api-client'
import type { DataListStatus } from 'types/types'

function UserList() {
  const { loginUserInfo } = useAuthContext()

  const { userSearch, fetchUserSearch, userSearchResult, userSearchIsLoading } = useUser()

  const [listStatus, setListStatus] = useState<DataListStatus>({
    ...initDataListStatus(),
  })
  const navigate = useNavigate()

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  // クエリパラメーターを取得
  const trQueryParam = {
    companyId: Number(query.get('company_id')),
  }

  // ソート順とページ番号の変更通知
  const onListStatusChange = (status: DataListStatus) => {
    fetchUserSearch({
      companyId: loginUserInfo?.tenantId === null ? trQueryParam.companyId : loginUserInfo?.companyId,
      limit: status.paginationModel.pageSize,
      pageNo: status.paginationModel.page + 1,
    })

    setListStatus({ ...listStatus, sortModel: status.sortModel, paginationModel: status.paginationModel })
  }

  useEffect(() => {
    fetchUserSearch({
      companyId: loginUserInfo?.tenantId === null ? trQueryParam.companyId : loginUserInfo?.companyId,
      limit: listStatus.paginationModel.pageSize,
      pageNo: 0,
    })
  }, [])

  return (
    <Container maxWidth="md">
      <Typography component="div" variant="largeBold">
        ユーザー管理
      </Typography>
      <Grid direction="row" container justifyContent="space-between">
        <Grid item marginTop={1} marginBottom={1}>
          <ActionButton
            caption="ユーザーを追加"
            onClick={() =>
              navigate(
                `/${protectedRoutes.UserDetail.path}?company_id=${
                  trQueryParam.companyId ? trQueryParam.companyId : loginUserInfo?.companyId
                }`
              )
            }
            startIcon={<GroupAddRoundedIcon />}
          />
          <PopUpButton
            caption="ログインURLの共有"
            messeage="URLをコピーしました。"
            onClick={() =>
              global.navigator.clipboard.writeText(
                `${new URL(document.URL).origin}/login${
                  loginUserInfo?.tenantId ? `?tenantId=${loginUserInfo?.tenantId}` : ''
                }`
              )
            }
            startIcon={<ShareRoundedIcon />}
          />
        </Grid>
        <Grid item>
          <ShowLastUpdateDatetime<MstUserOutput>
            list={userSearch.list}
            lastUpdateDatetime={userSearch.lastUpdateDatetime}
          />
        </Grid>
      </Grid>
      <DataList
        list={userSearch}
        isLoading={userSearchIsLoading}
        listStatus={listStatus}
        onListStatusChange={onListStatusChange}
      />
      <Grid direction="row" container justifyContent="space-between">
        <Grid item>
          <MessageInfo result={userSearchResult} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default UserList
