import React from 'react'

import SearchIcon from '@mui/icons-material/Search'
import { Button } from '@mui/material'

interface SearchButtonProps {
  caption?: string
  searchId?: string
}

function SearchButton({ caption = '検索', searchId }: SearchButtonProps) {
  return (
    <Button type="submit" id={searchId} variant="contained" startIcon={<SearchIcon />}>
      {caption}
    </Button>
  )
}

export default SearchButton
