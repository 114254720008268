import { useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import _ from 'lodash'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type { GetMstCustomersCustomerGetRequest, MstCustomer, ResponseResult } from 'api-client'
import type { ErrorResponse } from 'types/types'

export interface AutocompleteCustomer {
  label: string | null
  customerCompanyId: number | null
}

export const useCustomer = () => {
  const [customers, setCustomers] = useState<MstCustomer[] | []>([])
  const [autocompletCustomers, setAutocompleteCustomers] = useState<AutocompleteCustomer[]>([])

  const [hasCompanyIdCustomers, setHasCompanyIdCustomers] = useState<MstCustomer[] | []>([])
  const [autocompletHasCompanyICustomers, setAutocompleteHasCompanyICustomers] = useState<AutocompleteCustomer[]>([])
  const [result, setResult] = useState<ResponseResult | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)

  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchCustomer = (searchParam: GetMstCustomersCustomerGetRequest) => {
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getMstCustomersCustomerGet(searchParam)
      })
      .then((response) => {
        setCustomers(response.data.list)
        const customerMap = _.map(response.data.list, (value) => ({
          label: value.customerName,
          customerCompanyId: value.customerCompanyId,
        }))
        setAutocompleteCustomers(customerMap)

        // customer_company_idが入っているデータのみ抽出
        const hasCustomerCompanyId = _.filter(response.data.list, (value) => value.customerCompanyId !== null)
        setHasCompanyIdCustomers(hasCustomerCompanyId)

        const hasCustomerCompanyIdMap = _.map(hasCustomerCompanyId, (value) => ({
          label: value.customerName,
          customerCompanyId: value.customerCompanyId,
        }))
        setAutocompleteHasCompanyICustomers(hasCustomerCompanyIdMap)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    customers,
    autocompletCustomers,

    hasCompanyIdCustomers,
    autocompletHasCompanyICustomers,
    fetchCustomer,
    result,
    isLoading,
  }
}
