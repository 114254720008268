/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CurrentStatus } from './CurrentStatus';
import {
    CurrentStatusFromJSON,
    CurrentStatusFromJSONTyped,
    CurrentStatusToJSON,
} from './CurrentStatus';

/**
 * 
 * @export
 * @interface LoginUser
 */
export interface LoginUser {
    /**
     * 
     * @type {number}
     * @memberof LoginUser
     */
    companyId: number;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    tenantId: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    userMail: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    userName: string;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    authType: string | null;
    /**
     * 
     * @type {string}
     * @memberof LoginUser
     */
    businessCategory: string | null;
    /**
     * 
     * @type {Date}
     * @memberof LoginUser
     */
    systemDate: Date | null;
    /**
     * 
     * @type {Array<CurrentStatus>}
     * @memberof LoginUser
     */
    sysCurrentStatus: Array<CurrentStatus>;
}

/**
 * Check if a given object implements the LoginUser interface.
 */
export function instanceOfLoginUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "companyName" in value;
    isInstance = isInstance && "tenantId" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "userMail" in value;
    isInstance = isInstance && "userName" in value;
    isInstance = isInstance && "authType" in value;
    isInstance = isInstance && "businessCategory" in value;
    isInstance = isInstance && "systemDate" in value;
    isInstance = isInstance && "sysCurrentStatus" in value;

    return isInstance;
}

export function LoginUserFromJSON(json: any): LoginUser {
    return LoginUserFromJSONTyped(json, false);
}

export function LoginUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'companyName': json['company_name'],
        'tenantId': json['tenant_id'],
        'userId': json['user_id'],
        'userMail': json['user_mail'],
        'userName': json['user_name'],
        'authType': json['auth_type'],
        'businessCategory': json['business_category'],
        'systemDate': (json['system_date'] === null ? null : new Date(json['system_date'])),
        'sysCurrentStatus': ((json['sys_current_status'] as Array<any>).map(CurrentStatusFromJSON)),
    };
}

export function LoginUserToJSON(value?: LoginUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'company_name': value.companyName,
        'tenant_id': value.tenantId,
        'user_id': value.userId,
        'user_mail': value.userMail,
        'user_name': value.userName,
        'auth_type': value.authType,
        'business_category': value.businessCategory,
        'system_date': (value.systemDate === null ? null : value.systemDate.toISOString().substring(0,10)),
        'sys_current_status': ((value.sysCurrentStatus as Array<any>).map(CurrentStatusToJSON)),
    };
}

