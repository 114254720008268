import { createTheme, styled, unstable_composeClasses as composeClasses } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import type {} from '@mui/x-data-grid/themeAugmentation'
import MuiInput from '@mui/material/Input'
import { getDataGridUtilityClass, jaJP } from '@mui/x-data-grid-premium'

import type { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import type { DataGridPremiumProps } from '@mui/x-data-grid-premium'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    small: true
    medium: true
    mediumBold: true
    largeBold: true
  }
}

// フォントサイズ
export const smallFontSize = '0.75rem'
export const mediumFontSize = '1rem'
export const largeFontSize = '1.3rem'

// 入力サイズ
export const inputWidth = '16rem'
export const fileInputWidth = '35rem'

// 商品、仕入先詳細に関するmargin
export const detailCompornentMarginBottom = '1rem'

// Boxコンポーネントの個別スタイル群
export const boxMarginTop = { marginTop: 1 }

const dataGridColorToday = {
  backgroundColor: '#ffd364',
  color: '#030303',
  fontWeight: '600',
}

const dataGridColorTodayCell = {
  backgroundColor: '#fff2d1',
  color: '#030303',
  fontWeight: 'bold',
}

export const theme = createTheme({
  spacing: 10,
  typography: {
    fontSize: 12,
    h1: { fontSize: 20 },
    h2: { fontSize: 16, fontWeight: 600 },
    h3: { fontSize: 12 },
  },
  breakpoints: {
    values: {
      xs: 3,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1900,
    },
  },
  palette: {
    primary: {
      main: '#bdbdbd',
    },
    secondary: {
      main: '#eeeeee',
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          '@media (min-width: 600px)': {
            minHeight: '3rem',
          },
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        sx: {
          marginBottom: detailCompornentMarginBottom,
        },
      },
    },
    MuiCollapse: {
      defaultProps: {
        sx: {
          marginBottom: detailCompornentMarginBottom,
        },
      },
    },
    MuiContainer: {
      defaultProps: {
        sx: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiTypography: {
      // 追加variants
      // フォントサイズが３段階で構成されその中で太字が追加される
      variants: [
        {
          props: { variant: 'small' },
          style: {
            fontSize: smallFontSize,
          },
        },
        {
          props: { variant: 'medium' },
          style: {
            fontSize: mediumFontSize,
          },
        },
        {
          props: { variant: 'mediumBold' },
          style: {
            fontSize: mediumFontSize,
            fontWeight: 'bold',
          },
        },
        {
          props: { variant: 'largeBold' },
          style: {
            fontSize: largeFontSize,
            fontWeight: 'bold',
          },
        },
      ],
      styleOverrides: {
        root: {
          marginBottom: 4,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          fontSize: mediumFontSize,
          fontWeight: 'bold',
          height: '1.8rem',
          marginRight: '0.7rem',
          padding: '8px 10px',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontSize: mediumFontSize,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small',
        sx: {
          width: inputWidth,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        // 商品・仕入先詳細などでInputLabelとInputを用いた際に間のmarginを無くす
        root: {
          '& +.MuiInputBase-root': {
            marginTop: 0,
          },
        },
        formControl: {
          // 移動をクリック時に動かないように固定
          position: 'static',
          transform: 'none',
          transition: 'none',
          // クリックを可能に
          pointerEvents: 'auto',
          cursor: 'pointer',
          // 幅いっぱいを解除
          display: 'inline',
          alignSelf: 'start',
          // タイポグラフィを指定
          fontWeight: 'bold',
          fontSize: smallFontSize,
          // ラベル
          color: '#424242',
          marginTop: '0.5rem',
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          marginTop: 0,
          backgroundColor: 'white',
          // テキストの高さを固定
          height: '2.3rem !important',
          fontSize: mediumFontSize,
        },
        notchedOutline: {
          top: 0,
          legend: {
            display: 'none',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          // MEMO: リスト項目の高さを指定しても別のクラスでオーバライドされているため以下の記述で、高さを再割り当てする
          // 参考: https://github.com/mui/material-ui/issues/29703#issuecomment-996586877
          '@media (min-width: 600px)': {
            minHeight: '2.3rem',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          paddingTop: '6px',
          paddingBottom: '6px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
        sx: {
          height: 'auto',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        outlined: {
          backgroundColor: '#e3e3e3',
          marginBottom: '1rem',
          paddingLeft: '0.5rem',
          paddingBottom: '0.5rem',
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        sx: {
          paddingRight: '0.3rem',
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        sx: {
          width: inputWidth,
          display: 'inline-flex',
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        sx: {
          lineHeight: 2,
          padding: '2px 13px',
        },
      },
      styleOverrides: {
        root: {
          '& .MuiAlert-icon': {
            marginRight: 0,
          },
        },
      },
    },
    // タブコンポーネント選択時の色変更
    MuiTab: {
      defaultProps: {
        sx: {
          fontWeight: 'bold',
        },
      },
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#e3e3e3',
            color: 'black',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: 'black',
        },
      },
    },
    MuiTabs: {
      // タブをラウンド状にする
      styleOverrides: {
        root: {
          minHeight: 0,
          '& .MuiTab-root': {
            borderRadius: '8px 8px 0 0',
            maxHeight: '30px',
            minHeight: 0,
            fontSize: mediumFontSize,
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: '2.1rem',
          color: 'white',
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        sx: {
          fontSize: 24,
        },
      },
    },
    MuiGrid: {
      defaultProps: {
        sx: {
          marginTop: 'auto',
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        // 日本語対応
        localeText: jaJP.components.MuiDataGrid.defaultProps.localeText,
        pageSizeOptions: [100, 300, 500],
        experimentalFeatures: { columnGrouping: true },
        // カラムのメニュー非表示
        disableColumnMenu: true,
      },
      styleOverrides: {
        footerContainer: {
          minHeight: '2rem',
        },
        root: {
          fontSize: mediumFontSize,
          marginBottom: '0.5rem',

          // ヘッダー・ヘッダーグループ
          '& .MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'bold',
          },
          // フッター
          '& .MuiDataGrid-footerContainer': {
            fontSize: smallFontSize,
          },
          // データが空の場合、メッセージが隠れてしまうため少し高さを調節
          '& .MuiDataGrid-virtualScrollerContent': {
            minHeight: '10vh !important',
          },
          '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
            borderRight: `1px solid #e5e5e5`,
          },

          // 共通で使われるクラス群
          // ヘッダーグループが実績の場合は背景色を変更
          '& .super-app.rec': {
            backgroundColor: '#b1b3b1',
            color: '#030303',
            fontWeight: '600',
          },
          // ヘッダーグループが予測の場合は背景色を変更
          '& .super-app.expected': {
            backgroundColor: '#dee9f6',
            color: '#030303',
            fontWeight: '600',
          },
          // ヘッダーグループが日曜日の場合は背景色を変更
          '& .super-app.sunday': {
            color: '#ff0000 !important',
          },
          // ヘッダーグループが本日の場合は背景色を変更
          '& .super-app.today': dataGridColorToday,
          // 当日日付の場合はセルの背景色を変更
          '& .super-app.todayCell': dataGridColorTodayCell,

          // 在庫別発注商品一覧ページと入荷数修正ページで付与するクラス群
          // 受注日が当日日付の入荷数がある場合は、背景色を変更
          '& .super-app.arrivalOrderDate': {
            backgroundColor: '#fff2d1',
            fontWeight: 'bold',
          },

          // トップページで付与するクラス群
          // infoTypeがerrorの場合は背景色を変更
          '& .super-app.infotypeErrror': {
            color: 'firebrick',
          },
          // infoTypeがwarningの場合は背景色を変更
          '& .super-app.infotypewarning': {
            color: 'darkorange',
          },

          // 入荷数修正ページで付与するクラス群
          // ヘッダーグループが本日の場合は背景色を変更
          '& .super-app.todayAdjust': {
            ...dataGridColorToday,
            borderTop: `3px solid #ee611f`,
          },
          // 当日日付の場合はセルの背景色を変更
          '& .super-app.todayCellAdjust': {
            ...dataGridColorTodayCell,
            borderTop: `3px solid #ee611f`,
          },
          // 修正後の対象セルの背景色を変更
          '& .super-app.quantityAfterEditing': {
            backgroundColor: '#eef7ed',
            color: '#55aa4c',
            fontWeight: 'bold',
          },
          // 修正前の対象セルの背景色を変更
          '& .super-app.quantityBeforeEditing': {
            backgroundColor: '#3ce07b',
            color: '#1a3e72',
            fontWeight: 'bold',
          },
          // 在庫数と在庫日数が0以下の場合は、背景色を変更
          '& .super-app.stockLess': {
            color: '#ee611f',
            fontWeight: 'bold',
          },
        },
      },
    },
  },
})

// サイドバー用のコンポーネント
interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme: trTheme, open }) => ({
  transition: trTheme.transitions.create(['margin', 'width'], {
    easing: 'cubic-bezier(0.16, 1, 0.3, 1)',
    duration: 0,
  }),
  ...(open && {
    width: `calc(100% - 210px)`,
    marginLeft: `210px`,
    transition: trTheme.transitions.create(['margin', 'width'], {
      easing: trTheme.transitions.easing.easeOut,
      duration: trTheme.transitions.duration.enteringScreen,
    }),
  }),
}))

// SidebarとHeaderコンポーネントのHeader
export const DrawerHeader = styled('div', { shouldForwardProp: (prop) => prop !== 'sidebar' })<{
  sidebar?: boolean
}>(({ theme: trTheme, sidebar }) => ({
  marginBottom: '8px',
  display: 'flex',
  alignItems: 'center',
  padding: sidebar ? trTheme.spacing(0, 1) : 20,
  justifyContent: 'flex-end',
}))

export const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<AppBarProps>(
  ({ theme: trTheme, open }) => ({
    width: '100%',
    flexGrow: 1,
    padding: `${trTheme.spacing(2)} ${trTheme.spacing(2)} 0 ${trTheme.spacing(2)}`,
    transition: trTheme.transitions.create('margin', {
      easing: trTheme.transitions.easing.sharp,
      duration: trTheme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-210px`,
    ...(open && {
      width: `calc(100% - 210px)`,
      padding: `${trTheme.spacing(2)} ${trTheme.spacing(2)} 0 ${trTheme.spacing(2)}`,
      transition: trTheme.transitions.create('margin', {
        easing: trTheme.transitions.easing.easeOut,
        duration: trTheme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
)

// 商品詳細・仕入先詳細で主に使用
export const CustomDetailInput = styled(MuiInput, {
  shouldForwardProp: (prop) => prop !== 'doubleWidth',
})<{ doubleWidth?: boolean }>(({ doubleWidth }) => ({
  ...(doubleWidth && {
    width: '18rem',
  }),
}))

// https://mui.com/material-ui/react-button/#file-upload
export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

export const useTreeDataUtilityClasses = (ownerState: { classes: DataGridPremiumProps['classes'] }) => {
  const { classes } = ownerState

  const slots = {
    root: ['treeDataGroupingCell'],
    toggle: ['treeDataGroupingCellToggle'],
  }
  return composeClasses(slots, getDataGridUtilityClass, classes)
}
