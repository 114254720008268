import { useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import { useCurrentStatusStore } from 'store/currentStatusStore'

import type { GetMstSuppliersSupplierGetRequest, MstSupplier, ResponseResult } from 'api-client'
import type { ErrorResponse } from 'types/types'

export interface AutocompleteSupplier {
  label: string | null
  orgSupplierId: string | null
}

export const useSupplier = () => {
  const [suppliers, setSuppliers] = useState<MstSupplier[]>([])
  const [autocompletSuppliers, setAutocompleteSuppliers] = useState<AutocompleteSupplier[]>([])
  const [result, setResult] = useState<ResponseResult | undefined>(undefined)

  const [isLoading, setIsLoading] = useState(false)
  const { setCurrentStatuses } = useCurrentStatusStore()

  const fetchSupplier = (searchParam: GetMstSuppliersSupplierGetRequest) => {
    setIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.getMstSuppliersSupplierGet(searchParam)
      })
      .then((response) => {
        setSuppliers(response.data.list)

        // SelectSupplierコンポーネントのデータバインド形式に合わせる
        const suppliersMap = response.data.list.map((value) => ({
          label: value.supplierName,
          orgSupplierId: value.orgSupplierId,
        }))
        setAutocompleteSuppliers(suppliersMap)
        setResult(response.result)
        setCurrentStatuses(response.result.systemInfo?.sysCurrentStatus)
      })
      .catch(({ response }: ErrorResponse) => {
        setResult({
          status: 'error',
          message: response && response.status === 422 ? '入力値に誤りがあります。' : `エラーが発生しました。`,
          systemDate: null,
          pageNo: 0,
          systemInfo: null,
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    suppliers,
    autocompletSuppliers,
    result,
    fetchSupplier,
    isLoading,
  }
}
