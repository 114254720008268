export const protectedRoutes = {
  Login: {
    name: 'ログイン',
    path: 'login',
  },
  AuthAction: {
    name: '認証アクション',
    path: '/auth-action',
  },
  PasswordRest: {
    name: 'パスワード',
    path: '/password-reset',
  },
  Top: {
    name: 'トップページ',
    path: '/',
  },
  Dashboard: {
    name: 'ダッシュボード',
    path: 'dashbord',
  },
  UploadRecord: {
    name: '実績・予定登録',
    path: 'upload-record',
  },
  StockCenter: {
    name: '倉庫別発注商品一覧',
    path: 'stock-center',
  },
  StockAdjust: {
    name: '商品詳細',
    path: 'stock-adjust',
  },
  ShipExpectedCenter: {
    name: '倉庫別',
    path: 'ship-expected-center',
  },
  ShipExpectedItem: {
    name: '商品別',
    path: 'ship-expected-item',
  },
  UploadMaster: {
    name: 'マスタメンテ',
    path: 'upload-master',
  },
  Faq: {
    name: '操作マニュアル',
    path: 'faq',
  },
  UserList: {
    name: 'ユーザー管理',
    path: 'user-list',
  },
  UserDetail: {
    name: 'ユーザー情報更新',
    path: 'user-detail',
  },
  CompanyList: {
    name: 'ユーザー企業管理',
    path: 'company-list',
  },
  CompanyDetail: {
    name: 'ユーザー企業情報更新',
    path: 'company-detail',
  },
  AuthSetting: {
    name: '2段階認証設定',
    path: 'auth-setting',
  },
  // TODO リリース時削除
  StockCenterOld: {
    name: '旧）倉庫別発注商品一覧',
    path: 'stock-center-old',
  },
  StockAdjustOld: {
    name: '旧）入出荷数修正',
    path: 'stock-adjust-old',
  },
}
