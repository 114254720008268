import type { FormEvent } from 'react'
import React, { useState } from 'react'

import { Box } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { SearchFrom, SelectCenter } from 'components/commonparts'
import { ItemSearch } from 'components/dialog'

import type { GetShipExpectedShipExpectedGetRequest } from 'api-client'

interface SearchConditionProps {
  searchParam: GetShipExpectedShipExpectedGetRequest
  onSearch: (requestParam: GetShipExpectedShipExpectedGetRequest, e: FormEvent<HTMLFormElement>) => void
}

function SearchCondition({ searchParam, onSearch }: SearchConditionProps) {
  const { loginUserInfo } = useAuthContext()

  const [centerIsValid, setCenterIsValid] = useState(true)
  const [itemIsValid, setItemIsValid] = useState(true)
  const [centerId, setCenterId] = useState<number | null | undefined>(searchParam.centerId)
  const [itemCode, setItemCode] = useState<string | null | undefined>(searchParam.itemCode)

  const onClear = () => {
    setItemCode('')
    setCenterId(undefined)
  }

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault()

        // 必須入力チェック
        if (!centerIsValid && !itemIsValid) {
          onSearch(
            {
              companyId: loginUserInfo!.companyId,
              centerId,
              itemCode,
            },
            e
          )
        }
      }}
    >
      <SearchFrom onClear={onClear}>
        <ItemSearch
          trCompanyId={loginUserInfo!.companyId}
          itemCode={itemCode}
          setItemCode={setItemCode}
          setItemInfo={() => {}}
          isRequred
          setIsValid={setItemIsValid}
        />
        <SelectCenter
          trCompanyId={loginUserInfo!.companyId}
          centerId={centerId}
          setCenterId={setCenterId}
          setCenterInfo={() => {}}
          isRequired
          setIsValid={setCenterIsValid}
        />
      </SearchFrom>
    </Box>
  )
}

export default SearchCondition
