import React from 'react'

import { CircularProgress } from '@mui/material'

function LoadingOverlay() {
  return (
    <div className="ag-overlay-loading-center loading-box" role="presentation">
      <CircularProgress />
      <div>ロード中...</div>
    </div>
  )
}

export default LoadingOverlay
