/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ResponseResult } from './ResponseResult';
import {
    ResponseResultFromJSON,
    ResponseResultFromJSONTyped,
    ResponseResultToJSON,
} from './ResponseResult';
import type { ShipExpecteds } from './ShipExpecteds';
import {
    ShipExpectedsFromJSON,
    ShipExpectedsFromJSONTyped,
    ShipExpectedsToJSON,
} from './ShipExpecteds';

/**
 * 
 * @export
 * @interface ResponseShipExpected
 */
export interface ResponseShipExpected {
    /**
     * 
     * @type {ResponseResult}
     * @memberof ResponseShipExpected
     */
    result: ResponseResult;
    /**
     * 
     * @type {ShipExpecteds}
     * @memberof ResponseShipExpected
     */
    data: ShipExpecteds;
}

/**
 * Check if a given object implements the ResponseShipExpected interface.
 */
export function instanceOfResponseShipExpected(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ResponseShipExpectedFromJSON(json: any): ResponseShipExpected {
    return ResponseShipExpectedFromJSONTyped(json, false);
}

export function ResponseShipExpectedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResponseShipExpected {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': ResponseResultFromJSON(json['result']),
        'data': ShipExpectedsFromJSON(json['data']),
    };
}

export function ResponseShipExpectedToJSON(value?: ResponseShipExpected | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': ResponseResultToJSON(value.result),
        'data': ShipExpectedsToJSON(value.data),
    };
}

