import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'

export const getAPI = async () => {
  const { currentUser } = auth
  if (!currentUser) return null

  const token = await currentUser.getIdToken(true)
  const conf = new Configuration({
    basePath: process.env.REACT_APP_API_BASE_PATH,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  return new DefaultApi(conf)
}
