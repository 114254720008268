import type { Dispatch, SetStateAction, SyntheticEvent } from 'react'
import React, { useEffect, useState } from 'react'

import { Autocomplete, CircularProgress, FormControl, TextField } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { useCustomer } from 'hooks/useCustomer'
import _ from 'lodash'

import type { MstCustomer } from 'api-client'
import type { AutocompleteCustomer } from 'hooks/useCustomer'

interface SearchCustomerProps {
  title?: string
  trCompanyId: number
  customerCompanyId: number | undefined
  setCustomerCompanyId: Dispatch<SetStateAction<number | undefined>>
  setCustomerCompanyInfo: Dispatch<SetStateAction<MstCustomer | undefined>>
  hasCompanyId?: boolean
  isRequred: boolean
  setIsValid: Dispatch<SetStateAction<boolean>>
}

function SearchCustomer({
  trCompanyId,
  customerCompanyId,
  title = '得意先',
  setCustomerCompanyId,
  setCustomerCompanyInfo,
  hasCompanyId = true,
  isRequred,
  setIsValid,
}: SearchCustomerProps) {
  const { loginUserInfo } = useAuthContext()

  const {
    customers,
    autocompletCustomers,
    hasCompanyIdCustomers,
    autocompletHasCompanyICustomers,
    fetchCustomer,
    isLoading,
  } = useCustomer()

  const [inputValue, setInputValue] = useState('')
  const [trValue, setTrValue] = useState<AutocompleteCustomer | null | undefined>(null)
  const trCustomers = hasCompanyId ? hasCompanyIdCustomers : customers
  const trAutocompletCustomers = hasCompanyId ? autocompletHasCompanyICustomers : autocompletCustomers

  useEffect(() => {
    // customerCompanyIdが指定されている場合は入力値を取得する
    // ケース ・入力選択時 ・クエリパラメータ取得時
    if (trAutocompletCustomers.length > 0 && customerCompanyId) {
      setTrValue(_.find(trAutocompletCustomers, { customerCompanyId }))
    }
  }, [trAutocompletCustomers])

  useEffect(() => {
    // trCompanyIdが指定がない場合はログインユーザーの企業ID
    if (trCompanyId === undefined) {
      fetchCustomer({ companyId: loginUserInfo!.companyId, customerName: inputValue, customerCompanyId })
    } else {
      fetchCustomer({ companyId: trCompanyId, customerName: inputValue, customerCompanyId })
    }
  }, [inputValue])

  useEffect(() => {
    // customerCompanyIdがundefined(クリアボタン押下時)の場合は入力値をクリアにする
    if (customerCompanyId === undefined) {
      setTrValue(null)
    }
  }, [customerCompanyId])

  // 必須チェック処理
  useEffect(() => {
    // 必須チェックを上位コンポーネントに通知
    if (trValue) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [trValue])

  return (
    <FormControl>
      <Autocomplete
        disablePortal
        id="customer"
        // valueを明示的に指定(controlled component)しなければならないが、未指定の場合はundefinedが返るので
        // undefinedの場合はnullを指定する
        value={trValue ?? null}
        options={trAutocompletCustomers}
        loading={isLoading}
        onInputChange={(e, newInputValue) => {
          setInputValue(newInputValue)
        }}
        onChange={(e: SyntheticEvent<Element, Event>, selectedValue: AutocompleteCustomer | null) => {
          // 得意先情報を上位コンポーネントに通知
          setCustomerCompanyId(selectedValue?.customerCompanyId as number)
          setCustomerCompanyInfo(_.find(trCustomers, { customerCompanyId: selectedValue?.customerCompanyId }))

          setTrValue(selectedValue)
        }}
        isOptionEqualToValue={(option, value) => option.customerCompanyId === value.customerCompanyId}
        renderInput={(params) => (
          <TextField
            label={title}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <div>{isLoading ? <CircularProgress color="inherit" size={20} /> : params.InputProps.endAdornment}</div>
              ),
            }}
            // 必須入力チェック関するprops
            error={isRequred && !trValue}
            helperText={isRequred && !trValue && '必須'}
            required={isRequred}
          />
        )}
      />
    </FormControl>
  )
}

export default SearchCustomer
