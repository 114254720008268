/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Codes } from './Codes';
import {
    CodesFromJSON,
    CodesFromJSONTyped,
    CodesToJSON,
} from './Codes';

/**
 * 
 * @export
 * @interface Groups
 */
export interface Groups {
    /**
     * 
     * @type {string}
     * @memberof Groups
     */
    typeGroupName: string;
    /**
     * 
     * @type {string}
     * @memberof Groups
     */
    typeGroup: string;
    /**
     * 
     * @type {Array<Codes>}
     * @memberof Groups
     */
    codes: Array<Codes>;
}

/**
 * Check if a given object implements the Groups interface.
 */
export function instanceOfGroups(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "typeGroupName" in value;
    isInstance = isInstance && "typeGroup" in value;
    isInstance = isInstance && "codes" in value;

    return isInstance;
}

export function GroupsFromJSON(json: any): Groups {
    return GroupsFromJSONTyped(json, false);
}

export function GroupsFromJSONTyped(json: any, ignoreDiscriminator: boolean): Groups {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'typeGroupName': json['type_group_name'],
        'typeGroup': json['type_group'],
        'codes': ((json['codes'] as Array<any>).map(CodesFromJSON)),
    };
}

export function GroupsToJSON(value?: Groups | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type_group_name': value.typeGroupName,
        'type_group': value.typeGroup,
        'codes': ((value.codes as Array<any>).map(CodesToJSON)),
    };
}

