import type { MouseEvent, ReactNode } from 'react'
import React, { useState } from 'react'

import { Button, Popover, Typography } from '@mui/material'

interface PopUpButtonProps {
  onClick: () => void
  caption?: string
  messeage: string
  startIcon?: ReactNode
  disabled?: boolean
}

function PopUpButton({ onClick, caption = '', messeage, startIcon, disabled }: PopUpButtonProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    // 1秒後に表示が消えるようにする
    setTimeout(() => {
      handleClose()
    }, 1000)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={(e) => {
          onClick()
          handleClick(e)
        }}
        startIcon={startIcon}
        disabled={disabled}
      >
        {caption}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 1 }}>{messeage}</Typography>
      </Popover>
    </>
  )
}

export default PopUpButton
