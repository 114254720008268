/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ArrivalQuantityPlan } from './ArrivalQuantityPlan';
import {
    ArrivalQuantityPlanFromJSON,
    ArrivalQuantityPlanFromJSONTyped,
    ArrivalQuantityPlanToJSON,
} from './ArrivalQuantityPlan';
import type { ShipQuantityPlan } from './ShipQuantityPlan';
import {
    ShipQuantityPlanFromJSON,
    ShipQuantityPlanFromJSONTyped,
    ShipQuantityPlanToJSON,
} from './ShipQuantityPlan';

/**
 * 
 * @export
 * @interface StockUpdateBase
 */
export interface StockUpdateBase {
    /**
     * プロダクト統一企業ID
     * @type {number}
     * @memberof StockUpdateBase
     */
    companyId: number;
    /**
     * プロダクト統一センターID
     * @type {number}
     * @memberof StockUpdateBase
     */
    centerId: number;
    /**
     * 商品コード
     * @type {string}
     * @memberof StockUpdateBase
     */
    itemCode: string;
    /**
     * 実績日
     * @type {Date}
     * @memberof StockUpdateBase
     */
    recDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof StockUpdateBase
     */
    orderDate?: Date | null;
    /**
     * 
     * @type {ShipQuantityPlan}
     * @memberof StockUpdateBase
     */
    shipQuantityPlan?: ShipQuantityPlan | null;
    /**
     * 
     * @type {ArrivalQuantityPlan}
     * @memberof StockUpdateBase
     */
    arrivalQuantityPlan?: ArrivalQuantityPlan | null;
    /**
     * 
     * @type {string}
     * @memberof StockUpdateBase
     */
    orderQuantityReason?: string | null;
}

/**
 * Check if a given object implements the StockUpdateBase interface.
 */
export function instanceOfStockUpdateBase(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "companyId" in value;
    isInstance = isInstance && "centerId" in value;
    isInstance = isInstance && "itemCode" in value;
    isInstance = isInstance && "recDate" in value;

    return isInstance;
}

export function StockUpdateBaseFromJSON(json: any): StockUpdateBase {
    return StockUpdateBaseFromJSONTyped(json, false);
}

export function StockUpdateBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockUpdateBase {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': json['company_id'],
        'centerId': json['center_id'],
        'itemCode': json['item_code'],
        'recDate': (new Date(json['rec_date'])),
        'orderDate': !exists(json, 'order_date') ? undefined : (json['order_date'] === null ? null : new Date(json['order_date'])),
        'shipQuantityPlan': !exists(json, 'ship_quantity_plan') ? undefined : ShipQuantityPlanFromJSON(json['ship_quantity_plan']),
        'arrivalQuantityPlan': !exists(json, 'arrival_quantity_plan') ? undefined : ArrivalQuantityPlanFromJSON(json['arrival_quantity_plan']),
        'orderQuantityReason': !exists(json, 'order_quantity_reason') ? undefined : json['order_quantity_reason'],
    };
}

export function StockUpdateBaseToJSON(value?: StockUpdateBase | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'company_id': value.companyId,
        'center_id': value.centerId,
        'item_code': value.itemCode,
        'rec_date': (value.recDate.toISOString()),
        'order_date': value.orderDate === undefined ? undefined : (value.orderDate === null ? null : value.orderDate.toISOString()),
        'ship_quantity_plan': ShipQuantityPlanToJSON(value.shipQuantityPlan),
        'arrival_quantity_plan': ArrivalQuantityPlanToJSON(value.arrivalQuantityPlan),
        'order_quantity_reason': value.orderQuantityReason,
    };
}

