import type { PropsWithChildren } from 'react'
import React from 'react'

import { productTitle } from 'constants/constants'

import { Box, CssBaseline, Grid, Typography } from '@mui/material'
import { Header } from 'components/parts'

function BeforeLogin({ children }: PropsWithChildren) {
  return (
    <Box display="flex">
      <CssBaseline />
      <Header isLogin={false} />
      <Grid container alignItems="center" justifyContent="center" direction="column" sx={{ minHeight: '100vh' }}>
        <Typography variant="largeBold" textAlign="center" sx={{ marginBottom: 8 }}>
          {productTitle}
        </Typography>
        {children}
      </Grid>
    </Box>
  )
}

export default BeforeLogin
