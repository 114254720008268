import React, { useState } from 'react'

import { Box, Button, FormControl, InputLabel, TextField } from '@mui/material'
import { boxMarginTop, mediumFontSize } from 'style'

interface PasswordResetFormProps {
  handleSubmit: (email: string) => Promise<void>
}

function PasswordResetForm({ handleSubmit }: PasswordResetFormProps) {
  const [email, setEmail] = useState('')

  return (
    <Box sx={{ marginBottom: 1 }}>
      <FormControl>
        <InputLabel sx={{ fontSize: mediumFontSize }}>メールアドレス</InputLabel>
        <TextField id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
      </FormControl>
      <Box display="flex" justifyContent="center" sx={{ ...boxMarginTop }}>
        <Button variant="contained" onClick={() => handleSubmit(email)}>
          送信
        </Button>
      </Box>
    </Box>
  )
}

export default PasswordResetForm
