import React from 'react'

import { Typography } from '@mui/material'
import { convertFromDateTime } from 'utils/convertFromLocalDate'

interface ShowLastUpdateDatetimeProps<T> {
  list: T[]
  lastUpdateDatetime: Date | null
}

function ShowLastUpdateDatetime<T>({ list, lastUpdateDatetime }: ShowLastUpdateDatetimeProps<T>) {
  return (
    <Typography variant="medium" justifyContent="flex-end" display="flex" color="royalblue">
      {list.length > 0 && `最終更新日: ${convertFromDateTime(lastUpdateDatetime)}`}
    </Typography>
  )
}

export default ShowLastUpdateDatetime
