import type { ReactNode } from 'react'
import React from 'react'

import { Button, CircularProgress } from '@mui/material'

interface ActionButtonProps {
  onClick: () => void
  caption?: string
  startIcon?: ReactNode
  disabled?: boolean
  isLoading?: boolean
}

function ActionButton({ onClick, caption = '登録', startIcon, disabled, isLoading = false }: ActionButtonProps) {
  return (
    <Button
      variant="contained"
      onClick={onClick}
      startIcon={isLoading ? <CircularProgress size={12} color="primary" /> : startIcon}
      disabled={disabled || isLoading}
    >
      {caption}
    </Button>
  )
}

export default ActionButton
