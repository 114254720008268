import React, { useRef, useEffect, memo, useCallback } from 'react'

import { useGridCellEditor, type CustomCellEditorProps } from 'ag-grid-react'

import type { StockCell, StockRow } from './table-model'

export default memo(({ value, initialValue, onValueChange }: CustomCellEditorProps<StockRow, StockCell>) => {
  const refInput = useRef<HTMLInputElement>(null)

  useEffect(() => {
    refInput.current?.select()
    onValueChange(value)
  }, [])

  const sanitizeValue = (str: string): number | null => {
    try {
      const sanitizedValue = parseFloat(str)
      if (Number.isNaN(sanitizedValue)) {
        return null
      }

      if (sanitizedValue < 0) {
        return Math.round(sanitizedValue * -1)
      }

      return Math.round(sanitizedValue)
    } catch (e) {
      return null
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!value) {
      return
    }

    onValueChange({
      ...value,
      value: sanitizeValue(event.target.value),
      oldValue: initialValue?.value || null,
      isCurrentUpdate: true,
    })
  }

  const isCancelBeforeStart = useCallback(() => false, [])

  const isCancelAfterEnd = useCallback(() => false, [value])

  useGridCellEditor({
    isCancelBeforeStart,
    isCancelAfterEnd,
  })

  return (
    <input
      className="stock-cell-input"
      ref={refInput}
      type="number"
      value={value?.value || (value?.value === 0 ? 0 : '')}
      onChange={handleChange}
    />
  )
})
