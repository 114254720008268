import React, { useEffect, useRef, useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { KeyboardDoubleArrowDown } from '@mui/icons-material'
import { Alert, Box, Button, Container, Grid, TextField, Typography } from '@mui/material'
import { useAuthContext } from 'AuthProvider'
import { PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, multiFactor } from 'firebase/auth'

import type { FirebaseError } from 'firebase/app'
import type { PhoneInfoOptions } from 'firebase/auth'

function AuthSetting() {
  const OLD_CREDENTIAL_ERROR = 'auth/requires-recent-login'
  const { loginUserInfo } = useAuthContext()
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  // const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | undefined>()
  const [recaptchaId, setRecaptchaId] = useState<number | undefined>()
  const [verificationId, setVerificationId] = useState<string>('')
  const [verificationCode, setVerificationCode] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const recaptchaVerifierRef = useRef<RecaptchaVerifier | null | undefined>()

  const setRecaptcha = async () => {
    if (!loginUserInfo || recaptchaId) {
      return
    }

    try {
      recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
      })
      const id = await recaptchaVerifierRef.current.render()
      setRecaptchaId(id)
    } catch (e) {
      // 開発環境ではuseEffectが2回実行されてしまい、renderでエラーが発生するため、
      // それを回避するためにtry catchを使用。
    }
  }

  const isRecaptchaRendered = (): boolean => recaptchaId !== -1 && recaptchaId !== undefined

  const i18nPhoneNumber = (): string => {
    if (!phoneNumber) {
      return ''
    }

    return phoneNumber.trim().replace('-', '').replace(/^0/, '+81')
  }

  const jpPhoneNumber = (): string => {
    if (!loginUserInfo?.mfaTel) {
      return ''
    }

    return loginUserInfo.mfaTel.replace(/^\+81/, '0')
  }

  const sendPhoneNumber = async () => {
    if (!isRecaptchaRendered) {
      return
    }

    if (!recaptchaVerifierRef.current) {
      return
    }

    if (!auth || !auth.currentUser) {
      return
    }

    try {
      const multiFactorSession = await multiFactor(auth.currentUser).getSession()
      const phoneInfoOptions: PhoneInfoOptions = {
        phoneNumber: i18nPhoneNumber(),
        session: multiFactorSession,
      }
      const resultId = await new PhoneAuthProvider(auth).verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifierRef.current
      )
      setVerificationId(resultId)
    } catch (e) {
      const fbe = e as FirebaseError
      if (fbe?.code === OLD_CREDENTIAL_ERROR) {
        console.error(fbe.code)
        setErrorMessage(
          'ログインから時間が経過しているため設定できませんでした。一度ログアウトし、再度ログインしてから設定を行ってください。'
        )
      } else {
        setErrorMessage('不明なエラーが発生しました。ブラウザをリロードしてください')
      }
    } finally {
      recaptchaVerifierRef.current.verify()
      recaptchaVerifierRef.current.clear()
      recaptchaVerifierRef.current = null
    }
  }

  const verifyAuthCode = async () => {
    if (!auth || !auth.currentUser) {
      return
    }

    try {
      const cred = PhoneAuthProvider.credential(verificationId, verificationCode)
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
      await multiFactor(auth.currentUser).enroll(multiFactorAssertion)

      window.location.reload()
    } catch (e) {
      // error制御
    }
  }

  useEffect(() => {
    setRecaptcha()
  }, [])

  return (
    <Container id="main-container" maxWidth="md" sx={{ marginTop: '2rem' }}>
      <Typography component="div" variant="largeBold">
        2段階認証設定
      </Typography>
      <div id="recaptcha-container" />
      {loginUserInfo && !loginUserInfo.isMfaEnabled && (
        <div>
          {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
          <Box sx={{ border: 1, borderColor: 'grey.500', padding: 1, marginTop: 2 }}>
            <Typography>電話番号を設定してください</Typography>
            <Grid container>
              <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
                <Grid item md={4} sx={{ marginTop: 0 }}>
                  <Typography variant="medium">電話番号（ハイフン無し）</Typography>
                </Grid>
                <Grid item md={6}>
                  <TextField
                    value={phoneNumber ?? ''}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    disabled={!!verificationId}
                    sx={{ width: 400 }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {isRecaptchaRendered() && !verificationId && (
            <Grid direction="row" container justifyContent="space-between" paddingTop={1}>
              <Button variant="contained" onClick={sendPhoneNumber}>
                送信
                {/* {(userCreateIsLoading || userUpdateIsLoading) && <CircularProgress size={24} color="primary" />} */}
              </Button>
            </Grid>
          )}
          {verificationId && (
            <Container maxWidth="md">
              <Grid textAlign="center">
                <KeyboardDoubleArrowDown color="action" sx={{ fontSize: '64px' }} />
              </Grid>
            </Container>
          )}
          {verificationId && (
            <div>
              <Box sx={{ border: 1, borderColor: 'grey.500', padding: 1, marginTop: 2 }}>
                <Typography>携帯電話に送信された6桁の認証コードを入力してください</Typography>
                <Grid container>
                  <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
                    <Grid item md={4} sx={{ marginTop: 0 }}>
                      <Typography variant="medium">認証コード</Typography>
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        value={verificationCode ?? ''}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        sx={{ width: 400 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Grid direction="row" container justifyContent="space-between" paddingTop={1}>
                <Button variant="contained" onClick={verifyAuthCode}>
                  送信
                  {/* {(userCreateIsLoading || userUpdateIsLoading) && <CircularProgress size={24} color="primary" />} */}
                </Button>
              </Grid>
            </div>
          )}
        </div>
      )}
      {loginUserInfo && loginUserInfo.isMfaEnabled && (
        <div>
          <Box sx={{ border: 1, borderColor: 'grey.500', padding: 1, marginTop: 2 }}>
            <Alert severity="success">
              <Typography sx={{ fontWeight: 'bold' }}>2段階認証が設定済みです。</Typography>
              <Typography>設定を解除したい場合は、システム管理者にお問い合わせください。</Typography>
            </Alert>
            <Grid container sx={{ marginTop: '2rem' }}>
              <Grid container direction="row" alignItems="center" justifyContent="start" marginBottom={1}>
                <Grid item md={3} sx={{ marginTop: 0 }}>
                  <Typography variant="medium">電話番号</Typography>
                </Grid>
                <Grid item md={9}>
                  <Typography fontSize="large" sx={{ fontWeight: 'bold' }}>
                    {jpPhoneNumber()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </div>
      )}
    </Container>
  )
}

export default AuthSetting
