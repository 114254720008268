import { useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import { dateToStr24HPad0DayOfWeek } from 'utils/convertFromLocalDate'

import type { DlCsvDownloadCsvDataTypeGetRequest, DlXlsxDownloadXlsxDataTypeGetRequest } from 'api-client'

export const useFileDownload = () => {
  const [csvIsLoading, setCsvIsLoading] = useState(false)
  const [xlsxIsLoading, setXlsxIsLoading] = useState(false)

  const fetchCsvDownload = (filename: string, searchParam: DlCsvDownloadCsvDataTypeGetRequest) => {
    setCsvIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.dlCsvDownloadCsvDataTypeGetRaw(searchParam)
      })
      .then((response) => {
        response.raw.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${filename}_${dateToStr24HPad0DayOfWeek(new Date(), 'YYYYMMDDhhmmss')}`)
          document.body.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link)
        })
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setCsvIsLoading(false)
      })
  }

  const fetchXlsxDownload = (filename: string, searchParam: DlXlsxDownloadXlsxDataTypeGetRequest) => {
    setXlsxIsLoading(true)

    auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.dlXlsxDownloadXlsxDataTypeGetRaw(searchParam)
      })
      .then((response) => {
        response.raw.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${filename}_${dateToStr24HPad0DayOfWeek(new Date(), 'YYYYMMDDhhmmss')}`)
          document.body.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link)
        })
      })
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setXlsxIsLoading(false)
      })
  }

  return {
    fetchCsvDownload,
    csvIsLoading,

    fetchXlsxDownload,
    xlsxIsLoading,
  }
}
