import { useState } from 'react'

import { auth } from 'constants/fireabaseConfig'

import { Configuration, DefaultApi } from 'api-client'
import { dateToStr24HPad0DayOfWeek } from 'utils/convertFromLocalDate'

import type {
  DlShipExpectedCsvDownloadCsvShipExpectedGetRequest,
  DlShipExpectedXlsxDownloadXlsxShipExpectedGetRequest,
} from 'api-client'

export const useShipExpectedFileDownload = () => {
  const [isCsvLoading, setIsCsvLoading] = useState(false)
  const [isXlsxLoading, setIsXlsxLoading] = useState(false)

  const fetchShipExpectedCsvDownload = (
    filename: string,
    searchParam: DlShipExpectedCsvDownloadCsvShipExpectedGetRequest
  ) => {
    setIsCsvLoading(true)

    return auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.dlShipExpectedCsvDownloadCsvShipExpectedGetRaw({
          ...searchParam,
          companyId: searchParam.companyId,
          centerId: searchParam.centerId || undefined,
          viewDateType: searchParam.viewDateType || undefined,
          viewUnitType: searchParam.viewUnitType || undefined,
          itemCode: searchParam.itemCode || undefined,
        })
      })
      .then((response) => {
        response.raw.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${filename}_${dateToStr24HPad0DayOfWeek(new Date(), 'YYYYMMDDhhmmss')}`)
          document.body.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link)
        })
      })
      .finally(() => {
        setIsCsvLoading(false)
      })
  }

  const fetchShipExpectedXlsxDownload = (
    filename: string,
    searchParam: DlShipExpectedXlsxDownloadXlsxShipExpectedGetRequest
  ) => {
    setIsXlsxLoading(true)

    return auth.currentUser
      ?.getIdToken(true)
      .then((token) => {
        const conf = new Configuration({
          basePath: process.env.REACT_APP_API_BASE_PATH,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        const api = new DefaultApi(conf)
        return api.dlShipExpectedXlsxDownloadXlsxShipExpectedGetRaw({
          ...searchParam,
          companyId: searchParam.companyId,
          centerId: searchParam.centerId || undefined,
          viewDateType: searchParam.viewDateType || undefined,
          viewUnitType: searchParam.viewUnitType || undefined,
          itemCode: searchParam.itemCode || undefined,
        })
      })
      .then((response) => {
        response.raw.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob], { type: blob.type }))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${filename}_${dateToStr24HPad0DayOfWeek(new Date(), 'YYYYMMDDhhmmss')}`)
          document.body.appendChild(link)
          link.click()
          link.parentNode?.removeChild(link)
        })
      })
      .finally(() => {
        setIsXlsxLoading(false)
      })
  }

  return {
    fetchShipExpectedCsvDownload,
    isCsvLoading,
    fetchShipExpectedXlsxDownload,
    isXlsxLoading,
  }
}
