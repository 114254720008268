import type { SyntheticEvent } from 'react'
import React, { useState } from 'react'

import { Box, Tab, Tabs, Typography } from '@mui/material'
import { CustomTabPanel, FileUpload } from 'components/commonparts'
import { UploadMasterTab, useUploadMasterTabStore } from 'store/uploadMasterTabStore'

function UploadMaster() {
  const uploadMasterTabState = useUploadMasterTabStore((state) => state.uploadMasterTabState)
  const setUploadMasterTabsState = useUploadMasterTabStore((state) => state.setUploadMasterTabsState)

  const [value, setValue] = useState(uploadMasterTabState)

  const handleChange = (_event: SyntheticEvent, newValue: (typeof UploadMasterTab)[keyof typeof UploadMasterTab]) => {
    setValue(newValue)
    setUploadMasterTabsState(newValue)
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  return (
    <Box>
      <Typography component="div" variant="largeBold">
        マスタメンテ
      </Typography>
      <Box sx={{ marginBottom: 1, borderBottom: 1 }}>
        <Tabs value={value} onChange={handleChange} aria-label="tabs">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Tab value={UploadMasterTab.Common} label="共通" {...a11yProps(0)} />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Tab value={UploadMasterTab.Order} label="発注" {...a11yProps(1)} />
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Tab value={UploadMasterTab.Ship} label="受注" {...a11yProps(2)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={UploadMasterTab.Common}>
        <FileUpload dataType="MstCenter" title="自社センター" isDownload />
        <FileUpload dataType="MstItem" title="商品" isDownload />
        <FileUpload dataType="MstCalendar" title="カレンダー" isDownload />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={UploadMasterTab.Order}>
        <FileUpload dataType="MstSupplier" title="仕入先企業" isDownload />
        <FileUpload dataType="MstCenterStockItem" title="センター在庫管理" isDownload />
        <FileUpload dataType="MstSupplierRef" title="仕入先紐づけ" isDownload />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={UploadMasterTab.Ship}>
        <FileUpload dataType="MstCustomer" title="得意先企業" isDownload />
        <FileUpload dataType="MstCustomerCenter" title="得意先センター" isDownload />
        <FileUpload dataType="MstTransportRelation" title="輸送" isDownload />
        <FileUpload dataType="MstCustomerRef" title="得意先紐づけ" isDownload />
        <FileUpload dataType="MstCustomerCenterRef" title="得意先センター紐づけ" isDownload />
      </CustomTabPanel>
    </Box>
  )
}

export default UploadMaster
