import React from 'react'

import { Alert, Box } from '@mui/material'

import type { AlertColor } from '@mui/material'
import type { ResponseResult } from 'api-client'

interface MessageInfoProps {
  result: ResponseResult | undefined
}

function MessageInfo({ result }: MessageInfoProps) {
  return <Box>{result?.status ? <Alert severity={result!.status as AlertColor}>{result.message}</Alert> : <div />}</Box>
}

export default MessageInfo
