import React from 'react'

import { Button } from '@mui/material'

interface ClearButtonProps {
  onClick: () => void
}

function ClearButton({ onClick }: ClearButtonProps) {
  return (
    <Button variant="contained" color="secondary" onClick={onClick}>
      クリア
    </Button>
  )
}

export default ClearButton
