import _ from 'lodash'

import type { GridSortModel } from '@mui/x-data-grid'

export const sortToRequestParam = (status: GridSortModel) =>
  status.map((value) => {
    if (value.sort === 'asc') {
      return `${value.field}`
    }
    return `${value.field},${value.sort}`
  })

export const sortToSortModel = (sort: string[] | undefined) => {
  const sortModel: GridSortModel = []

  _.map(sort, (value) => {
    if (value.includes('desc')) {
      sortModel.push({
        field: value.split(',')[0],
        sort: 'desc',
      })
    } else {
      sortModel.push({
        field: value.split(',')[0],
        sort: 'asc',
      })
    }
  })

  return sortModel
}
