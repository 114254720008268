import React, { useState } from 'react'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, InputLabel, Typography } from '@mui/material'
import { CustomDetailInput } from 'style'
import { convertFromDate } from 'utils/convertFromLocalDate'

import type { MstItem } from 'api-client'

interface ItemDetailProps {
  item: MstItem | undefined
  defaultExpanded?: boolean
}

function ItemDetail({ item, defaultExpanded = false }: ItemDetailProps) {
  const [expanded, setExpanded] = useState(defaultExpanded)

  const handleChange = () => {
    // 現在の状態の反対の値に設定して、開閉を切り替えます。
    setExpanded(!expanded)
  }

  return (
    <Grid item direction="row" container>
      <Typography variant="h2">商品詳細</Typography>
      <Grid item xs={12}>
        <Accordion expanded={expanded} onChange={handleChange}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
            <FormControl>
              <InputLabel>社内商品コード</InputLabel>
              <CustomDetailInput size="small" value={item?.orgItemCode ?? ''} />
            </FormControl>
            <FormControl>
              <InputLabel>商品名称</InputLabel>
              <CustomDetailInput size="small" doubleWidth value={item?.itemName ?? ''} />
            </FormControl>
            <FormControl>
              <InputLabel>メーカー名</InputLabel>
              <CustomDetailInput size="small" value={item?.makerName ?? ''} />
            </FormControl>
            <FormControl>
              <InputLabel>発注単位</InputLabel>
              <CustomDetailInput size="small" value={item?.orderUnitTypeName ?? ''} />
            </FormControl>
            <FormControl>
              <InputLabel>ケースロット数</InputLabel>
              <CustomDetailInput size="small" value={item?.caseLot ?? ''} />
            </FormControl>
            <FormControl>
              <InputLabel>最低発注量</InputLabel>
              <CustomDetailInput size="small" value={item?.minOrderQuantity ?? ''} />
            </FormControl>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel>商品コード</InputLabel>
                <CustomDetailInput size="small" value={item?.itemCode ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>発売日</InputLabel>
                <CustomDetailInput size="small" value={convertFromDate(item?.salesStartdate) ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>終了日</InputLabel>
                <CustomDetailInput size="small" value={convertFromDate(item?.salesEnddate) ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>規格</InputLabel>
                <CustomDetailInput size="small" value={item?.spec ?? ''} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel>ケースサイズ 長手（mm）</InputLabel>
                <CustomDetailInput size="small" value={item?.longside ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>ケースサイズ 短手（mm）</InputLabel>
                <CustomDetailInput size="small" value={item?.shortside ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>ケースサイズ 高さ（mm）</InputLabel>
                <CustomDetailInput size="small" value={item?.height ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>ケースサイズ 重量（g）</InputLabel>
                <CustomDetailInput size="small" value={item?.weight ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>パレット最大積付数</InputLabel>
                <CustomDetailInput size="small" value={item?.maxPalletQuantity ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>パレット積載数（面）</InputLabel>
                <CustomDetailInput size="small" value={item?.palletQuantityFace ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>パレット積載数（段）</InputLabel>
                <CustomDetailInput size="small" value={item?.palletQuantityTier ?? ''} />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl>
                <InputLabel>JANコード（バラ）</InputLabel>
                <CustomDetailInput size="small" value={item?.jan ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>JANコード（ケース）</InputLabel>
                <CustomDetailInput size="small" value={item?.janCase ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>GTINコード（バラ）</InputLabel>
                <CustomDetailInput size="small" value={item?.gtin13 ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>GTINコード（ケース）</InputLabel>
                <CustomDetailInput size="small" value={item?.gtin14 ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>ITFコード</InputLabel>
                <CustomDetailInput size="small" value={item?.itf ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>SDPコード</InputLabel>
                <CustomDetailInput size="small" value={item?.sdp ?? ''} />
              </FormControl>
              <FormControl>
                <InputLabel>後継商品コード</InputLabel>
                <CustomDetailInput size="small" value={item?.replaceItemCode ?? ''} />
              </FormControl>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </Grid>
  )
}

export default ItemDetail
