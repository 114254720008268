import React from 'react'
// https://mui.com/material-ui/react-tabs/#basic-tabs
// MUIのタブは個別でタブ表示時のコンポーネントを作成する必要がある

interface TabPanelProps {
  index: string
  value: string
  children?: React.ReactNode
}

function CustomTabPanel({ index, value, children }: TabPanelProps) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
      {value === index && children}
    </div>
  )
}

export default CustomTabPanel
