// companyIdの初期値を取得する関数
export const getInitCompanyId = (
  queryCompanyId: number,
  localStorageComapnyId: number | null | undefined,
  userCompanyId: number
) => {
  if (queryCompanyId) {
    return queryCompanyId
  }
  // ローカルストレージに保存してある検索条件のcompanyIdが更新されているかどうか
  if (localStorageComapnyId !== 0 && localStorageComapnyId) {
    return localStorageComapnyId
  }
  return userCompanyId
}
