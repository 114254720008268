/* tslint:disable */
/* eslint-disable */
/**
 * WSK APIドキュメント
 *  WSK APIドキュメント  ## レスポンス形式 - result     - **status**：     - **message**：     - **system_date**：     - **pageno**： - data     - list         - **datacount**：         - **maxcount**：         - **list**：   ### result.status - **success**：正常終了 - **error**：入力エラー、権限エラー - **warning**：警告で、通知する必要のあるメッセージがある - **info**：正常処理で、通知する必要のあるメッセージがある  
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Daylist } from './Daylist';
import {
    DaylistFromJSON,
    DaylistFromJSONTyped,
    DaylistToJSON,
} from './Daylist';
import type { StockTree } from './StockTree';
import {
    StockTreeFromJSON,
    StockTreeFromJSONTyped,
    StockTreeToJSON,
} from './StockTree';

/**
 * 
 * @export
 * @interface StockTrees
 */
export interface StockTrees {
    /**
     * 
     * @type {number}
     * @memberof StockTrees
     */
    datacount: number | null;
    /**
     * 
     * @type {number}
     * @memberof StockTrees
     */
    maxcount: number | null;
    /**
     * 
     * @type {Date}
     * @memberof StockTrees
     */
    lastUpdateDatetime: Date | null;
    /**
     * 
     * @type {Array<StockTree>}
     * @memberof StockTrees
     */
    list: Array<StockTree>;
    /**
     * 
     * @type {Array<Daylist>}
     * @memberof StockTrees
     */
    daylist: Array<Daylist>;
}

/**
 * Check if a given object implements the StockTrees interface.
 */
export function instanceOfStockTrees(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "datacount" in value;
    isInstance = isInstance && "maxcount" in value;
    isInstance = isInstance && "lastUpdateDatetime" in value;
    isInstance = isInstance && "list" in value;
    isInstance = isInstance && "daylist" in value;

    return isInstance;
}

export function StockTreesFromJSON(json: any): StockTrees {
    return StockTreesFromJSONTyped(json, false);
}

export function StockTreesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StockTrees {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'datacount': json['datacount'],
        'maxcount': json['maxcount'],
        'lastUpdateDatetime': (json['last_update_datetime'] === null ? null : new Date(json['last_update_datetime'])),
        'list': ((json['list'] as Array<any>).map(StockTreeFromJSON)),
        'daylist': ((json['daylist'] as Array<any>).map(DaylistFromJSON)),
    };
}

export function StockTreesToJSON(value?: StockTrees | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'datacount': value.datacount,
        'maxcount': value.maxcount,
        'last_update_datetime': (value.lastUpdateDatetime === null ? null : value.lastUpdateDatetime.toISOString()),
        'list': ((value.list as Array<any>).map(StockTreeToJSON)),
        'daylist': ((value.daylist as Array<any>).map(DaylistToJSON)),
    };
}

