import type { MutableRefObject } from 'react'
import React, { useEffect, useState } from 'react'

import { RecType } from 'constants/constants'

import { Rating } from '@mui/material'
import { DataGridPremium, GRID_TREE_DATA_GROUPING_FIELD, useGridApiRef } from '@mui/x-data-grid-premium'
import clsx from 'clsx'
import { GroupingCellWithLazyLoading } from 'components/commonparts'
import _ from 'lodash'
import { convertFromDate, dateToStr24HPad0DayOfWeek } from 'utils/convertFromLocalDate'

import ShowLastUpdateDatetime from '../ShowLastUpdateDatetime/ShowLastUpdateDatetime'
import ExpectedRankTooltip from '../Tooltip/ExpectedRankTooltip'

import type {
  GridColDef,
  GridColumnGroupingModel,
  GridColumnNode,
  GridEventListener,
  GridRenderCellParams,
  GridRowModelUpdate,
} from '@mui/x-data-grid'
import type {
  DataGridPremiumProps,
  GridApi,
  GridGroupingColDefOverride,
  GridCellParams,
} from '@mui/x-data-grid-premium'
import type {
  GetShipExpectedShipExpectedGetRequest,
  ShipExpected,
  ShipExpectedCustomer,
  ShipExpecteds,
} from 'api-client'
import type { GroupingCellWithLazyLoadingProps } from 'components/commonparts/GroupingCellWithLazyLoading/GroupingCellWithLazyLoading'

const initColumns: GridColDef[] = [
  {
    field: 'expectedRank',
    headerName: '',
    renderHeader: (params) => <ExpectedRankTooltip />,
    headerAlign: 'center',
    minWidth: 90,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => <Rating value={params.value ? params.value : 0} max={3} readOnly />,
  },
  { field: 'col1', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col2', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col3', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col4', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col5', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col6', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col7', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col8', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col9', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col10', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col11', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col12', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col13', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col14', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col15', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col16', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col17', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col18', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col19', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col20', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
  { field: 'col21', headerName: '', minWidth: 110, maxWidth: 500, sortable: false, type: 'number' },
]

export interface AddFieldTreePass extends ShipExpectedCustomer {
  id: string
  treepassItem?: string[]
  treepassCenter?: string[]
}
interface ShipExpectedDataListProps {
  list: ShipExpecteds
  isLoading: boolean
  getChildRows: (
    requestParam: GetShipExpectedShipExpectedGetRequest,
    rootTreePath: string
  ) => Promise<void | AddFieldTreePass[] | undefined>
  treepassKey: string
}

function ShipExpectedDataList({ list, isLoading, getChildRows, treepassKey }: ShipExpectedDataListProps) {
  const apiRef = useGridApiRef()

  const [columns, setColumns] = useState<GridColDef[]>([])
  const [columnGrouping, setColumnGrouping] = useState<GridColumnGroupingModel>([])

  const getTreeDataPath: DataGridPremiumProps['getTreeDataPath'] = (row) => row[treepassKey]

  const updateRows = (trApiRef: MutableRefObject<GridApi>, rows: GridRowModelUpdate[]) => {
    if (!trApiRef.current) {
      return
    }

    const rowsToAdd = [...rows]

    rows.forEach((row) => {
      if (row.rootpass) {
        // 行を展開可能にするためにプレースホルダー行を追加
        rowsToAdd.push({
          id: `placeholder-children-${row.id}`,
          [treepassKey]: [...row[treepassKey], ''],
        })
      }
    })

    trApiRef.current.updateRows(rowsToAdd)
  }

  const customGroupingColDef: GridGroupingColDefOverride = {
    minWidth: 300,
    renderCell: (params) => (
      <GroupingCellWithLazyLoading
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(params as GroupingCellWithLazyLoadingProps)}
      />
    ),
  }

  // ヘッダーを取得
  useEffect(() => {
    // col1 ~ col14のヘッダ名をdaylistから取得し設定する
    setColumns(
      initColumns.map((column) => {
        const trDayList = _.find(list.daylist, { colname: column.field })

        if (!trDayList?.datevalue) {
          return { ...column }
        }

        const datevalue = new Date(trDayList.datevalue)
        return {
          ...column,
          headerName: dateToStr24HPad0DayOfWeek(trDayList.datevalue, 'MM/DD (WW)', false),
          headerClassName: () =>
            // clsxを用いて、動的なクラス名を設定する
            clsx('super-app', {
              rec: trDayList.rectype === RecType.Rec,
              expected: trDayList.rectype === RecType.Expected,
              today: trDayList.rectype === RecType.Today,
              sunday: datevalue.getDay() === 0,
            }),
          cellClassName: (params: GridCellParams<any, any, number>) =>
            clsx('super-app', {
              // 本日日付は背景色を変更
              todayCell: convertFromDate(datevalue) === convertFromDate(params.row.systemDate),
            }),
        }
      })
    )

    // col1 ~ col14のヘッダグルーピングをdaylist.rectypeから取得し設定する
    const groupByRectype = _.groupBy(list.daylist, 'rectype')
    const columnGroup: GridColumnGroupingModel = [
      {
        groupId: '予測精度',
        children: [{ field: 'expectedRank' }],
        headerName: '予測精度',
      },
    ]

    // rectypeのkey毎にchildrenを設定する
    _.mapKeys(groupByRectype, (data, key) => {
      const columnNode: GridColumnNode[] = []

      _.map(data, (value) =>
        columnNode.push({
          field: value.colname as string,
        })
      )

      const recTypeName = _.find(list.daylist, { rectype: key })?.rectypeName

      columnGroup.push({
        groupId: key,
        headerName: recTypeName || key,
        children: columnNode,
        headerClassName: () =>
          // clsxを用いて、動的なクラス名を設定する
          clsx('super-app', {
            rec: key === RecType.Rec,
            expected: key === RecType.Expected,
            today: key === RecType.Today,
          }),
      })
      setColumnGrouping(columnGroup)
    })

    updateRows(apiRef, list.list)
  }, [list])

  // 子データの開閉に関する処理
  useEffect(() => {
    const handleRowExpansionChange: GridEventListener<'rowExpansionChange'> = async (node) => {
      const row = apiRef.current.getRow(node.id) as any | null

      if (!node.childrenExpanded || !row || row.childrenFetched) {
        return
      }

      // 子の行を非同期で取得する
      await getChildRows(row, row[treepassKey]).then((childRows) => {
        if (childRows !== undefined) {
          return updateRows(apiRef, [
            ...childRows,
            { ...row, childrenFetched: true },
            { id: `placeholder-children-${node.id}`, _action: 'delete' },
          ])
        }
        // apiエラーが発生した場合は展開をしない
        return updateRows(apiRef, [
          { ...row, childrenFetched: false },
          { id: `placeholder-children-${node.id}`, _action: undefined },
        ])
      })
    }

    return apiRef.current.subscribeEvent('rowExpansionChange', handleRowExpansionChange)
  }, [apiRef, getChildRows])

  // セルの自動調整機能 premium版のみ利用可能
  const autosizeColumns = {
    includeHeaders: true,
    includeOutliere: true,
    outliersFactor: 1.5,
    expand: true,
  }

  // https://mui.com/x/react-data-grid/column-dimensions/#autosizing
  // 上記のセルの自動調整機能採用しているが、正式的な機能ではない
  useEffect(() => {
    if (list.list.length > 0 && apiRef.current.autosizeColumns) {
      // NOTE:データをdataGridにバインドしてからじゃないと自動調節が効かないため意図的にずらして実行
      setTimeout(() => apiRef.current.autosizeColumns(autosizeColumns), 1000)
    }
  }, [list.list])

  return (
    <>
      <ShowLastUpdateDatetime<ShipExpected> list={list.list} lastUpdateDatetime={list.lastUpdateDatetime} />
      <DataGridPremium
        // 画面サイズによって1画面で収まるように高さを調節する
        sx={{ maxHeight: treepassKey === 'treepassItem' ? 'calc(100vh - 280px)' : 'calc(100vh - 400px)' }}
        rows={list.list}
        rowCount={list.datacount as number}
        columns={columns}
        columnGroupingModel={columnGrouping}
        initialState={{
          pinnedColumns: { left: [GRID_TREE_DATA_GROUPING_FIELD] },
        }}
        loading={isLoading}
        // 下記でユニークなkeyとして設定する
        getRowId={(row) => row.id}
        treeData
        apiRef={apiRef}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={customGroupingColDef}
        disableChildrenFiltering
        autosizeOnMount
        density="compact"
      />
    </>
  )
}

export default ShipExpectedDataList
