import React, { useState } from 'react'

import { Box, Button, FormControl, InputLabel, TextField } from '@mui/material'
import { boxMarginTop, mediumFontSize } from 'style'

interface Props {
  handleSubmit: (password: string, passwordConfirmation: string) => void
}

function PasswordResetExecForm({ handleSubmit }: Props) {
  const [password, setPassword] = useState<string>('')
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('')
  return (
    <Box sx={{ marginBottom: 1 }}>
      <FormControl>
        <InputLabel sx={{ fontSize: mediumFontSize }}>新しいパスワード</InputLabel>
        <TextField type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <InputLabel sx={{ fontSize: mediumFontSize }}>新しいパスワード（確認）</InputLabel>
        <TextField
          type="password"
          value={passwordConfirmation}
          onChange={(e) => setPasswordConfirmation(e.target.value)}
        />
      </FormControl>
      <Box display="flex" justifyContent="center" sx={{ ...boxMarginTop }}>
        <Button variant="contained" onClick={() => handleSubmit(password, passwordConfirmation)}>
          送信
        </Button>
      </Box>
    </Box>
  )
}

export default PasswordResetExecForm
