import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { protectedRoutes } from 'constants/routes'

import CheckIcon from '@mui/icons-material/Check'
import { type GridColDef } from '@mui/x-data-grid'
import { DataGridPremium, useGridApiRef } from '@mui/x-data-grid-premium'

import type { GridRenderCellParams } from '@mui/x-data-grid-premium'
import type { User } from 'api-client'
import type { DataListStatus } from 'types/types'

interface DataListProps {
  list: User
  isLoading: boolean
  listStatus: DataListStatus
  onListStatusChange: (status: DataListStatus) => void
}

function DataList({ list, isLoading, listStatus, onListStatusChange }: DataListProps) {
  const columns: GridColDef[] = [
    {
      headerName: 'メールアドレス',
      field: 'userMail',
      type: 'string',
      minWidth: 300,
      renderCell: (params: GridRenderCellParams) => (
        <Link
          className="text-blue-400 underline"
          style={{ textDecoration: 'none' }}
          // ユーザー情報更新リンク
          to={`/${protectedRoutes.UserDetail.path}?company_id=${params.row.companyId}&user_id=${params.row.userId}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      headerName: 'ユーザー名',
      field: 'userName',
      type: 'string',
      minWidth: 300,
    },
    {
      headerName: '権限区分',
      field: 'authTypeName',
      type: 'string',
      minWidth: 60,
    },
    {
      headerName: '有効',
      field: 'enabled',
      type: 'boolean',
      renderCell: (params) => {
        if (!params.value) return ''
        return <CheckIcon />
      },
      width: 60,
    },
  ]

  // セルの自動調整機能 premium版のみ利用可能
  const apiRef = useGridApiRef()
  const autosizeColumns = {
    expand: true,
  }

  // https://mui.com/x/react-data-grid/column-dimensions/#autosizing
  // 上記のセルの自動調整機能採用しているが、正式的な機能ではない
  useEffect(() => {
    if (list.list.length > 0 && apiRef.current.autosizeColumns) {
      // NOTE:データをdataGridにバインドしてからじゃないと自動調節が効かないため意図的にずらして実行
      setTimeout(() => apiRef.current.autosizeColumns(autosizeColumns), 1000)
    }
  }, [list.list])

  return (
    <DataGridPremium
      // 画面サイズによって1画面で収まるように高さを調節する
      sx={{ maxHeight: 'calc(100vh - 200px)' }}
      rows={list.list}
      rowCount={list.maxcount as number}
      columns={columns}
      initialState={{
        pagination: { paginationModel: listStatus.paginationModel },
      }}
      paginationMode="server"
      loading={isLoading}
      getRowId={(row) => row.userId}
      pagination
      paginationModel={listStatus.paginationModel}
      onPaginationModelChange={(pgState) => {
        onListStatusChange({ sortModel: listStatus.sortModel, paginationModel: pgState })
      }}
      apiRef={apiRef}
      density="compact"
    />
  )
}

export default DataList
